import { call } from 'redux-saga/effects';
import { isEmpty } from 'validator';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { INDEX_BRAND_USERS } from '../constants';

export const likeRegex = (val) =>
  val && !isEmpty(val) ? { $regex: `.*${val}.*`, $options: 'i' } : undefined;

const formatter = ({
  formValues: {
    query: { name, email, 'brand.name': brand },
    ...rest
  },
}) => ({
  formValues: {
    query: {
      name: likeRegex(name),
      email: likeRegex(email),
      'brand.name': likeRegex(brand),
    },
    ...rest,
  },
});

const indexBrandUsersNotifier = (formValues) =>
  notify({
    type: INDEX_BRAND_USERS,
    formValues,
    formatter,
  });

function* indexBrandUsersAction({ formValues }) {
  yield call(request, {
    type: INDEX_BRAND_USERS,
    method: 'get',
    endpoint: '/brand-users',
    params: formValues,
  });
}

export { indexBrandUsersAction, indexBrandUsersNotifier };
