import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { INDEX_CURRENCIES } from '../constants';
import { ADMIN_CURRENCIES } from '../../../../routing/paths';

// import { likeRegex } from '../../../../utils/helperFunctions';

const formatter = ({ formValues: { role, ...rest } }) => ({
  formValues: {
    role: 'Admin',
    ...rest,
  },
});

const indexCurrencyNotifier = (formValues, loader = true) =>
  notify({
    type: INDEX_CURRENCIES,
    formValues,
    loader,
    formatter,
  });

function* indexCurrencyAction({ formValues }) {
  yield call(request, {
    type: INDEX_CURRENCIES,
    method: 'get',
    endpoint: ADMIN_CURRENCIES,
    params: formValues,
  });
}

export { indexCurrencyNotifier, indexCurrencyAction };
