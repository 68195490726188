import { Button, Row, Col } from 'antd';
import { func, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { t } from '../../../utils/helperFunctions';

export const LandIng = ({ history, setShowLogin }) => {
  const openLogin = () => {
    setShowLogin(true);
  };

  const openBrief = () => {
    history.push('/brief');
  };

  return (
    <div className="landing">
      <Col>
        <Row
          type="flex"
          justify="center"
          className="mb-25 ml-25 send-container"
        >
          <Col>
            <Button
              type="primary"
              block
              onClick={openBrief}
              className="forgot-password-button"
            >
              {t('briefs.createBrief')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="ml-25  send-login">
          <Col>
            <Button
              type="link text-left"
              block
              onClick={openLogin}
              className="login-landing"
            >
              {t('auth.logIn')}
            </Button>
          </Col>
        </Row>
      </Col>
      <div className="logo-fluvip" />
    </div>
  );
};

LandIng.propTypes = {
  history: object.isRequired,
  setShowLogin: func.isRequired,
};

export const SimpleLandIng = LandIng;

const ShowTheLocationWithRouter = withRouter(LandIng);

export default connect()(
  reduxForm({
    form: 'logInForm',
  })(ShowTheLocationWithRouter)
);
