import { takeLatest } from 'redux-saga/effects';
import { getCampaignGlobalIndicatorAction } from './actions/getCampaignGlobalIndicators';
import { getCampaignIndicatorAction } from './actions/getCampaignIndicators';
import { getCustomerIndicatorAction } from './actions/getCustomerIndicators';
import { getContentsIndicatorAction } from './actions/getContentIndicators';
import { getRankingIndicatorAction } from './actions/getRankingIndicators';
import {
  SHOW_CAMPAIGNS_GLOBAL_INDICATORS,
  SHOW_CUSTOMER_INDICATORS,
  SHOW_CAMPAIGNS_INDICATORS,
  SHOW_CONTENTS_INDICATORS,
  SHOW_RANKING_INDICATORS,
} from './constants';

export default [
  takeLatest(
    SHOW_CAMPAIGNS_GLOBAL_INDICATORS,
    getCampaignGlobalIndicatorAction
  ),
  takeLatest(SHOW_CUSTOMER_INDICATORS, getCustomerIndicatorAction),
  takeLatest(SHOW_CAMPAIGNS_INDICATORS, getCampaignIndicatorAction),
  takeLatest(SHOW_CONTENTS_INDICATORS, getContentsIndicatorAction),
  takeLatest(SHOW_RANKING_INDICATORS, getRankingIndicatorAction),
];
