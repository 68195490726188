import { call } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import request from '../../../helpers/request';
import { formatDate } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import { UPDATE_CONTENT_DESCRIPTION } from '../constants';

const formatter = ({ formValues, ...rest }) => {
  const data = cloneDeep(formValues);
  data.date = formatDate(data.date, 'YYYY-MM-DD', 'DD-MM-YYYY');
  data.timeFrame = data.timeFrame.toLowerCase();

  return {
    formValues: data,
    ...rest,
  };
};

const updateContentDescriptionNotifier = (
  campaignId,
  contentId,
  formValues,
  callback
) =>
  notify({
    type: UPDATE_CONTENT_DESCRIPTION,
    campaignId,
    contentId,
    callback,
    formValues,
    formatter,
  });

function* updateContentDescriptionAction({
  campaignId,
  contentId,
  formValues,
  callback,
}) {
  yield call(request, {
    type: UPDATE_CONTENT_DESCRIPTION,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/contents/${contentId}/description`,
    params: formValues,
    callback,
  });
}

export { updateContentDescriptionAction, updateContentDescriptionNotifier };
