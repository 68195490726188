import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  feedbackLoaderActiveSelector,
  feedbackLoaderTextSelector,
} from '../../../redux/reducers/feedbackReducer/feedbackSelectors';
import Loader from './Loader';
import './LoaderContainer.scss';

const LoaderContainer = ({ loaderActive, loaderText }) => {
  const renderLoader = () =>
    loaderActive ? <Loader loaderText={loaderText} /> : null;

  return renderLoader();
};

LoaderContainer.propTypes = {
  loaderActive: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loaderActive: feedbackLoaderActiveSelector(state),
  loaderText: feedbackLoaderTextSelector(state),
});

export default connect(mapStateToProps)(LoaderContainer);
