export const ROOT_PATH = '/';
export const AUTH_FORGOT_PASSWORD = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';

/* Public */
export const PUBLIC_BRIEF = '/brief';
export const PUBLIC_PROPOSAL_REPORT = (campaignId) => `/proposal/${campaignId}`;
export const PUBLIC_CAMPAIGN_REPORT = (campaignId) => `/report/${campaignId}`;

/* Admin */
export const ADMIN_FINANCE = '/finance';
export const ADMIN_SHOW_FINANCE = (financeId) =>
  `${ADMIN_FINANCE}/${financeId}`;

export const ADMIN_USERS = '/users';
export const ADMIN_CREATE_USER = `${ADMIN_USERS}/create`;
export const ADMIN_SHOW_USER = (userId) => `${ADMIN_USERS}/${userId}`;

export const ADMIN_AGENCIES = '/agencies';
export const ADMIN_CREATE_AGENCY = `${ADMIN_AGENCIES}/create`;

export const ADMIN_BRANDS = '/brands';
export const ADMIN_CREATE_BRAND = `${ADMIN_BRANDS}/create`;
export const ADMIN_SHOW_BRAND = (brandId) => `${ADMIN_BRANDS}/${brandId}`;

export const ADMIN_BRAND_USERS = '/brand-users';

export const ADMIN_SNTS = '/social-network-traces';
export const ADMIN_CREATE_SNT = `${ADMIN_SNTS}/create`;
export const ADMIN_SHOW_SNT = (sntId) => `${ADMIN_SNTS}/${sntId}`;

export const ADMIN_PARAMETERS = '/parameters';
export const ADMIN_UPDATE_PARAMETER = `${ADMIN_PARAMETERS}/update`;

/* Currencies */
export const ADMIN_CURRENCIES = '/currencies';
export const ADMIN_CREATE_CURRENCY = `${ADMIN_CURRENCIES}/create`;
export const ADMIN_UPDATE_CURRENCY = `${ADMIN_CURRENCIES}/update`;
export const ADMIN_SHOW_CURRENCY = (currencyId) =>
  `${ADMIN_CURRENCIES}/${currencyId}`;

/* Exchanges */
export const ADMIN_EXCHANGES = '/exchanges';
export const ADMIN_CREATE_EXCHANGE = `${ADMIN_EXCHANGES}/create`;
export const ADMIN_UPDATE_EXCHANGE = `${ADMIN_EXCHANGES}/update`;
export const ADMIN_SHOW_EXCHANGE = (exchangeId) =>
  `${ADMIN_EXCHANGES}/${exchangeId}`;

/* Campaigns */

export const CAMPAIGNS_PATH = '/campaigns';
export const SHOW_CAMPAIGN_PATH = (id) => `/campaigns/${id}`;
export const CAMPAIGN_CONTENT_GRID_PATH = (id) =>
  `/campaigns/${id}/content-grid`;
export const CAMPAIGN_STATISTICS_PATH = (id) => `/campaigns/${id}/statistics`;
export const CAMPAIGN_PROPOSAL_PATH = (campaign, proposal) =>
  `/campaigns/${campaign}/proposal/${proposal}`;
export const SHOW_BRIEF_PATH = (id) => `/briefs/${id}`;
export const EDIT_BRIEF_PATH = (id) => `/briefs/${id}/edit`;
export const CAMPAIGN_CONTENT_PATH = (campaignId, snaId) =>
  `/campaigns/${campaignId}/snas/${snaId}/contents`;
export const CREATE_BRIEF_CAMPAIGN_PATH = (id) => `/briefs/${id}/campaign`;
export const PROPOSAL_REPORT_PREVIEW = (campaign, proposal) =>
  `/campaigns/${campaign}/proposal/${proposal}/preview`;

/* Social network accounts */
export const ADMIN_SNAS = '/social-network-accounts';

/* Style Guide */
export const STYLE_GUIDE = '/style-guide';

/* Indicator management */
export const INDICATOR_MANAGEMENT = '/indicator-management';

/* White Label */
export const ADMIN_WHITE_LABEL = '/white-label';
export const ADMIN_CREATE_WHITE_LABEL = `${ADMIN_WHITE_LABEL}/create`;
export const ADMIN_SHOW_WHITE_LABEL = (whiteLabelId) =>
  `${ADMIN_WHITE_LABEL}/${whiteLabelId}`;
export const UPDATE_WHITE_LABEL = (id) => `/white-label/${id}/edit`;
