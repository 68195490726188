import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { GET_CURRENCY, SHOW_CURRENCY } from '../constants';
import { ADMIN_SHOW_CURRENCY } from '../../../../routing/paths';

const showCurrencyNotifier = (_id, external = false) => {
  return notify({
    type: external ? SHOW_CURRENCY : GET_CURRENCY,
    _id,
    external,
  });
};

function* showCurrencyAction({ _id, external }) {
  yield call(request, {
    type: external ? SHOW_CURRENCY : GET_CURRENCY,
    method: 'get',
    endpoint: ADMIN_SHOW_CURRENCY(_id),
  });
}

export { showCurrencyNotifier, showCurrencyAction };
