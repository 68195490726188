import { main } from './main';

/**
 * Warning: Tener en cuenta que los valores de estos temas serán utilizados para
 * inyectar estilos específicos en SASS
 */
export const THEME_NAMES = {
  MAIN: 'main',
};

export const themeState = {
  selected: 'main',
  themes: {
    [THEME_NAMES.MAIN]: main,
  },
};

export const THEMES_DOMAIN = {
  localhost: THEME_NAMES.MAIN,
};
