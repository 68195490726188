import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { INDEX_EXCHANGES } from '../constants';
import { ADMIN_EXCHANGES } from '../../../../routing/paths';
// import { likeRegex } from '../../../../utils/helperFunctions';

const formatter = ({ formValues: { role, ...rest } }) => ({
  formValues: {
    role: 'Admin',
    ...rest,
  },
});

const indexExchangeNotifier = (formValues, loader = true) => {
  return notify({
    type: INDEX_EXCHANGES,
    formValues,
    loader,
    formatter,
  });
};

function* indexExchangeAction({ formValues }) {
  yield call(request, {
    type: INDEX_EXCHANGES,
    method: 'get',
    endpoint: ADMIN_EXCHANGES,
    params: formValues,
  });
}

export { indexExchangeNotifier, indexExchangeAction };
