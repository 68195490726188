import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { SHOW_ACTIVE_PROPOSAL } from '../constants';

const showProposalNotifier = (campaignId) =>
  notify({
    type: SHOW_ACTIVE_PROPOSAL,
    campaignId,
  });

function* showProposalAction({ campaignId }) {
  yield call(request, {
    type: SHOW_ACTIVE_PROPOSAL,
    method: 'get',
    endpoint: `campaigns/${campaignId}/activeProposal/`,
  });
}

export { showProposalAction, showProposalNotifier };
