import {
  SHOW_LOADER_MOVEMENT,
  HIDE_LOADER_MOVEMENT,
  SHOW_LOADER_MOVEMENT_TEXT,
} from './constants';

export const showLoaderMovement = () => ({ type: SHOW_LOADER_MOVEMENT });
export const showLoaderMovementText = (loaderText) => ({
  type: SHOW_LOADER_MOVEMENT_TEXT,
  payload: loaderText,
});
export const hideLoaderMovement = () => ({ type: HIDE_LOADER_MOVEMENT });
