export const INDEX_BRANDS = 'INDEX_BRANDS';
export const INDEX_BRANDS_SUCCESS = `${INDEX_BRANDS}_SUCCESS`;
export const INDEX_BRANDS_FAILURE = `${INDEX_BRANDS}_FAILURE`;

export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_SUCCESS = `${GET_BRAND}_SUCCESS`;
export const GET_BRAND_FAILURE = `${GET_BRAND}_FAILURE`;

export const SHOW_BRAND = 'SHOW_BRAND';
export const SHOW_BRAND_SUCCESS = `${SHOW_BRAND}_SUCCESS`;
export const SHOW_BRAND_FAILURE = `${SHOW_BRAND}_FAILURE`;

export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_BRAND_SUCCESS = `${CREATE_BRAND}_SUCCESS`;
export const CREATE_BRAND_FAILURE = `${CREATE_BRAND}_FAILURE`;

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESS = `${UPDATE_BRAND}_SUCCESS`;
export const UPDATE_BRAND_FAILURE = `${UPDATE_BRAND}_FAILURE`;

export const CREATE_BRAND_AGENCY = 'CREATE_BRAND_AGENCY';
export const CREATE_BRAND_AGENCY_SUCCESS = `${CREATE_BRAND_AGENCY}_SUCCESS`;
export const CREATE_BRAND_AGENCY_FAILURE = `${CREATE_BRAND_AGENCY}_FAILURE`;

export const UPDATE_BRAND_AGENCY = 'UPDATE_BRAND_AGENCY';
export const UPDATE_BRAND_AGENCY_SUCCESS = `${UPDATE_BRAND_AGENCY}_SUCCESS`;
export const UPDATE_BRAND_AGENCY_FAILURE = `${UPDATE_BRAND_AGENCY}_FAILURE`;

export const DELETE_BRAND_AGENCY = 'DELETE_BRAND_AGENCY';
export const DELETE_BRAND_AGENCY_SUCCESS = `${DELETE_BRAND_AGENCY}_SUCCESS`;
export const DELETE_BRAND_AGENCY_FAILURE = `${DELETE_BRAND_AGENCY}_FAILURE`;

export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_BRAND_SUCCESS = `${DELETE_BRAND}_SUCCESS`;
export const DELETE_BRAND_FAILURE = `${DELETE_BRAND}_FAILURE`;

export const UPDATE_BRAND_USER = 'UPDATE_BRAND_USER';
export const UPDATE_BRAND_USER_SUCCESS = `${UPDATE_BRAND_USER}_SUCCESS`;
export const UPDATE_BRAND_USER_FAILURE = `${UPDATE_BRAND_USER}_FAILURE`;
