import { call } from 'redux-saga/effects';
import { likeRegex } from '../../../../utils/helperFunctions';
import request from '../../../helpers/request';
import { INDEX_SNT } from '../constants';
import notify from '../../../helpers/notify';

const formatter = ({
  formValues: {
    query: { name, socialNetwork, username },
    ...rest
  },
}) => ({
  formValues: {
    query: {
      name: likeRegex(name),
      socialNetwork,
      username: likeRegex(username),
    },
    ...rest,
  },
});

const indexSNTsNotifier = (formValues) =>
  notify({
    type: INDEX_SNT,
    formValues,
    formatter,
  });

function* indexSNTsAction({ formValues }) {
  yield call(request, {
    type: INDEX_SNT,
    method: 'get',
    endpoint: '/social-network-traces/',
    params: formValues,
  });
}

export { indexSNTsAction, indexSNTsNotifier };
