import {
  ADMIN_BRANDS,
  ADMIN_CURRENCIES,
  ADMIN_EXCHANGES,
  // ADMIN_PARAMETERS,
  ADMIN_FINANCE,
  ADMIN_SNAS,
  ADMIN_USERS,
  CAMPAIGNS_PATH,
  INDICATOR_MANAGEMENT,
  ADMIN_WHITE_LABEL,
  // ROOT_PATH,
} from '../../../../routing/paths';
import {
  ACCOUNT_ROLE,
  ADMIN_ROLE,
  ADVISOR_ROLE,
  COUNTRY_MANAGER_ROLE,
  INFLUENCER_ROLE,
  MANAGER_ROLE,
  FINANCE_ROLE,
  INFLUENCER_CONSULTANT,
  BRAND_CLIENT,
  ADMIN_WHITE_LABELS,
} from '../../../../utils/roles';

export default {
  [FINANCE_ROLE]: [
    {
      title: 'campaigns',
      path: CAMPAIGNS_PATH,
      icon: 'story',
    },
    {
      title: 'accounts',
      path: ADMIN_SNAS,
      icon: 'crown',
    },
    {
      title: 'brands',
      path: ADMIN_BRANDS,
      icon: 'squares',
    },
    // {
    //   title: 'users',
    //   path: ADMIN_USERS,
    //   icon: 'user',
    // },
    {
      title: 'finance',
      path: ADMIN_FINANCE,
      icon: 'finance',
    },
  ],
  [ADMIN_ROLE]: [
    // {
    //   title: 'dashboard',
    //   path: ROOT_PATH,
    //   icon: 'dashboard',
    // },
    {
      title: 'campaigns',
      path: CAMPAIGNS_PATH,
      icon: 'story',
    },
    {
      title: 'indicator_management',
      path: INDICATOR_MANAGEMENT,
      icon: 'indicators',
    },
    {
      title: 'accounts',
      path: ADMIN_SNAS,
      icon: 'crown',
    },
    {
      title: 'brands',
      path: ADMIN_BRANDS,
      icon: 'squares',
    },
    {
      title: 'white_label',
      path: ADMIN_WHITE_LABEL,
      icon: 'dashboard',
    },
    {
      title: 'utils',
      menus: [
        { title: 'currencies', path: ADMIN_CURRENCIES, icon: 'currency' },
        { title: 'exchanges', path: ADMIN_EXCHANGES, icon: 'exchange' },
      ],
      icon: 'utils',
    },
    {
      title: 'users',
      path: ADMIN_USERS,
      icon: 'user',
    },
    // {
    //   title: 'finance',
    //   path: ADMIN_FINANCE,
    //   icon: 'finance',
    // },
    // {
    //   title: 'parameters',
    //   path: ADMIN_PARAMETERS,
    //   icon: 'post',
    // },
  ],
  [COUNTRY_MANAGER_ROLE]: [
    // {
    //   title: 'dashboard',
    //   path: ROOT_PATH,
    //   icon: 'dashboard',
    // },
    {
      title: 'campaigns',
      path: CAMPAIGNS_PATH,
      icon: 'story',
    },
    {
      title: 'accounts',
      path: ADMIN_SNAS,
      icon: 'crown',
    },
    {
      title: 'brands',
      path: ADMIN_BRANDS,
      icon: 'squares',
    },
    {
      title: 'users',
      path: ADMIN_USERS,
      icon: 'user',
    },
    // {
    //   title: 'finance',
    //   path: ADMIN_FINANCE,
    //   icon: 'finance',
    // },
  ],
  [MANAGER_ROLE]: [
    // {
    //   title: 'dashboard',
    //   path: ROOT_PATH,
    //   icon: 'squares',
    // },
  ],
  [INFLUENCER_ROLE]: [
    // {
    //   title: 'home',
    //   path: ROOT_PATH,
    //   icon: 'squares',
    // },
  ],
  [ACCOUNT_ROLE]: [
    // {
    //   title: 'dashboard',
    //   path: ROOT_PATH,
    //   icon: 'dashboard',
    // },
    {
      title: 'campaigns',
      path: CAMPAIGNS_PATH,
      icon: 'story',
    },
    // {
    //   title: 'indicator_management',
    //   path: INDICATOR_MANAGEMENT,
    //   icon: 'indicators',
    // },
    {
      title: 'accounts',
      path: ADMIN_SNAS,
      icon: 'crown',
    },
    {
      title: 'brands',
      path: ADMIN_BRANDS,
      icon: 'squares',
    },
  ],
  [ADVISOR_ROLE]: [
    // {
    //   title: 'dashboard',
    //   path: ROOT_PATH,
    //   icon: 'dashboard',
    // },
    {
      title: 'campaigns',
      path: CAMPAIGNS_PATH,
      icon: 'story',
    },
    // {
    //   title: 'indicator_management',
    //   path: INDICATOR_MANAGEMENT,
    //   icon: 'indicators',
    // },
    {
      title: 'accounts',
      path: ADMIN_SNAS,
      icon: 'crown',
    },
    {
      title: 'brands',
      path: ADMIN_BRANDS,
      icon: 'squares',
    },
    {
      title: 'users',
      path: ADMIN_USERS,
      icon: 'user',
    },
  ],
  [BRAND_CLIENT]: [
    {
      title: 'finance',
      path: ADMIN_FINANCE,
      icon: 'finance',
    },
  ],
  [ADMIN_WHITE_LABELS]: [
    {
      title: 'white_label',
      path: ADMIN_WHITE_LABEL,
      icon: 'dashboard',
    },
  ],
  [INFLUENCER_CONSULTANT]: [
    {
      title: 'accounts',
      path: ADMIN_SNAS,
      icon: 'crown',
    },
  ],
};
