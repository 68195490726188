export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = `${LOG_IN}_SUCCESS`;
export const LOG_IN_FAILURE = `${LOG_IN}_FAILURE`;

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = `${SIGN_UP}_SUCCESS`;
export const SIGN_UP_FAILURE = `${SIGN_UP}_FAILURE`;

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = `${CONFIRM_EMAIL}_SUCCESS`;
export const CONFIRM_EMAIL_FAILURE = `${CONFIRM_EMAIL}_FAILURE`;

export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = `${RESEND_EMAIL}_SUCCESS`;
export const RESEND_EMAIL_FAILURE = `${RESEND_EMAIL}_FAILURE`;

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = `${RECOVER_PASSWORD}_SUCCESS`;
export const RECOVER_PASSWORD_FAILURE = `${RECOVER_PASSWORD}_FAILURE`;

export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const RESTORE_PASSWORD_SUCCESS = `${RESTORE_PASSWORD}_SUCCESS`;
export const RESTORE_PASSWORD_FAILURE = `${RESTORE_PASSWORD}_FAILURE`;

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN_SUCCESS = `${VERIFY_TOKEN}_SUCCESS`;
export const VERIFY_TOKEN_FAILURE = `${VERIFY_TOKEN}_FAILURE`;

export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const FETCH_CAMPAIGNS_SUCCESS = `${FETCH_CAMPAIGNS}_SUCCESS`;
export const FETCH_CAMPAIGNS_FAILURE = `${FETCH_CAMPAIGNS}_FAILURE`;
