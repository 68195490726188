import React from 'react';
import PrivateSider from './Sider';
import '../privateLayout.scss';

const PrivateMenu = () => {
  const defineMenu = () => <PrivateSider />;

  return defineMenu();
};

export default PrivateMenu;
