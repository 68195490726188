import { createSelector } from 'reselect';

export const authTokenSelector = (state) => state.auth.token;
export const authRoleSelector = (state) => state.auth.role;
export const authVerifiedSelector = (state) => state.auth.verified;
export const authEmailSelector = (state) => state.auth.email;
// eslint-disable-next-line no-underscore-dangle
export const authIdSelector = (state) => state.auth._id;
export const authNameSelector = (state) => state.auth.name;
export const authIsGlobalManagerSelector = (state) =>
  state.auth.isGlobalManager;
export const authCountrySelector = (state) => state.auth.country;

export const tokenAdminFetcher = (
  token,
  role,
  isGlobalManager,
  verified,
  email,
  _id,
  name
) => ({
  token,
  role,
  isGlobalManager,
  verified,
  email,
  _id,
  name,
});

export const authSelector = createSelector(
  authTokenSelector,
  authRoleSelector,
  authIsGlobalManagerSelector,
  authVerifiedSelector,
  authEmailSelector,
  authIdSelector,
  authNameSelector,
  tokenAdminFetcher
);
