import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import es from './es.json';
import esAr from './es-ar.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      es_ar: {
        translation: esAr,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
  });
