import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { ADMIN_USERS } from '../../../../routing/paths';
import { CREATE_USER } from '../constants';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues }) => ({
  formValues: objectToFormData({
    whiteBrand: localStorage.whiteBrand,
    ...formValues,
    birthDate: '1990-01-01',
  }),
});

const createUserNotifier = (formValues) =>
  notify({
    type: CREATE_USER,
    formValues,
    formatter,
    loader: false,
  });

function* createUserAction({ formValues }) {
  yield call(request, {
    type: CREATE_USER,
    method: 'post',
    endpoint: '/users',
    params: formValues,
    path: ADMIN_USERS,
  });
}

export { createUserAction, createUserNotifier };
