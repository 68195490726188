import i18n from 'i18next';
import moment from 'moment';
import { isEmpty } from 'validator';
import { numFormat } from '../helpers/input/format';
import {
  INSTAGRAM,
  TIKTOK,
  YOUTUBE,
  socialNetworkIcons,
} from './socialNetworkUtils';

const capitalize = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
const toUpper = (string) => (string ? string.toUpperCase() : string);
const t = (value, options) => i18n.t(value, options);

const likeRegex = (val) =>
  val && !isEmpty(val)
    ? { $regex: `.*${val.trim()}.*`, $options: 'i' }
    : undefined;

const validFilter = (val) => (val && !isEmpty(val) ? val : undefined);

const floorFigure = (figure, decimals = 0) => {
  const d = 10 ** decimals;
  return (parseInt(figure * d, 10) / d).toFixed(decimals);
};

const getDayDifferenceBetweenDates = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const isToFixedNoRound = (num, decimals) =>
  Math.round(num) !== num ? floorFigure(num, decimals) : floorFigure(num, 0);

const readablizeIgNumber = (num, decimals = 0) => {
  const newNum = parseFloat(num);
  if (Number.isNaN(newNum)) {
    return '0';
  }
  if (newNum >= 1e4 && newNum < 1e6)
    return `${isToFixedNoRound(newNum / 1e3, decimals)}K`;
  if (newNum >= 1e6 && newNum < 1e9)
    return `${isToFixedNoRound(newNum / 1e6, decimals)}M`;
  if (newNum >= 1e9 && newNum < 1e12)
    return `${isToFixedNoRound(newNum / 1e9, decimals)}B`;
  if (newNum >= 1e12) return `${isToFixedNoRound(newNum / 1e12, decimals)}T`;
  return isToFixedNoRound(newNum, decimals);
};

const isToFixed = (num, decimals) => {
  return Math.round(num) !== num ? num.toFixed(decimals) : num.toFixed(0);
};

const readablizeNumber = (num, decimals = 0) => {
  const newNum = parseFloat(num);
  if (Number.isNaN(newNum)) {
    return '0';
  }
  if (newNum >= 1e3 && newNum < 1e6)
    return `${isToFixed(newNum / 1e3, decimals)}K`;
  if (newNum >= 1e6 && newNum < 1e9)
    return `${isToFixed(newNum / 1e6, decimals)}M`;
  if (newNum >= 1e9 && newNum < 1e12)
    return `${isToFixed(newNum / 1e9, decimals)}B`;
  if (newNum >= 1e12) return `${isToFixed(newNum / 1e12, decimals)}T`;
  return isToFixed(newNum, decimals);
};

const formatBudget = (budget, currency) =>
  `${readablizeNumber(budget.max, 0)} ${currency || ''}`;

const newFilterPagination = (
  query,
  pagination,
  newFilter = {},
  newPagination = {},
  sendWhiteBrand = true
) => {
  let newQuery = {
    ...query,
    ...newFilter,
  };
  if (sendWhiteBrand) {
    newQuery = {
      ...query,
      ...newFilter,
      whiteBrand: localStorage.whiteBrand,
    };
  }

  const newPag = { ...pagination, ...newPagination };
  return {
    pagination: newPag,
    query: newQuery,
  };
};
const getPercentage = (value, decimal = 1) =>
  Number((value * 100).toFixed(decimal));

const formatDate = (
  date,
  outputFormat = 'YYYY-MM-DD',
  inputFormat = 'DD-MM-YYYY'
) => {
  const result = moment(date, inputFormat)
    .format(outputFormat)
    .toUpperCase()
    .replace('.', '');

  if (['FECHA INVALIDA', 'INVALID DATE'].includes(result)) {
    return '';
  }
  return result;
};

const unixTimestampToDate = (timestamp) => new Date(timestamp * 1000);

const objectAvg = (obj) =>
  obj
    ? (
        Object.keys(obj).reduce(
          (previous, current) => previous + obj[current],
          0
        ) / Object.keys(obj).length
      ).toFixed(2)
    : 0;

const removeDuplicates = (arr) => [...new Set(arr)];

const toggleArrayValue = (arr, toSearch, value) => {
  const newArr = [...arr];
  const index = newArr.indexOf(toSearch);

  if (index === -1 && value) {
    newArr.push(toSearch);
  } else {
    newArr.splice(index, 1);
  }

  return newArr;
};

const addId = (arr) =>
  arr.reduce((newArr, current, index) => {
    const newItem = { ...current, _id: current['_id'] || index };
    newArr.push(newItem);
    return newArr;
  }, []);

const reduceDecimals = (number, decimals = 1) =>
  number % 1 === 0
    ? numFormat(number)
    : numFormat(Number.parseFloat(number).toFixed(decimals));

const orderArrayAsc = (array = []) => {
  return array.sort((currentElement, nextElement) => {
    if (currentElement === nextElement) return 0;

    return currentElement < nextElement ? 1 : -1;
  });
};

const activityIcon = (activity) => {
  if (activity === 'bonus') return 'gift';
  return activity;
};

const sumKeys = (obj, keys) =>
  obj
    ? Object.entries(obj).reduce((total, [key, val]) => {
        let newTotal = total;
        if (typeof val === 'object' && val !== null) {
          newTotal += sumKeys(val);
        } else if (keys) {
          newTotal += keys.includes(key) ? val : 0;
        } else {
          newTotal += val;
        }
        return newTotal;
      }, 0)
    : 0;

const getTranslate = (object, key) => t(`${object}.${key}`);

const orderLabelsAsc = (labels, translate = false) =>
  labels.sort((currentElement, nextElement) => {
    if (currentElement === nextElement) return 0;

    if (translate)
      return getTranslate('labels', currentElement.label) >
        getTranslate('labels', nextElement.label)
        ? 1
        : -1;

    return currentElement.label > nextElement.label ? 1 : -1;
  });

const orderRolesAsc = (roles) =>
  orderLabelsAsc(roles, true).map((role) => {
    const newRole = {
      label: getTranslate('labels', role.label),
      value: role.value,
    };
    return newRole;
  });

const showUsername = (socialNetwork, username) =>
  `${socialNetwork === 'FanPage' ? '' : '@'}${username}`;

const usernameForUrl = (socialNetwork) =>
  socialNetwork === 'fanpage' ? 'facebook' : socialNetwork;

const sentenceWord = (string) => {
  let newString = '';
  const stringSeparate = string.toLowerCase().split(' ');

  stringSeparate.forEach((word) => {
    newString += capitalize(word);
    newString += ' ';
  });

  return newString;
};

const getColorByPercentage = (percentage) => {
  let color = '';
  if (percentage >= 90) {
    color = 'green';
  } else if (percentage >= 60 && percentage < 90) {
    color = 'yellow';
  } else {
    color = 'red';
  }
  return color;
};

const disableGrid = (obtained) => {
  let grid = '';
  if (obtained === 0) {
    grid = 'without';
  }
  return grid;
};

const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
};

const parseQueryString = (params = {}) => {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach(
    (key) => params[key] && queryParams.append(key, params[key])
  );
  const strQueryParams = queryParams.toString()
    ? `?${queryParams.toString()}`
    : '';
  return strQueryParams;
};

const castingMessage = (info, form) => {
  let message = { ...info };
  if (i18n.exists(`back-message.${info.message}`)) {
    message = { ...info, message: t(`back-message.${info.message}`) };
  }
  if (i18n.exists(`back-message.${form}.${info.message}`)) {
    message = { ...info, message: t(`back-message.${form}.${info.message}`) };
  }
  return message;
};

const getSocialNetworkContent = (content) => {
  let socialNetwork = '';
  if (content.published.permalink.includes(socialNetworkIcons[YOUTUBE])) {
    socialNetwork = YOUTUBE;
  }
  if (content.published.permalink.includes(socialNetworkIcons[INSTAGRAM])) {
    socialNetwork = INSTAGRAM;
  }
  if (content.published.permalink.includes(socialNetworkIcons[TIKTOK])) {
    socialNetwork = TIKTOK;
  }
  return socialNetwork;
};

const makeCache = (fn) => {
  const cache = {};
  return async (key, ...rest) => {
    if (cache[key]) {
      return Promise.resolve(cache[key]);
    }
    cache[key] = await fn(key, ...rest);
    return Promise.resolve(cache[key]);
  };
};

const arrayToObject = (arr, key) => {
  return arr.reduce((obj, item) => ({ ...obj, [item[key]]: item }), {});
};

const getHostDomain = () => {
  const { host } = window.location;

  const arr = host.split('.');
  if (arr.length < 0) {
    return '';
  }
  if (arr[0].includes('localhost')) {
    return 'localhost';
  }
  if (arr[0].includes('www')) {
    return arr[1];
  }
  if (
    arr[0].includes('influencers') &&
    !arr[0].includes('-influencers') &&
    !arr[0].includes('atvinfluencers')
  ) {
    return arr[0] + arr[1];
  }
  return arr.length > 1 ? arr[0] : '';
};

const isValidComission = (value) => value < 100;

export {
  capitalize,
  t,
  likeRegex,
  readablizeNumber,
  readablizeIgNumber,
  formatBudget,
  newFilterPagination,
  getPercentage,
  formatDate,
  objectAvg,
  removeDuplicates,
  toggleArrayValue,
  addId,
  reduceDecimals,
  orderArrayAsc,
  activityIcon,
  sumKeys,
  orderLabelsAsc,
  orderRolesAsc,
  showUsername,
  usernameForUrl,
  sentenceWord,
  getColorByPercentage,
  isToFixed,
  fixedEncodeURIComponent,
  parseQueryString,
  unixTimestampToDate,
  disableGrid,
  castingMessage,
  getSocialNetworkContent,
  makeCache,
  arrayToObject,
  getDayDifferenceBetweenDates,
  getHostDomain,
  toUpper,
  isValidComission,
  validFilter,
};
