import { call } from 'redux-saga/effects';
import { ADMIN_USERS } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';
import request from '../../../helpers/request';
import { UPDATE_USER } from '../constants';

const formatter = ({ formValues: { ...rest } }) => {
  let values = { ...rest };
  if (typeof values.avatar === 'string') delete values.avatar;

  values = objectToFormData(values);

  return { formValues: values, userId: rest['_id'] };
};

const updateUserNotifier = (formValues, callback) =>
  notify({
    type: UPDATE_USER,
    formValues,
    formatter,
    callback,
  });

function* updateUserAction({ formValues, userId, callback }) {
  yield call(request, {
    type: UPDATE_USER,
    method: 'put',
    endpoint: `/users/${userId}`,
    params: formValues,
    callback,
    path: ADMIN_USERS,
  });
}

export { updateUserAction, updateUserNotifier };
