import { call } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import request from '../../../helpers/request';
import { CREATE_MANUAL_BONUS } from '../constants';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';
import { formatDate } from '../../../../utils/helperFunctions';

const formatter = ({ formValues, campaignId, snaId }) => {
  const params = cloneDeep(formValues);
  if (params.publishedDate) {
    params.publishedDate = formatDate(params.publishedDate, 'YYYY-MM-DD');
  }
  return {
    formValues: objectToFormData(params),
    campaignId,
    snaId,
  };
};

const createManualBonustNotifier = (formValues, campaignId, snaId, callback) =>
  notify({
    type: CREATE_MANUAL_BONUS,
    formValues,
    formatter,
    campaignId,
    snaId,
    callback,
  });

function* createManualBonustAction({
  formValues,
  campaignId,
  snaId,
  callback,
}) {
  yield call(request, {
    type: CREATE_MANUAL_BONUS,
    method: 'post',
    endpoint: `/campaigns/${campaignId}/snas/${snaId}/bonus`,
    params: formValues,
    callback,
  });
}

export { createManualBonustAction, createManualBonustNotifier };
