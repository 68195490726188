import { call } from 'redux-saga/effects';
import { ADMIN_SNAS } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_SNT } from '../constants';
import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues: { ...rest }, sntId, redirect }) => {
  let values = { ...rest };
  if (typeof values.avatar === 'string') delete values.avatar;

  values = objectToFormData(values);

  return {
    formValues: values,
    sntId,
    path: redirect ? ADMIN_SNAS : '',
  };
};

const updateSNTNotifier = (formValues, sntId, redirect = true) =>
  notify({
    type: UPDATE_SNT,
    formValues,
    sntId,
    redirect,
    formatter,
  });

function* updateSNTAction({ formValues, sntId, path }) {
  yield call(request, {
    type: UPDATE_SNT,
    method: 'put',
    endpoint: `/social-network-traces/${sntId}`,
    params: formValues,
    path,
  });
}

export { updateSNTAction, updateSNTNotifier };
