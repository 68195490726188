import { call } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import { ADMIN_EXCHANGES } from '../../../../routing/paths';
import request from '../../../helpers/request';
import { CREATE_EXCHANGE } from '../constants';
import notify from '../../../helpers/notify';
import { formatDate } from '../../../../utils/helperFunctions';
// import { numFormat } from '../../../../helpers/input/format';
// import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  params.status = formValues.status ? 1 : 0;
  params.dateRate = formatDate(params.dateRate, 'YYYY-MM-DD', 'DD-MM-YYYY');
  params.sellingRate = parseFloat(formValues.sellingRate);
  params.purchaseRate = parseFloat(formValues.purchaseRate);
  return {
    formValues: params,
  };
};

const createExchangeNotifier = (formValues) =>
  notify({
    type: CREATE_EXCHANGE,
    formValues,
    formatter,
    loader: false,
  });

function* createExchangeAction({ formValues }) {
  yield call(request, {
    type: CREATE_EXCHANGE,
    method: 'post',
    endpoint: ADMIN_EXCHANGES,
    params: formValues,
    path: ADMIN_EXCHANGES,
  });
}

export { createExchangeAction, createExchangeNotifier };
