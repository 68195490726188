import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_SNT_BASIC } from '../constants';
import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues: { ...rest }, sntId }) => {
  let values = { ...rest };
  if (typeof values.avatar === 'string') delete values.avatar;

  values = objectToFormData(values);

  return { formValues: values, sntId };
};

const updateSNTBasicNotifier = (formValues, sntId, callback) =>
  notify({
    type: UPDATE_SNT_BASIC,
    formValues,
    sntId,
    callback,
    formatter,
  });

function* updateSNTBasicAction({ formValues, sntId, callback }) {
  yield call(request, {
    type: UPDATE_SNT_BASIC,
    method: 'put',
    endpoint: `/social-network-traces/${sntId}/basic`,
    params: formValues,
    callback,
  });
}

export { updateSNTBasicAction, updateSNTBasicNotifier };
