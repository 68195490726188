import React, { useState } from 'react';
import './index.scss';
import { node, string, any, bool } from 'prop-types';

const FloatLabel = ({ children, label, value, float }) => {
  const [focus, setFocus] = useState(false);

  let labelClass = 'input-label label ';
  labelClass +=
    float &&
    (focus ||
      ((typeof value === 'string' || Array.isArray(value)) &&
        value.length > 0) ||
      (typeof value === 'object' && value) ||
      (typeof value === 'number' && !Number.isNaN(value)))
      ? 'label-float'
      : '';

  return (
    <div
      className="float-label-container"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label htmlFor="input" className={labelClass}>
        {label}
      </label>
    </div>
  );
};

FloatLabel.propTypes = {
  children: node.isRequired,
  label: string,
  float: bool.isRequired,
  value: any,
};

FloatLabel.defaultProps = {
  value: null,
  label: '',
};

export default FloatLabel;
