import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { arrayOf, element, oneOf, object } from 'prop-types';
import less from 'less';
import { themeState, THEME_NAMES } from '../themes';
import { THEME_VARS, DEFAULT_VARS } from '../themes/constants';

const ACTIONS = {
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_THEME_CONFIG: 'CHANGE_THEME_CONFIG',
  CLEAR_THEME: 'CLEAR_THEME',
};
const CLASS_PREFIX = '__theme_selected_';

const reducer = (state = themeState, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_THEME:
      return {
        ...state,
        selected: action.payload,
      };
    case ACTIONS.CHANGE_THEME_CONFIG:
      return {
        ...state,
        selected: action.payload.selected,
        themes: { ...state.themes, ...action.payload.themes },
      };
    case ACTIONS.CLEAR:
      return themeState;
    default:
      return state;
  }
};

export const actions = {
  handleChange: (payload) => ({ type: ACTIONS.CHANGE_THEME, payload }),
  handleChangeConfig: (payload) => ({ type: ACTIONS.CHANGE_THEME, payload }),
  handleClear: () => ({ type: ACTIONS.CLEAR_THEME }),
};

const useThemeDef = (defaultTheme) => {
  const [state, dispatch] = useReducer(reducer, {
    ...themeState,
    selected: defaultTheme,
  });

  useEffect(() => {
    less.modifyVars(state.themes[state.selected]);
    less.refreshStyles();
  }, [state.selected]);

  return [state, dispatch];
};

const initialData = [
  themeState,
  {
    handleChange: null,
    handleClear: null,
  },
];

const ThemeContext = createContext(initialData);

const useTheme = () => {
  const context = useContext(ThemeContext);
  return context;
};

const ThemeProvider = ({ domainConfigData, children }) => {
  const themeConf = domainConfigData.theme
    ? domainConfigData.theme.name
    : THEME_NAMES.MAIN;
  const value = useThemeDef(themeConf);
  const [loaded, setLoaded] = useState(false);
  const [theme, dispatch] = value;
  useEffect(() => {
    if (domainConfigData.theme && domainConfigData.theme.name) {
      const payload = {
        selected: domainConfigData.theme.name,
        themes: {
          [domainConfigData.theme.name]: {
            ...theme.themes[domainConfigData.theme],
            [THEME_VARS.THEME]: true,
            [THEME_VARS.THEME_VALID_MAIN]:
              domainConfigData.theme.name === 'main',
            [THEME_VARS.THEME_VALID_BLACK]:
              domainConfigData.theme.name === 'black',
            [THEME_VARS.THEME_VALID_GREENSOCIAL]:
              domainConfigData.theme.name === 'greensocial',
            [THEME_VARS.THEME_VALID_BLUERCN]:
              domainConfigData.theme.name === 'bluercn',
            [THEME_VARS.THEME_VALID_ORANGEEXISTAYA]:
              domainConfigData.theme.name === 'orangeExistaYa',
            [THEME_VARS.THEME_VALID_VICTORIAFRIENDS]:
              domainConfigData.theme.name === 'victoria Friends',
            [THEME_VARS.THEME_VALID_THEME10]:
              domainConfigData.theme.name === 'theme10',
            [THEME_VARS.THEME_VALID_THEME2]:
              domainConfigData.theme.name === 'theme2',
            [THEME_VARS.THEME_VALID_ATV]: domainConfigData.theme.name === 'atv',
            [THEME_VARS.THEME_VALID_REBOLD]:
              domainConfigData.theme.name === 'rebold',
            [THEME_VARS.THEME_VALID_MBCS]:
              domainConfigData.theme.name === 'mbcs',
            [THEME_VARS.THEME_VALID_IKI]: domainConfigData.theme.name === 'iki',
            [THEME_VARS.THEME_VALID_LOGIN_IMAGE]:
              domainConfigData.imageBackground3 !== DEFAULT_VARS.BACKGROUND3,
            [THEME_VARS.LOGIN_IMAGE]: `'${domainConfigData.imageBackground3}'`,
            [THEME_VARS.THEME_VALID_LANDING_IMAGE]:
              domainConfigData.imageLanding !== DEFAULT_VARS.BACKGROUND3,
            [THEME_VARS.LANDING_IMAGE]: `'${domainConfigData.imageLanding}'`,
            [THEME_VARS.LOGO_IMAGE]: `'${domainConfigData.imageLogo}'`,
            [THEME_VARS.PREVIEW_IMAGE]: `'${domainConfigData.imageBackground2}'`,
            [THEME_VARS.CART_IMAGE]: `'${domainConfigData.imageCart}'`,
            [THEME_VARS.BLUE_BOX_IMAGE]: `'${domainConfigData.imageBlueBox}'`,
            [THEME_VARS.PINK_BOX_IMAGE]: `'${domainConfigData.imagePinkBox}'`,
            [THEME_VARS.PRIMARY_COLOR]: domainConfigData.theme.primaryColor,
            [THEME_VARS.LAYOUT_HEADER_BACKGROUND_COLOR]:
              domainConfigData.theme.primaryColor,
            [THEME_VARS.MENU_ITEM_COLOR]: domainConfigData.theme.primaryColor,
            [THEME_VARS.TABLE_HEADER_COLOR]:
              domainConfigData.theme.primaryColor,
            [THEME_VARS.PRIMARY_BACKGROUND_COLOR_BUTTON]:
              domainConfigData.theme.pinkColor,

            [THEME_VARS.PRIMARY_BACKGROUND_COLOR_COLLAPSE_HEADER]:
              domainConfigData.theme.pinkColor,
            [THEME_VARS.SECONDARY_BACKGROUND_COLOR]:
              domainConfigData.theme.pinkColor,
            [THEME_VARS.PRIMARY_TEXT_COLOR_BUTTON]:
              domainConfigData.theme.whiteColor,
            [THEME_VARS.SECONDARY_COLOR_CONTRAST_PRIMARY]:
              domainConfigData.theme.whiteColor,
            [THEME_VARS.SECONDARY_TEXT_COLOR]:
              domainConfigData.theme.purpleColor,
          },
        },
      };
      dispatch({ payload, type: ACTIONS.CHANGE_THEME_CONFIG });
      setLoaded(true);
    }
  }, [domainConfigData.theme]);
  return (
    <ThemeContext.Provider value={value}>
      <div className={`${CLASS_PREFIX}${theme.selected}`}>
        {loaded && children}
      </div>
    </ThemeContext.Provider>
  );
};

ThemeProvider.defaultProps = {
  domainConfigData: null,
};

ThemeProvider.propTypes = {
  domainConfigData: object,
  children: oneOf(element, arrayOf(element)).isRequired,
};

export { ThemeProvider, useTheme };
