import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { SHOW_CAMPAIGN_REPORT } from '../constants';

const showCampaignReportNotifier = (campaignId) =>
  notify({
    type: SHOW_CAMPAIGN_REPORT,
    campaignId,
  });

function* showCampaignReportAction({ campaignId }) {
  yield call(request, {
    type: SHOW_CAMPAIGN_REPORT,
    method: 'get',
    endpoint: `campaigns/${campaignId}/report/`,
  });
}

export { showCampaignReportAction, showCampaignReportNotifier };
