import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { SEND_PROPOSAL_TO_CLIENT } from '../constants';

const sendProposalToClientNotifier = (formValues, campaignId, proposalId) =>
  notify({
    type: SEND_PROPOSAL_TO_CLIENT,
    formValues: { ...formValues, status: 'Active' },
    campaignId,
    proposalId,
  });

function* sendProposalToClientAction({
  campaignId,
  proposalId,
  formValues,
  callback,
}) {
  yield call(request, {
    type: SEND_PROPOSAL_TO_CLIENT,
    method: 'post',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}/proposal`,
    params: formValues,
    callback,
  });
}

export { sendProposalToClientAction, sendProposalToClientNotifier };
