import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_SNT_FIELD } from '../constants';

const updateSNTFieldNotifier = (formValues, sntId) =>
  notify({
    type: UPDATE_SNT_FIELD,
    formValues,
    sntId,
  });

function* updateSNTFieldAction({ formValues, sntId }) {
  yield call(request, {
    type: UPDATE_SNT_FIELD,
    method: 'put',
    endpoint: `/social-network-traces/${sntId}/insights`,
    params: formValues,
  });
}

export { updateSNTFieldAction, updateSNTFieldNotifier };
