import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { APPROVE_ACTIVE_PROPOSAL } from '../constants';

const formatter = ({ campaignId, userId }) => ({
  campaignId,
  user: userId,
});

const approveProposalNotifier = (campaignId, userId) =>
  notify({
    type: APPROVE_ACTIVE_PROPOSAL,
    campaignId,
    userId,
    formatter,
  });

function* approveProposalAction({ campaignId, user }) {
  yield call(request, {
    endpoint: `campaigns/${campaignId}/activeProposal/status`,
    method: 'put',
    type: APPROVE_ACTIVE_PROPOSAL,
    params: { user },
  });
}

export { approveProposalAction, approveProposalNotifier };
