/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Upload } from 'antd';
import { any, bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
import './dropzone.scss';

const FileInput = ({
  value,
  name,
  change,
  accept,
  showUploadList,
  small,
  placeholder,
  meta: { error, touched },
  preview,
  isImage,
  isVideo,
}) => {
  const fileValue = typeof value === 'object';

  const props = {
    onRemove: () => {
      change(name, undefined);
    },
    onChange: (newFile) => {
      change(name, newFile.fileList.length ? newFile.file : undefined);
    },
    beforeUpload: () => false,
    fileList: fileValue ? [value] : [],
    accept,
    showUploadList,
  };

  const textPreview = (text, showPlace = true) => (
    <div className="text-preview p-5">
      {showPlace && <p className="placeholder">{placeholder}</p>}
      <div className="message">{text}</div>
    </div>
  );

  const filePreview = (kind) => {
    const src = typeof value === 'string' ? value : URL.createObjectURL(value);
    return (
      <div className="image-container">
        {kind === 'image' ? <img src={src} alt="" /> : <Player src={src} />}
      </div>
    );
  };

  const renderPreview = () => {
    if (error && touched) {
      return textPreview(error);
    }

    if (value || preview) {
      if (isImage || isVideo) {
        return filePreview(isImage ? 'image' : 'video');
      }
      if (value) {
        return textPreview(
          <span>
            Se cargó con éxito
            <br />
            {value.name}
          </span>
        );
      }
      return textPreview(<span>&nbsp;</span>);
    }
    return textPreview(<span>+</span>, false);
  };

  return (
    <div className="file-input">
      <Upload.Dragger className={`${small ? 'small' : ''}`} {...props}>
        {small ? placeholder : renderPreview()}
      </Upload.Dragger>
    </div>
  );
};

FileInput.propTypes = {
  value: any,
  name: string.isRequired,
  change: func.isRequired,
  accept: string,
  showUploadList: bool,
  small: bool,
  placeholder: string,
  meta: shape({
    error: string,
    touched: bool,
  }).isRequired,
  preview: string,
  isImage: bool,
  isVideo: bool,
};

FileInput.defaultProps = {
  value: undefined,
  placeholder: undefined,
  accept: '',
  showUploadList: true,
  small: false,
  preview: null,
  isImage: true,
  isVideo: false,
};

export default FileInput;
