import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { INDEX_CAMPAIGNS } from '../constants';
import { likeRegex } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';

const formatter = ({
  formValues: {
    query: { name, brand, budget, status, country, startDate, whiteBrand },
    ...rest
  },
}) => ({
  formValues: {
    query: {
      'info.name': likeRegex(name),
      'brand.name': brand,
      budget,
      status,
      'proposals.dates.startDate': startDate,
      'brief.country': country,
    },
    ...rest,
    whiteBrand,
  },
});

const indexCampaignsNotifier = (formValues, loader = false) =>
  notify({
    type: INDEX_CAMPAIGNS,
    formValues,
    loader,
    formatter,
  });

function* indexCampaignsAction({ formValues }) {
  yield call(request, {
    type: INDEX_CAMPAIGNS,
    method: 'get',
    endpoint: '/campaigns',
    params: formValues,
  });
}

export { indexCampaignsAction, indexCampaignsNotifier };
