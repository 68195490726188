import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { DELETE_BRAND } from '../constants';

const deleteBrandNotifier = (brand, callback) =>
  notify({
    type: DELETE_BRAND,
    brand,
    callback,
  });

function* deleteBrandAction({ brand, callback }) {
  yield call(request, {
    type: DELETE_BRAND,
    method: 'delete',
    endpoint: `/brands/${brand}`,
    callback,
  });
}
export { deleteBrandAction, deleteBrandNotifier };
