import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import { formatDate } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_CAMPAIGN_PROPOSAL } from '../constants';
// import { PROPOSAL_REPORT_PREVIEW } from '../../../../routing/paths';

const formatter = ({
  formValues: { campaignId, proposalId, cart, proposal: oldProposal, info },
}) => {
  const newProposal = { ...cloneDeep(oldProposal) };

  const dates = {};
  if (newProposal.dates && newProposal.dates.length > 0) {
    dates.startDate = formatDate(newProposal.dates[0]);
    dates.endDate = formatDate(newProposal.dates[1]);
  }
  const proposal = {
    ...newProposal,
    dates: {
      ...dates,
    },
    socialNetworkAccounts: cart,
  };
  return { proposal, info, campaignId, proposalId };
};

const updateCampaignProposalNotifier = (formValues, callback) =>
  notify({
    type: UPDATE_CAMPAIGN_PROPOSAL,
    formValues,
    formatter,
    callback,
  });

function* updateCampaignProposalAction({
  campaignId,
  proposalId,
  proposal,
  info,
  callback,
}) {
  yield call(request, {
    type: UPDATE_CAMPAIGN_PROPOSAL,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}`,
    params: { proposal, info },
    callback,
    // path: PROPOSAL_REPORT_PREVIEW(campaignId, proposalId),
  });
}

export { updateCampaignProposalAction, updateCampaignProposalNotifier };
