/* eslint-disable react/jsx-props-no-spreading */
import { Col, Row, Select } from 'antd';
import {
  arrayOf,
  bool,
  func,
  object,
  shape,
  string,
  oneOfType,
  number,
} from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { t } from '../../../utils/helperFunctions';
import { capitalize, dateFormat, numOnly, strTrim } from '../format';
import {
  createLabels,
  CreatePasswordField,
  CreateSelectField,
  CreateTextField,
} from '../inputCreator';
import { capitalLetters } from '../normalizers';
import {
  email,
  maxLength30,
  minLength2,
  minLength7,
  passwordMatch,
  required,
  minLength8,
  maxLength10,
  validDate,
} from '../validations';

export const EmailField = ({
  name,
  isRequired,
  validate,
  hasLabel = true,
  infoError = false,
  info = undefined,
}) => (
  <Field
    name={name}
    component={CreateTextField}
    placeholder={t('form.credentialsInfo.email')}
    label={hasLabel ? t('form.credentialsInfo.email') : ''}
    validate={validate || isRequired ? [required, email, ...validate] : []}
    normalize={strTrim}
    info={info}
    infoError={infoError}
    hasFeedback
  />
);

EmailField.propTypes = {
  name: string.isRequired,
  info: string,
  isRequired: bool,
  infoError: bool,
  hasLabel: bool,
  validate: arrayOf(func),
};

EmailField.defaultProps = {
  isRequired: false,
  infoError: false,
  hasLabel: true,
  validate: [],
  info: null,
};

export const PhoneField = ({ name, placeholder, label, isRequired }) => (
  <Field
    name={name}
    component={CreateTextField}
    placeholder={placeholder}
    label={label}
    normalize={numOnly}
    validate={
      isRequired
        ? [required, minLength8, maxLength10]
        : [minLength8, maxLength10]
    }
    hasFeedback
  />
);

PhoneField.propTypes = {
  name: string.isRequired,
  isRequired: bool.isRequired,
  placeholder: string,
  label: string,
};

PhoneField.defaultProps = {
  placeholder: t('form.contactInfo.phone'),
  label: t('form.contactInfo.phone'),
};

const setValue = (value) =>
  value ? ` +${value.toString().replace(/\+/, '')}` : '';

export const PhoneCodeField = ({ name, placeholder, label, isRequired }) => (
  <Field
    name={name}
    component={CreateTextField}
    placeholder={placeholder}
    label={label}
    normalize={numOnly}
    format={setValue}
    validate={isRequired ? [required] : required}
    maxLength="5"
    hasFeedback
    disabled
  />
);

PhoneCodeField.propTypes = {
  name: string.isRequired,
  isRequired: bool.isRequired,
  placeholder: string,
  label: string,
};

PhoneCodeField.defaultProps = {
  placeholder: '+',
  label: t('form.contactInfo.code'),
};

export const FullPhoneField = ({ codeName, phoneName, flag }) => (
  <Row className="full-phone-field">
    <Col xs={5} className="code-container">
      {flag ? <img className="flag-image" src={flag} alt={codeName} /> : ''}
      <PhoneCodeField
        name={codeName}
        label={t('form.contactInfo.code')}
        placeholder={t('form.contactInfo.code')}
        isRequired
      />
    </Col>
    <Col xs={1} className="separator">
      <span>-</span>
    </Col>
    <Col xs={18}>
      <PhoneField
        name={phoneName}
        label={t('form.contactInfo.phone')}
        placeholder={t('form.contactInfo.phone')}
        isRequired
      />
    </Col>
  </Row>
);

FullPhoneField.propTypes = {
  codeName: string.isRequired,
  phoneName: string.isRequired,
  flag: oneOfType([string, number]).isRequired,
};

export const NameField = ({ name, isLastName, validate }) => (
  <Field
    name={name}
    component={CreateTextField}
    placeholder={t(
      isLastName ? 'form.contactInfo.lastName' : 'form.contactInfo.name'
    )}
    label={t(
      isLastName ? 'form.contactInfo.lastName' : 'form.contactInfo.name'
    )}
    normalize={capitalLetters}
    validate={validate}
    hasFeedback
  />
);

NameField.propTypes = {
  name: string.isRequired,
  isLastName: bool,
  validate: arrayOf(func),
};

NameField.defaultProps = {
  isLastName: false,
  validate: [required, minLength2, maxLength30],
};

export const CountryField = ({
  name,
  isRequired,
  countries,
  placeholder,
  label,
  ...rest
}) => {
  return (
    <Field
      name={name}
      component={CreateSelectField}
      placeholder={placeholder || t('form.contactInfo.country')}
      label={label || t('form.contactInfo.country')}
      validate={isRequired ? [required] : []}
      normalize={capitalize}
      hasFeedback
      {...rest}
    >
      {createLabels(countries, false)}
    </Field>
  );
};

CountryField.propTypes = {
  name: string.isRequired,
  placeholder: string,
  label: string,
  countries: arrayOf(object).isRequired,
  isRequired: bool.isRequired,
};

CountryField.defaultProps = {
  placeholder: t('form.contactInfo.country'),
  label: t('form.contactInfo.country'),
};

export const DateField = ({ name, label, placeholder, validate }) => (
  <Field
    name={name}
    component={CreateTextField}
    placeholder={placeholder}
    label={label}
    validate={validate}
    normalize={dateFormat}
    hasFeedback
  />
);

DateField.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  placeholder: string.isRequired,
  validate: arrayOf(func),
};

DateField.defaultProps = {
  validate: [required, validDate],
};

export const RoleField = ({ name, roles }) => (
  <Field
    name={name}
    component={CreateSelectField}
    placeholder={t('form.credentialsInfo.rol.placeholder')}
    label={t('form.credentialsInfo.rol.label')}
    normalize={capitalize}
    validate={[required]}
    hasFeedback
  >
    {roles.map((role) => (
      <Select.Option key={role.value} title={role.label} value={role.value}>
        {role.label}
      </Select.Option>
    ))}
  </Field>
);

RoleField.propTypes = {
  name: string.isRequired,
  roles: arrayOf(
    shape({
      value: string,
      title: string,
    })
  ).isRequired,
};

export const PasswordField = ({ name, placeholder, confirm }) => (
  <Field
    name={name}
    component={CreatePasswordField}
    placeholder={t(placeholder)}
    label={t(placeholder)}
    validate={
      confirm ? [required, passwordMatch, minLength7] : [required, minLength7]
    }
    hasFeedback
  />
);

PasswordField.propTypes = {
  name: string.isRequired,
  placeholder: string.isRequired,
  confirm: bool,
};

PasswordField.defaultProps = {
  confirm: false,
};
