import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { UPDATE_PROPOSAL_NAME } from '../constants';
import notify from '../../../helpers/notify';

const updateProposalNameNotifier = (campaignId, proposalId, name, callback) =>
  notify({
    type: UPDATE_PROPOSAL_NAME,
    campaignId,
    proposalId,
    name,
    callback,
  });

function* updateProposalNameAction({ campaignId, proposalId, name, callback }) {
  yield call(request, {
    type: UPDATE_PROPOSAL_NAME,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}/name`,
    params: { name },
    callback,
  });
}

export { updateProposalNameAction, updateProposalNameNotifier };
