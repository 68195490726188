import { takeLatest } from 'redux-saga/effects';
import { indexCurrencyAction } from './actions/indexCurrencies';
import { createCurrencyAction } from './actions/createCurrency';
import { updateCurrencyAction } from './actions/updateCurrency';
import { showCurrencyAction } from './actions/showCurrency';
import { deleteCurrencyAction } from './actions/deleteCurrency';
import {
  INDEX_CURRENCIES,
  SHOW_CURRENCY,
  CREATE_CURRENCY,
  UPDATE_CURRENCY,
  GET_CURRENCY,
  DELETE_CURRENCY,
} from './constants';
import { SHOW_LOADER_MOVEMENT } from '../../movements/constants';
import { showLoaderMovement } from '../../movements/loaderMovements';

export default [
  takeLatest(INDEX_CURRENCIES, indexCurrencyAction),
  takeLatest(SHOW_CURRENCY, showCurrencyAction),
  takeLatest(GET_CURRENCY, showCurrencyAction),
  takeLatest(CREATE_CURRENCY, createCurrencyAction),
  takeLatest(UPDATE_CURRENCY, updateCurrencyAction),
  takeLatest(DELETE_CURRENCY, deleteCurrencyAction),
  takeLatest(DELETE_CURRENCY, deleteCurrencyAction),
  takeLatest(SHOW_LOADER_MOVEMENT, showLoaderMovement),
];
