import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import { formatDate } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { CREATE_CAMPAIGN } from '../constants';

const formatter = ({
  formValues,
  briefId,
  totalPrice,
  cart,
  totalCountryPrice,
  totalUSDPrice,
}) => {
  const newCampaign = cloneDeep(formValues);
  const dates = {};
  if (
    newCampaign.proposals[0].dates &&
    newCampaign.proposals[0].dates.length > 0
  ) {
    dates.startDate = formatDate(newCampaign.proposals[0].dates[0]);
    dates.endDate = formatDate(newCampaign.proposals[0].dates[1]);
  }
  newCampaign.proposals[0].socialNetworkAccounts = cart;
  newCampaign.proposals[0].totalPrice = totalPrice;
  newCampaign.proposals[0].totalCountryPrice = totalCountryPrice;
  newCampaign.proposals[0].totalUSDPrice = totalUSDPrice;
  newCampaign.proposals[0].dates = {
    ...dates,
  };
  newCampaign.brief = briefId;
  return { formValues: newCampaign };
};

const createCampaignNotifier = (
  formValues,
  briefId,
  cart,
  totalPrice,
  totalCountryPrice,
  totalUSDPrice,
  callback
) =>
  notify({
    type: CREATE_CAMPAIGN,
    formValues,
    briefId,
    cart,
    totalPrice,
    totalCountryPrice,
    totalUSDPrice,
    formatter,
    callback,
  });

function* createCampaignAction({ formValues, callback }) {
  yield call(request, {
    type: CREATE_CAMPAIGN,
    method: 'post',
    endpoint: '/campaigns',
    params: formValues,
    callback,
  });
}

export { createCampaignAction, createCampaignNotifier };
