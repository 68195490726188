import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_SNT } from '../constants';
import notify from '../../../helpers/notify';

const showSNTNotifier = (SNTId) =>
  notify({
    type: SHOW_SNT,
    SNTId,
  });

function* showSNTAction({ SNTId }) {
  yield call(request, {
    type: SHOW_SNT,
    method: 'get',
    endpoint: `/social-network-traces/${SNTId}`,
  });
}

export { showSNTAction, showSNTNotifier };
