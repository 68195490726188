export const SHOW_CAMPAIGNS_GLOBAL_INDICATORS =
  'SHOW_CAMPAIGNS_GLOBAL_INDICATORS';
export const SHOW_CAMPAIGNS_GLOBAL_INDICATORS_SUCCESS = `${SHOW_CAMPAIGNS_GLOBAL_INDICATORS}_SUCCESS`;
export const SHOW_CAMPAIGNS_GLOBAL_INDICATORS_FAILURE = `${SHOW_CAMPAIGNS_GLOBAL_INDICATORS}_FAILURE`;

export const SHOW_CAMPAIGNS_INDICATORS = 'SHOW_CAMPAIGNS_INDICATORS';
export const SHOW_CAMPAIGNS_INDICATORS_SUCCESS = `${SHOW_CAMPAIGNS_INDICATORS}_SUCCESS`;
export const SHOW_CAMPAIGNS_INDICATORS_FAILURE = `${SHOW_CAMPAIGNS_INDICATORS}_FAILURE`;

export const SHOW_CONTENTS_INDICATORS = 'SHOW_CONTENTS_INDICATORS';
export const SHOW_CONTENTS_INDICATORS_SUCCESS = `${SHOW_CONTENTS_INDICATORS}_SUCCESS`;
export const SHOW_CONTENTS_INDICATORS_FAILURE = `${SHOW_CONTENTS_INDICATORS}_FAILURE`;

export const SHOW_CUSTOMER_INDICATORS = 'SHOW_CUSTOMER_INDICATORS';
export const SHOW_CUSTOMER_INDICATORS_SUCCESS = `${SHOW_CUSTOMER_INDICATORS}_SUCCESS`;
export const SHOW_CUSTOMER_INDICATORS_FAILURE = `${SHOW_CUSTOMER_INDICATORS}_FAILURE`;

export const SHOW_RANKING_INDICATORS = 'SHOW_RANKING_INDICATORS';
export const SHOW_RANKING_INDICATORS_SUCCESS = `${SHOW_RANKING_INDICATORS}_SUCCESS`;
export const SHOW_RANKING_INDICATORS_FAILURE = `${SHOW_RANKING_INDICATORS}_FAILURE`;
