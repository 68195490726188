export const INDEX_BRIEFS = 'INDEX_BRIEFS';
export const INDEX_BRIEFS_SUCCESS = `${INDEX_BRIEFS}_SUCCESS`;
export const INDEX_BRIEFS_FAILURE = `${INDEX_BRIEFS}_FAILURE`;

export const GET_BRIEF = 'GET_BRIEF';
export const GET_BRIEF_SUCCESS = `${GET_BRIEF}_SUCCESS`;
export const GET_BRIEF_FAILURE = `${GET_BRIEF}_FAILURE`;

export const SHOW_BRIEF = 'SHOW_BRIEF';
export const SHOW_BRIEF_SUCCESS = `${SHOW_BRIEF}_SUCCESS`;
export const SHOW_BRIEF_FAILURE = `${SHOW_BRIEF}_FAILURE`;

export const CREATE_BRIEF = 'CREATE_BRIEF';
export const CREATE_BRIEF_SUCCESS = `${CREATE_BRIEF}_SUCCESS`;
export const CREATE_BRIEF_FAILURE = `${CREATE_BRIEF}_FAILURE`;

export const UPDATE_BRIEF_STATUS = 'UPDATE_BRIEF_STATUS';
export const UPDATE_BRIEF_STATUS_SUCCESS = `${UPDATE_BRIEF_STATUS}_SUCCESS`;
export const UPDATE_BRIEF_STATUS_FAILURE = `${UPDATE_BRIEF_STATUS}_FAILURE`;

export const UPDATE_BRIEF_OPS_USERS = 'UPDATE_BRIEF_OPS_USERS';
export const UPDATE_BRIEF_OPS_USERS_SUCCESS = `${UPDATE_BRIEF_OPS_USERS}_SUCCESS`;
export const UPDATE_BRIEF_OPS_USERS_FAILURE = `${UPDATE_BRIEF_OPS_USERS}_FAILURE`;

export const UPDATE_BRIEF = 'UPDATE_BRIEF';
export const UPDATE_BRIEF_SUCCESS = `${UPDATE_BRIEF}_SUCCESS`;
export const UPDATE_BRIEF_FAILURE = `${UPDATE_BRIEF}_FAILURE`;
