import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { DELETE_BRAND_AGENCY } from '../constants';

const deleteBrandAgencyNotifier = (brand, agency, callback) =>
  notify({
    type: DELETE_BRAND_AGENCY,
    brand,
    agency,
    callback,
  });

function* deleteBrandAgencyAction({ brand, agency, callback }) {
  yield call(request, {
    type: DELETE_BRAND_AGENCY,
    method: 'delete',
    endpoint: `/brands/${brand}/agencies/${agency}`,
    callback,
  });
}
export { deleteBrandAgencyAction, deleteBrandAgencyNotifier };
