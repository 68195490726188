import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD } from '../constants';
import notify from '../../../helpers/notify';

const formatter = ({ formValues: { passwordConfirmation, ...rest } }) => ({
  formValues: {
    ...rest,
  },
});

const changePasswordNotifier = (formValues) =>
  notify({
    type: CHANGE_PASSWORD,
    formValues,
    formatter,
  });

function* changePasswordAction({ formValues }) {
  yield call(request, {
    type: CHANGE_PASSWORD_SUCCESS,
    method: 'put',
    endpoint: '/users/change-password',
    params: formValues,
  });
}

export { changePasswordAction, changePasswordNotifier };
