import { put } from 'redux-saga/effects';
import { INDEX_SNA_SUCCESS, INDEX_SNA_FULL } from '../constants';
import notify from '../../../helpers/notify';
import triggerAction from '../../../helpers/triggerAction';

const updateSNAsNotifier = (payload) =>
  notify({
    type: INDEX_SNA_FULL,
    payload,
    loader: false,
  });

function* updateSNAsAction({ payload }) {
  yield put(triggerAction(INDEX_SNA_SUCCESS, payload.data));
}

export { updateSNAsNotifier, updateSNAsAction };
