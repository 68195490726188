import { call } from 'redux-saga/effects';
import { ADMIN_EXCHANGES } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { DELETE_EXCHANGE } from '../constants';

const deleteExchangeNotifier = (exchange, callback) =>
  notify({
    type: DELETE_EXCHANGE,
    exchange,
    callback,
  });

function* deleteExchangeAction({ exchange, callback }) {
  yield call(request, {
    type: DELETE_EXCHANGE,
    method: 'delete',
    endpoint: `/exchanges/${exchange}`,
    callback,
    path: ADMIN_EXCHANGES,
  });
}
export { deleteExchangeAction, deleteExchangeNotifier };
