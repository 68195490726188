import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { LINK_FB_ACCOUNT } from '../constants';

const formatter = ({ formValues: { accessToken, grantedScopes } }) => ({
  formValues: {
    accessToken,
    grantedScopes,
  },
});

const linkFBAccountNotifier = (formValues, callback) =>
  notify({
    type: LINK_FB_ACCOUNT,
    formValues,
    formatter,
    callback,
  });

function* linkFBAccountAction({ formValues, callback }) {
  yield call(request, {
    type: LINK_FB_ACCOUNT,
    method: 'post',
    endpoint: '/social-network-accounts/facebook',
    params: formValues,
    callback,
  });
}

export { linkFBAccountAction, linkFBAccountNotifier };
