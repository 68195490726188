import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_EXCHANGE } from '../constants';
import notify from '../../../helpers/notify';

const showExchangeNotifier = (exchangeId) =>
  notify({
    type: SHOW_EXCHANGE,
    exchangeId,
  });

function* showExchangeAction({ exchangeId }) {
  yield call(request, {
    type: SHOW_EXCHANGE,
    method: 'get',
    endpoint: `/exchanges/${exchangeId}`,
  });
}

export { showExchangeAction, showExchangeNotifier };
