import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_BRAND_USER } from '../constants';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  if (typeof params.avatar === 'string') delete params.avatar;

  return { formValues: params };
};

const updateBrandUserNotifier = (formValues, callback) =>
  notify({
    type: UPDATE_BRAND_USER,
    formValues,
    formatter,
    callback,
  });

function* updateBrandUserAction({ formValues, callback }) {
  yield call(request, {
    type: UPDATE_BRAND_USER,
    method: 'put',
    endpoint: `/users/brand-users/${formValues['_id']}`,
    params: formValues,
    callback,
  });
}
export { updateBrandUserAction, updateBrandUserNotifier };
