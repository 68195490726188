export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = `${CREATE_CAMPAIGN}_SUCCESS`;
export const CREATE_CAMPAIGN_FAILURE = `${CREATE_CAMPAIGN}_FAILURE`;

export const INDEX_CAMPAIGNS = 'INDEX_CAMPAIGNS';
export const INDEX_CAMPAIGNS_SUCCESS = `${INDEX_CAMPAIGNS}_SUCCESS`;
export const INDEX_CAMPAIGNS_FAILURE = `${INDEX_CAMPAIGNS}_FAILURE`;

export const INDEX_FINANCE_CAMPAIGNS = 'INDEX_FINANCE_CAMPAIGNS';
export const INDEX_FINANCE_CAMPAIGNS_SUCCESS = `${INDEX_FINANCE_CAMPAIGNS}_SUCCESS`;
export const INDEX_FINANCE_CAMPAIGNS_FAILURE = `${INDEX_FINANCE_CAMPAIGNS}_FAILURE`;

export const SHOW_CAMPAIGN = 'SHOW_CAMPAIGN';
export const SHOW_CAMPAIGN_SUCCESS = `${SHOW_CAMPAIGN}_SUCCESS`;
export const SHOW_CAMPAIGN_FAILURE = `${SHOW_CAMPAIGN}_FAILURE`;

export const SHOW_CAMPAIGN_REPORT = 'SHOW_CAMPAIGN_REPORT';
export const SHOW_CAMPAIGN_REPORT_SUCCESS = `${SHOW_CAMPAIGN_REPORT}_SUCCESS`;
export const SHOW_CAMPAIGN_REPORT_FAILURE = `${SHOW_CAMPAIGN_REPORT}_FAILURE`;

export const SHOW_CAMPAIGN_PROPOSAL = 'SHOW_CAMPAIGN_PROPOSAL';
export const SHOW_CAMPAIGN_PROPOSAL_SUCCESS = `${SHOW_CAMPAIGN_PROPOSAL}_SUCCESS`;
export const SHOW_CAMPAIGN_PROPOSAL_FAILURE = `${SHOW_CAMPAIGN_PROPOSAL}_FAILURE`;

export const SHOW_CAMPAIGN_PROPOSAL_MISC = 'SHOW_CAMPAIGN_PROPOSAL_MISC';
export const SHOW_CAMPAIGN_PROPOSAL_MISC_SUCCESS = `${SHOW_CAMPAIGN_PROPOSAL_MISC}_SUCCESS`;
export const SHOW_CAMPAIGN_PROPOSAL_MISC_FAILURE = `${SHOW_CAMPAIGN_PROPOSAL_MISC}_FAILURE`;

export const SHOW_CAMPAIGN_PROPOSAL_PREVIEW = 'SHOW_CAMPAIGN_PROPOSAL_PREVIEW';
export const SHOW_CAMPAIGN_PROPOSAL_PREVIEW_SUCCESS = `${SHOW_CAMPAIGN_PROPOSAL_PREVIEW}_SUCCESS`;
export const SHOW_CAMPAIGN_PROPOSAL_PREVIEW_FAILURE = `${SHOW_CAMPAIGN_PROPOSAL_PREVIEW}_FAILURE`;

export const SHOW_CAMPAIGN_CONTENT = 'SHOW_CAMPAIGN_CONTENT';
export const SHOW_CAMPAIGN_CONTENT_SUCCESS = `${SHOW_CAMPAIGN_CONTENT}_SUCCESS`;
export const SHOW_CAMPAIGN_CONTENT_FAILURE = `${SHOW_CAMPAIGN_CONTENT}_FAILURE`;

export const SHOW_CAMPAIGN_CONTENT_GRID = 'SHOW_CAMPAIGN_CONTENT_GRID';
export const SHOW_CAMPAIGN_CONTENT_GRID_SUCCESS = `${SHOW_CAMPAIGN_CONTENT_GRID}_SUCCESS`;
export const SHOW_CAMPAIGN_CONTENT_GRID_FAILURE = `${SHOW_CAMPAIGN_CONTENT_GRID}_FAILURE`;

export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS';
export const UPDATE_CAMPAIGN_STATUS_SUCCESS = `${UPDATE_CAMPAIGN_STATUS}_SUCCESS`;
export const UPDATE_CAMPAIGN_STATUS_FAILURE = `${UPDATE_CAMPAIGN_STATUS}_FAILURE`;

export const UPDATE_CAMPAIGN_PROPOSAL = 'UPDATE_CAMPAIGN_PROPOSAL';
export const UPDATE_CAMPAIGN_PROPOSAL_SUCCESS = `${UPDATE_CAMPAIGN_PROPOSAL}_SUCCESS`;
export const UPDATE_CAMPAIGN_PROPOSAL_FAILURE = `${UPDATE_CAMPAIGN_PROPOSAL}_FAILURE`;

export const UPDATE_CAMPAIGN_PROPOSAL_PRICES =
  'UPDATE_CAMPAIGN_PROPOSAL_PRICES';
export const UPDATE_CAMPAIGN_PROPOSAL_PRICES_SUCCESS = `${UPDATE_CAMPAIGN_PROPOSAL_PRICES}_SUCCESS`;
export const UPDATE_CAMPAIGN_PROPOSAL_PRICES_FAILURE = `${UPDATE_CAMPAIGN_PROPOSAL_PRICES}_FAILURE`;

export const BIND_CAMPAIGN_CONTENT = 'CREATE_BIND_CAMPAIGN_CONTENT';
export const BIND_CAMPAIGN_CONTENT_SUCCESS = `${BIND_CAMPAIGN_CONTENT}_SUCCESS`;
export const BIND_CAMPAIGN_CONTENT_FAILURE = `${BIND_CAMPAIGN_CONTENT}_FAILURE`;

export const UPDATE_CAMPAIGN_CONTENT = 'UPDATE_CAMPAIGN_CONTENT';
export const UPDATE_CAMPAIGN_CONTENT_SUCCESS = `${UPDATE_CAMPAIGN_CONTENT}_SUCCESS`;
export const UPDATE_CAMPAIGN_CONTENT_FAILURE = `${UPDATE_CAMPAIGN_CONTENT}_FAILURE`;

export const SHOW_ACTIVE_PROPOSAL = 'SHOW_ACTIVE_PROPOSAL';
export const SHOW_ACTIVE_PROPOSAL_SUCCESS = `${SHOW_ACTIVE_PROPOSAL}_SUCCESS`;
export const SHOW_ACTIVE_PROPOSAL_FAILURE = `${SHOW_ACTIVE_PROPOSAL}_FAILURE`;

export const UPDATE_PROPOSAL_NAME = 'UPDATE_PROPOSAL_NAME';
export const UPDATE_PROPOSAL_NAME_SUCCESS = `${UPDATE_PROPOSAL_NAME}_SUCCESS`;
export const UPDATE_PROPOSAL_NAME_FAILURE = `${UPDATE_PROPOSAL_NAME}_FAILURE`;

export const APPROVE_ACTIVE_PROPOSAL = 'APPROVE_ACTIVE_PROPOSAL';
export const APPROVE_ACTIVE_PROPOSAL_SUCCESS = `${APPROVE_ACTIVE_PROPOSAL}_SUCCESS`;
export const APPROVE_ACTIVE_PROPOSAL_FAILURE = `${APPROVE_ACTIVE_PROPOSAL}_FAILURE`;

export const UPDATE_PROPOSAL_STATUS = 'UPDATE_PROPOSAL_STATUS';
export const UPDATE_PROPOSAL_STATUS_SUCCESS = `${UPDATE_PROPOSAL_STATUS}_SUCCESS`;
export const UPDATE_PROPOSAL_STATUS_FAILURE = `${UPDATE_PROPOSAL_STATUS}_FAILURE`;

export const CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL =
  'CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL';
export const CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL_SUCCESS = `${CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL}_SUCCESS`;
export const CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL_FAILURE = `${CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL}_FAILURE`;

export const UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS =
  'UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS';
export const UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS_SUCCESS = `${UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS}_SUCCESS`;
export const UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS_FAILURE = `${UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS}_FAILURE`;

export const BIND_MANUAL_CONTENT = 'BIND_MANUAL_CONTENT';
export const BIND_MANUAL_CONTENT_SUCCESS = `${BIND_MANUAL_CONTENT}_SUCCESS`;
export const BIND_MANUAL_CONTENT_FAILURE = `${BIND_MANUAL_CONTENT}_FAILURE`;

export const CREATE_MANUAL_BONUS = 'CREATE_MANUAL_BONUS';
export const CREATE_MANUAL_BONUS_SUCCESS = `${CREATE_MANUAL_BONUS}_SUCCESS`;
export const CREATE_MANUAL_BONUS_FAILURE = `${CREATE_MANUAL_BONUS}_FAILURE`;

export const UPDATE_MANUAL_CONTENT = 'UPDATE_MANUAL_CONTENT';
export const UPDATE_MANUAL_CONTENT_SUCCESS = `${UPDATE_MANUAL_CONTENT}_SUCCESS`;
export const UPDATE_MANUAL_CONTENT_FAILURE = `${UPDATE_MANUAL_CONTENT}_FAILURE`;

export const DELETE_MANUAL_CONTENT = 'DELETE_MANUAL_CONTENT';
export const DELETE_MANUAL_CONTENT_SUCCESS = `${DELETE_MANUAL_CONTENT}_SUCCESS`;
export const DELETE_MANUAL_CONTENT_FAILURE = `${DELETE_MANUAL_CONTENT}_FAILURE`;

export const CREATE_COMMENT_ACTIVE_PROPOSAL = 'CREATE_COMMENT_ACTIVE_PROPOSAL';
export const CREATE_COMMENT_ACTIVE_PROPOSAL_SUCCESS = `${CREATE_COMMENT_ACTIVE_PROPOSAL}_SUCCESS`;
export const CREATE_COMMENT_ACTIVE_PROPOSAL_FAILURE = `${CREATE_COMMENT_ACTIVE_PROPOSAL}_FAILURE`;

export const DUPLICATE_CAMPAIGN_PROPOSAL = 'DUPLICATE_CAMPAIGN_PROPOSAL';
export const DUPLICATE_CAMPAIGN_PROPOSAL_SUCCESS = `${DUPLICATE_CAMPAIGN_PROPOSAL}_SUCCESS`;
export const DUPLICATE_CAMPAIGN_PROPOSAL_FAILURE = `${DUPLICATE_CAMPAIGN_PROPOSAL}_FAILURE`;

export const DELETE_CAMPAIGN_PROPOSAL = 'DELETE_CAMPAIGN_PROPOSAL';
export const DELETE_CAMPAIGN_PROPOSAL_SUCCESS = `${DELETE_CAMPAIGN_PROPOSAL}_SUCCESS`;
export const DELETE_CAMPAIGN_PROPOSAL_FAILURE = `${DELETE_CAMPAIGN_PROPOSAL}_FAILURE`;

export const UPDATE_CONTENT_DESCRIPTION = 'UPDATE_CAMPAIGN_CONTENT_DESCRIPTION';
export const UPDATE_CONTENT_DESCRIPTION_SUCCESS = `${UPDATE_CONTENT_DESCRIPTION}_SUCCESS`;
export const UPDATE_CONTENT_DESCRIPTION_FAILURE = `${UPDATE_CONTENT_DESCRIPTION}_FAILURE`;

export const SEND_PROPOSAL_REVIEW = 'SEND_PROPOSAL_REVIEW';
export const SEND_PROPOSAL_REVIEW_SUCCESS = `${SEND_PROPOSAL_REVIEW}_SUCCESS`;
export const SEND_PROPOSAL_REVIEW_FAILURE = `${SEND_PROPOSAL_REVIEW}_FAILURE`;

export const SEND_PROPOSAL_TO_CLIENT = 'SEND_PROPOSAL_TO_CLIENT';
export const SEND_PROPOSAL_TO_CLIENT_SUCCESS = `${SEND_PROPOSAL_TO_CLIENT}_SUCCESS`;
export const SEND_PROPOSAL_TO_CLIENT_FAILURE = `${SEND_PROPOSAL_TO_CLIENT}_FAILURE`;

export const UPDATE_CAMPAIGN_OPS_USERS = 'UPDATE_CAMPAIGN_OPS_USERS';
export const UPDATE_CAMPAIGN_OPS_USERS_SUCCESS = `${SEND_PROPOSAL_TO_CLIENT}_SUCCESS`;
export const UPDATE_CAMPAIGN_OPS_USERS_FAILURE = `${SEND_PROPOSAL_TO_CLIENT}_FAILURE`;

export const UPDATE_CAMPAIGN_COLLABORATORS = 'UPDATE_CAMPAIGN_COLLABORATORS';
export const UPDATE_CAMPAIGN_COLLABORATORS_SUCCESS = `${SEND_PROPOSAL_TO_CLIENT}_SUCCESS`;
export const UPDATE_CAMPAIGN_COLLABORATORS_FAILURE = `${SEND_PROPOSAL_TO_CLIENT}_FAILURE`;

export const UPDATE_BRIEF_COLLABORATORS = 'UPDATE_BRIEF_COLLABORATORS';
export const UPDATE_BRIEF_COLLABORATORS_SUCCESS = `${SEND_PROPOSAL_TO_CLIENT}_SUCCESS`;
export const UPDATE_BRIEF_COLLABORATORS_FAILURE = `${SEND_PROPOSAL_TO_CLIENT}_FAILURE`;
