import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_CAMPAIGN_PROPOSAL_PRICES } from '../constants';

const formatter = ({ formValues: { campaignId, proposalId, cart } }) => {
  const proposal = {
    socialNetworkAccounts: cart,
  };
  return { proposal, campaignId, proposalId };
};

const updateCampaignProposalPricesNotifier = (formValues, callback) =>
  notify({
    type: UPDATE_CAMPAIGN_PROPOSAL_PRICES,
    formValues,
    formatter,
    callback,
  });

function* updateCampaignProposalPricesAction({
  campaignId,
  proposalId,
  proposal,
  callback,
}) {
  yield call(request, {
    type: UPDATE_CAMPAIGN_PROPOSAL_PRICES,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}/priceContext`,
    params: { proposal },
    callback,
  });
}

export {
  updateCampaignProposalPricesAction,
  updateCampaignProposalPricesNotifier,
};
