/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ROOT_PATH } from '../../routing/paths';
import routes, { DEFAULT_TITLE_PREFIX } from '../../routing/routes';
import { getHostDomain } from '../../utils/helperFunctions';
import { useTheme } from '../ThemeManager';

const headComponent = (Component, { title, defaultPrefix }) => ({
  ...props
}) => {
  const prefix = defaultPrefix
    ? DEFAULT_TITLE_PREFIX
    : useSelector((state) => state.layout.tab.prefix);

  const [theme] = useTheme();

  const domain = getHostDomain();

  if (!localStorage.whiteBrand) {
    localStorage.removeItem('token');
  }

  const confLocalStorage = localStorage.getItem(`conf-${domain}`)
    ? JSON.parse(window.localStorage.getItem(`conf-${domain}`))
    : null;

  const logo =
    confLocalStorage && confLocalStorage.imageIco
      ? confLocalStorage.imageIco
      : `/static/themes/${theme.selected}/favicon.ico`;

  const fullTitle = [prefix, title].filter(Boolean).join(' - ');
  return (
    <>
      <Helmet>
        <title>{fullTitle}</title>
        <link rel="icon" type="image/png" href={`${logo}`} sizes="16x16" />
      </Helmet>
      <Component {...props} />
    </>
  );
};

const renderRoutes = (currentRole) =>
  routes.map(
    ({ title, defaultPrefix, path, roles, component, ...routeParams }) => {
      if (!roles || roles.includes(currentRole)) {
        return (
          <Route
            exact
            key={path}
            path={path}
            component={headComponent(component, { title, defaultPrefix })}
            {...routeParams}
          />
        );
      }
      return null;
    }
  );

const RoutesContainer = ({ role }) => (
  <Suspense fallback={<Skeleton active />}>
    <Switch>
      {renderRoutes(role)}
      <Redirect to={ROOT_PATH} />
    </Switch>
  </Suspense>
);

RoutesContainer.propTypes = {
  role: PropTypes.string,
};

RoutesContainer.defaultProps = {
  role: null,
};

export default RoutesContainer;
