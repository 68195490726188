export const INDEX_USERS = 'INDEX_USERS';
export const INDEX_USERS_SUCCESS = `${INDEX_USERS}_SUCCESS`;
export const INDEX_USERS_FAILURE = `${INDEX_USERS}_FAILURE`;

export const INDEX_ADVISOR_USERS = 'INDEX_ADVISOR_USERS';
export const INDEX_ADVISOR_USERS_SUCCESS = `${INDEX_ADVISOR_USERS}_SUCCESS`;
export const INDEX_ADVISOR_USERS_FAILURE = `${INDEX_ADVISOR_USERS}_FAILURE`;

export const INDEX_BRAND_USERS = 'INDEX_BRAND_USERS';
export const INDEX_BRAND_USERS_SUCCESS = `${INDEX_BRAND_USERS}_SUCCESS`;
export const INDEX_BRAND_USERS_FAILURE = `${INDEX_BRAND_USERS}_FAILURE`;

export const CREATE_BRAND_CONTACT = 'CREATE_BRAND_CONTACT';
export const CREATE_BRAND_CONTACT_SUCCESS = `${CREATE_BRAND_CONTACT}_SUCCESS`;
export const CREATE_BRAND_CONTACT_FAILURE = `${CREATE_BRAND_CONTACT}_FAILURE`;

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = `${CREATE_USER}_SUCCESS`;
export const CREATE_USER_FAILURE = `${CREATE_USER}_FAILURE`;

export const SHOW_USER = 'SHOW_USER';
export const SHOW_USER_SUCCESS = `${SHOW_USER}_SUCCESS`;
export const SHOW_USER_FAILURE = `${SHOW_USER}_FAILURE`;

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`;
export const UPDATE_USER_FAILURE = `${UPDATE_USER}_FAILURE`;

export const MANAGER_SEND_INVITE = 'MANAGER_SEND_INVITE';
export const MANAGER_SEND_INVITE_SUCCESS = `${MANAGER_SEND_INVITE}_SUCCESS`;
export const MANAGER_SEND_INVITE_FAILURE = `${MANAGER_SEND_INVITE}_FAILURE`;

export const INFLUENCER_GET_INVITES = 'INFLUENCER_GET_INVITES';
export const INFLUENCER_GET_INVITES_SUCCESS = `${INFLUENCER_GET_INVITES}_SUCCESS`;
export const INFLUENCER_GET_INVITES_FAILURE = `${INFLUENCER_GET_INVITES}_FAILURE`;

export const INFLUENCER_REJECT_INVITE = 'INFLUENCER_REJECT_INVITE';
export const INFLUENCER_REJECT_INVITE_SUCCESS = `${INFLUENCER_REJECT_INVITE}_SUCCESS`;
export const INFLUENCER_REJECT_INVITE_FAILURE = `${INFLUENCER_REJECT_INVITE}_FAILURE`;

export const INFLUENCER_ACCEPT_INVITE = 'INFLUENCER_ACCEPT_INVITE';
export const INFLUENCER_ACCEPT_INVITE_SUCCESS = `${INFLUENCER_ACCEPT_INVITE}_SUCCESS`;
export const INFLUENCER_ACCEPT_INVITE_FAILURE = `${INFLUENCER_ACCEPT_INVITE}_FAILURE`;

export const MANAGER_GET_INVITES = 'MANAGER_GET_INVITES';
export const MANAGER_GET_INVITES_SUCCESS = `${MANAGER_GET_INVITES}_SUCCESS`;
export const MANAGER_GET_INVITES_FAILURE = `${MANAGER_GET_INVITES}_FAILURE`;

export const MANAGER_CANCEL_INVITE = 'MANAGER_CANCEL_INVITE';
export const MANAGER_CANCEL_INVITE_SUCCESS = `${MANAGER_CANCEL_INVITE}_SUCCESS`;
export const MANAGER_CANCEL_INVITE_FAILURE = `${MANAGER_CANCEL_INVITE}_FAILURE`;

export const INFLUENCER_DISMISS_MANAGER = 'INFLUENCER_DISMISS_MANAGER';
export const INFLUENCER_DISMISS_MANAGER_SUCCESS = `${INFLUENCER_DISMISS_MANAGER}_SUCCESS`;
export const INFLUENCER_DISMISS_MANAGER_FAILURE = `${INFLUENCER_DISMISS_MANAGER}_FAILURE`;

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = `${CHANGE_PASSWORD}_SUCCESS`;
export const CHANGE_PASSWORD_FAILURE = `${CHANGE_PASSWORD}_FAILURE`;

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = `${DELETE_USER}_SUCCESS`;
export const DELETE_USER_FAILURE = `${DELETE_USER}_FAILURE`;
