import { takeLatest } from 'redux-saga/effects';
import { changePasswordAction } from './actions/changePassword';
import { createBrandContactAction } from './actions/createBrandContact';
import { createUserAction } from './actions/createUser';
import { indexAdvisorUsersAction } from './actions/indexAdvisorUsers';
import { indexBrandUsersAction } from './actions/indexBrandUsers';
import { indexUsersAction } from './actions/indexUsers';
import { showUserAction } from './actions/showUser';
import { deleteUserAction } from './actions/deleteUser';
import {
  CHANGE_PASSWORD,
  CREATE_BRAND_CONTACT,
  CREATE_USER,
  INDEX_ADVISOR_USERS,
  INDEX_BRAND_USERS,
  INDEX_USERS,
  SHOW_USER,
  DELETE_USER,
  UPDATE_USER,
} from './constants';
import { updateUserAction } from './actions/updateUser';

export default [
  takeLatest(CREATE_USER, createUserAction),
  takeLatest(INDEX_USERS, indexUsersAction),
  takeLatest(INDEX_ADVISOR_USERS, indexAdvisorUsersAction),
  takeLatest(INDEX_BRAND_USERS, indexBrandUsersAction),
  takeLatest(CHANGE_PASSWORD, changePasswordAction),
  takeLatest(CREATE_BRAND_CONTACT, createBrandContactAction),
  takeLatest(SHOW_USER, showUserAction),
  takeLatest(DELETE_USER, deleteUserAction),
  takeLatest(UPDATE_USER, updateUserAction),
];
