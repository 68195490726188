import { call } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import request from '../../../helpers/request';
import { BIND_MANUAL_CONTENT } from '../constants';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';
import { formatDate } from '../../../../utils/helperFunctions';

const formatter = ({ formValues, campaignId, contentId }) => {
  const params = cloneDeep(formValues);
  if (params.publishedDate) {
    params.publishedDate = formatDate(params.publishedDate, 'YYYY-MM-DD');
  }
  return {
    formValues: objectToFormData(params),
    campaignId,
    contentId,
  };
};

const bindManualContentNotifier = (
  formValues,
  campaignId,
  contentId,
  callback
) =>
  notify({
    type: BIND_MANUAL_CONTENT,
    formValues,
    formatter,
    campaignId,
    contentId,
    callback,
  });

function* bindManualContentAction({
  formValues,
  campaignId,
  contentId,
  callback,
}) {
  yield call(request, {
    type: BIND_MANUAL_CONTENT,
    method: 'post',
    endpoint: `/campaigns/${campaignId}/contents/${contentId}/published`,
    params: formValues,
    callback,
  });
}

export { bindManualContentAction, bindManualContentNotifier };
