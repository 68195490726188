/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { t } from '../../utils/helperFunctions';
import FileFiled from './FileField';
import FloatLabel from './FloatLabel';
import './input.css';
import OTPField from './OTPField';

export const arrToLabels = (arr) =>
  arr.map((item) => ({ value: item, label: item }));

export const createLabels = (options = [], translate = true, render = null) => {
  const renderOption = (option) => {
    if (render) return render(option);
    return translate ? t(`labels.${option.label}`) : option.label;
  };
  const labels = options.map((option) => (
    <Select.Option key={option.value} title={option.label} value={option.value}>
      {renderOption(option)}
    </Select.Option>
  ));
  return labels;
};

export const createRadios = (options = [], translate = true, render = null) => {
  const renderOption = (option) => {
    if (render) return render(option);
    return translate ? t(`labels.${option.label}`) : option.label;
  };
  const labels = options.map((option) => (
    <Radio key={option.value} value={option.value}>
      {renderOption(option)}
    </Radio>
  ));
  return labels;
};

export const createBrandLabels = (options = [], render = null) => {
  const renderOption = (option) => {
    if (render) return render(option);
    return option.label;
  };
  const labels = options.map((option) => (
    <Select.Option key={option.value} title={option.label} value={option.value}>
      {renderOption(option)}
    </Select.Option>
  ));
  return labels;
};

const createField = (Component, options = {}) => ({
  style,
  input,
  itemClassName,
  meta,
  children,
  hasFeedback = true,
  label,
  info,
  infoError,
  placeholder,
  ...rest
}) => {
  const { touched, initial, invalid, error } = meta;

  const newInput = input;
  let newChildren = children;

  if (options.checkbox) {
    newInput.checked = input.value;
    if (input.value === '' || input.value === 'false') {
      newInput.checked = false;
    } else if (input.value === 'true') {
      newInput.checked = true;
    }
  }
  if (options.multipleSelect) {
    newInput.value = input.value ? input.value : [];
    newInput.optionFilterProp = 'title';
    newInput.mode = 'multiple';
  }
  if (rest.labels) {
    newChildren = createLabels(rest.labels);
  }
  if (options.select || options.file) {
    newInput.value = input.value ? input.value : undefined;
  }

  if (options.select) {
    // eslint-disable-next-line
    rest.onChange = (e) => {
      newInput.onChange(e || null);
    };
  }

  if ((options.select || options.multipleSelect) && children) {
    newInput.value = children.length > 0 ? newInput.value : null;
  }

  if (options.select || options.label || options.multipleSelect) {
    newInput.showSearch = true;
    newInput.allowClear = true;
    newInput.optionFilterProp = 'title';
    newInput.notFoundContent = 'Sin resultados';
    newInput.getPopupContainer = () => document.getElementById('root');
  }
  if (options.datePicker) {
    newInput.format = rest.dateFormat || 'DD-MM-YYYY';

    if (!input.value) {
      newInput.value = null;
    } else {
      newInput.value = moment(input.value, newInput.format);
      if (newInput.value && !newInput.value.isValid()) {
        newInput.value = moment();
      }
    }

    newInput.selected = input.value
      ? moment(input.value, newInput.format)
      : null;
    newInput.placeholder = null;
    newInput.label = null;
  }
  if (options.rangePicker) {
    newInput.format = rest.dateFormat;
    if (!input.value) {
      newInput.value = [];
    }

    if (newInput.value[0]) {
      newInput.value[0] = moment.utc(newInput.value[0], 'YYYY-MM-DD');
    }
    if (newInput.value[1]) {
      newInput.value[1] = moment.utc(newInput.value[1], 'YYYY-MM-DD');
    }
  }
  if (options.textArea) {
    newInput.placeholder = placeholder;
  }

  const validate = () => {
    if (!touched && !initial) {
      return '';
    }
    return invalid ? 'error' : 'success';
  };

  const className = () => {
    const computedClass = [];
    if (itemClassName) computedClass.push(itemClassName);
    if (input.value) computedClass.push('has-value');
    if (options.noErrorIcon) computedClass.push('no-error-icon');
    if (options.textArea) computedClass.push('text-area');

    return computedClass.join(' ');
  };

  const infoErrorText = infoError ? 'error' : '';
  return (
    <Form.Item
      style={style}
      className={className()}
      validateStatus={infoErrorText || validate()}
      hasFeedback={false}
      help={(touched && hasFeedback && error) || info || infoError}
    >
      <FloatLabel
        float={!options.file}
        value={options.fixedLabel || newInput.value}
        label={label || placeholder}
      >
        <Component meta={meta} id={input.name} {...newInput} {...rest}>
          {newChildren}
        </Component>
      </FloatLabel>
    </Form.Item>
  );
};

/* eslint-disable react/destructuring-assignment */
const CustomInputNumber = (props) => (
  <>
    <InputNumber style={{ width: '100%' }} {...props} />
    {props.addonAfter && (
      <span style={{ right: '52px' }} className="ant-input-group-addon">
        {props.addonAfter}
      </span>
    )}
  </>
);
export const CreateTextField = createField(Input);
export const CreateNumberField = createField(CustomInputNumber);
export const CreateDateField = createField(DatePicker, {
  noErrorIcon: true,
  datePicker: true,
});

export const CreateMonthField = createField(DatePicker.MonthPicker, {
  noErrorIcon: true,
  datePicker: true,
});
export const CreateRangePicker = createField(DatePicker.RangePicker, {
  noErrorIcon: true,
  rangePicker: true,
  fixedLabel: true,
});
export const CreateTextArea = createField(Input.TextArea, { textArea: true });
export const CreatePasswordField = createField(Input.Password);
export const CreateSelectField = createField(Select, { select: true });
export const CreateRadioGroup = createField(Radio.Group, { noErrorIcon: true });
export const CreateMultipleSelectField = createField(Select, {
  multipleSelect: true,
});
export const CreateCheckbox = createField(Checkbox, {
  checkbox: true,
  noErrorIcon: true,
});
export const CreateFileField = createField(FileFiled, {
  noErrorIcon: true,
  file: true,
});
export const CreateOTPField = createField(OTPField, { noErrorIcon: true });
