/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-throw-literal */
import i18n from 'i18next';
import moment from 'moment';
import { equals, isEmail } from 'validator';
import { t } from '../../utils/helperFunctions';

// TODO: Translations
export const validationInstagramUrl = (value) =>
  value && !value.includes('instagram')
    ? i18n.t('contentManualProposal.urlError')
    : null;

export const validationFacebookUrl = (value) =>
  value && !value.includes('facebook')
    ? i18n.t('contentManualProposal.urlError')
    : null;
export const validationTiktokUrl = (value) =>
  value && !value.includes('tiktok')
    ? i18n.t('contentManualProposal.urlError')
    : null;
export const validationYouTubeUrl = (value) =>
  value && !value.includes('youtube')
    ? i18n.t('contentManualProposal.urlError')
    : null;
export const required = (value) =>
  value ? null : i18n.t('validations.requiredField');
export const requiredDate = (value) =>
  value && value !== 'Invalid date'
    ? null
    : i18n.t('validations.requiredField');
export const email = (value) =>
  value
    ? isEmail(value.trim())
      ? null
      : i18n.t('validations.invalidEmail')
    : null;
export const passwordRequirements = (value) =>
  value && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{10,}/.test(value)
    ? i18n.t('validations.passwordRequirements')
    : null;
export const validUrl = (value) => {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return t('validations.invalidUrl');
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
    ? null
    : t('validations.invalidUrl');
  // const pattern = new RegExp(
  //   '^(https?:\\/\\/)?' + // protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //     '(\\#[-a-z\\d_]*)?$',
  //   'i'
  // ); // fragment locator
  // return !pattern.test(value) ? t('validations.invalidUrl') : null;
};

export const emailsFromFluvip = (value) => {
  const pattern = new RegExp('[a-z]+@fluvip.com');

  return pattern.test(value);
};

export const notFluvipEmail = (value) =>
  emailsFromFluvip(value) ? i18n.t('validations.invalidEmail') : null;
export const passwordMatch = (value, allValues) =>
  equals(value, allValues.password) ? null : t('validations.passwordMatch');
export const arrayRequired = (value) =>
  value && value[0] ? null : t('validations.arrayRequired');
export const percentage = (value) =>
  value >= 0 && value <= 100 ? null : t('validations.percentage');

export const rate = (value) =>
  value >= 0 && value <= 1 ? null : t('validations.percentage');

export const validDate = (value) =>
  value.length === 10 && moment(value, 'DD/MM/YYYY').isValid()
    ? undefined
    : 'Fecha invalida (DD/MM/YYYY)';
export const thirteenOrOlder = (value) =>
  moment().diff(moment(value, 'DD/MM/YYY/'), 'years') >= 13
    ? undefined
    : 'Debes tener más de 13 años';
export const eighteenOrOlder = (value) =>
  moment().diff(moment(value, 'DD/MM/YYY/'), 'years') >= 18
    ? undefined
    : 'Debes tener más de 13 años';

const minLength = (min, type = 'characters') => (value) =>
  value && value.length < min
    ? `${t('validations.minLength')} ${min} ${t(`layout.${type}`)}`
    : null;
export const minLength2 = minLength(2);
export const minLength3 = minLength(3);
export const minLength7 = minLength(7);
export const minLength6 = minLength(6);
export const minLength8 = minLength(8);
export const minLength10 = minLength(10);

const maxLength = (max, type = 'characters') => (value) =>
  value && value.length > max
    ? `${t('validations.maxLength')} ${max} ${t(`layout.${type}`)}`
    : null;

export const maxLength3 = maxLength(3);
export const maxLength1 = maxLength(1);
export const categoriesMaxLength = maxLength(5, 'categories');
export const maxLength10 = maxLength(10);
export const maxLength15 = maxLength(15);
export const maxLength30 = maxLength(30);
export const maxLength50 = maxLength(50);
export const maxLength300 = maxLength(300);
export const maxLength700 = maxLength(700);
export const maxLength1000 = maxLength(1000);
export const maxLength1500 = maxLength(1500);

const fileType = (extensions) => (file) =>
  typeof file === 'string' || extensions.includes(file.type.split('/')[1])
    ? null
    : i18n.t('validations.fileType') + extensions;
export const imageType = (file) => fileType('jpg, jpeg, png, svg+xml')(file);
export const imageTypeIco = (file) => fileType('vnd.microsoft.icon')(file);

export const fileSize = (file) =>
  file && file.size > 2000000
    ? 'El archivo es obligatorio y debe pesar menos de 2MB'
    : undefined;

const imageDimensions = (file, dimensions) =>
  new Promise((resolve) => {
    if (typeof file === 'string') {
      resolve(null);
    } else {
      const fr = new FileReader();
      fr.onload = () => {
        const img = new Image();
        img.onload = () => {
          const response =
            img.width === dimensions[0] && img.height === dimensions[1]
              ? null
              : `${i18n.t('validations.imageDimensions')}${dimensions[0]} x ${
                  dimensions[1]
                }`;
          resolve(response);
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  });

export const brandDimensions = ({ logo, banner }) =>
  new Promise((resolve, reject) => {
    imageDimensions(logo, [150, 150]).then((logoFB) =>
      imageDimensions(banner, [400, 200]).then((bannerFB) => {
        if (logoFB || bannerFB) {
          reject({
            logo: logoFB,
            banner: bannerFB,
          });
        }
        resolve();
      })
    );
  });

export const sntDimensions = ({ avatar }) =>
  new Promise((resolve, reject) => {
    if (!avatar) reject({ avatar: 'El avatar es requerido' });
    else {
      imageDimensions(avatar, [150, 150]).then((ppFB) => {
        if (ppFB) {
          reject({
            avatar: ppFB,
          });
        }
        resolve();
      });
    }
  });
