import { call } from 'redux-saga/effects';
import { ADMIN_SNAS } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { CREATE_SNT } from '../constants';
import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues }) => {
  return {
    formValues: objectToFormData(formValues),
  };
};

const createSNTNotifier = (formValues) =>
  notify({
    type: CREATE_SNT,
    formValues,
    formatter,
  });

function* createSNTAction({ formValues }) {
  yield call(request, {
    type: CREATE_SNT,
    method: 'post',
    endpoint: '/social-network-traces/',
    params: formValues,
    path: ADMIN_SNAS,
  });
}

export { createSNTAction, createSNTNotifier };
