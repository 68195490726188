import { LOG_OUT_MOVEMENT } from '../../movements/constants';
import {
  GET_BRAND_SUCCESS,
  CREATE_BRAND_AGENCY_SUCCESS,
} from '../../sagas/brands/constants';
import { GET_BRIEF_SUCCESS } from '../../sagas/briefs/constants';
import {
  APPROVE_ACTIVE_PROPOSAL_SUCCESS,
  BIND_CAMPAIGN_CONTENT_SUCCESS,
  CREATE_COMMENT_ACTIVE_PROPOSAL_SUCCESS,
  CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL_SUCCESS,
  SHOW_ACTIVE_PROPOSAL_SUCCESS,
  SHOW_CAMPAIGN_CONTENT_GRID_SUCCESS,
  SHOW_CAMPAIGN_CONTENT_SUCCESS,
  SHOW_CAMPAIGN_PROPOSAL_PREVIEW_SUCCESS,
  SHOW_CAMPAIGN_PROPOSAL_SUCCESS,
  SHOW_CAMPAIGN_SUCCESS,
  UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS_SUCCESS,
  UPDATE_PROPOSAL_STATUS_SUCCESS,
  SHOW_CAMPAIGN_REPORT_SUCCESS,
} from '../../sagas/campaigns/constants';
import { GET_CURRENCY_SUCCESS } from '../../sagas/currencies/constants';
import {
  GET_EXCHANGE_SUCCESS,
  SHOW_EXCHANGE_SUCCESS,
} from '../../sagas/exchanges/constants';
import {
  SHOW_CAMPAIGNS_GLOBAL_INDICATORS_SUCCESS,
  SHOW_CAMPAIGNS_INDICATORS_SUCCESS,
  SHOW_CONTENTS_INDICATORS_SUCCESS,
  SHOW_CUSTOMER_INDICATORS_SUCCESS,
  SHOW_RANKING_INDICATORS_SUCCESS,
} from '../../sagas/indicators/constants';
import { SHOW_SNA_SUCCESS } from '../../sagas/socialNetworkAccounts/constants';
import {
  SHOW_SNT_SUCCESS,
  SHOW_INFO_SNT_SUCCESS,
  RELOAD_SNT_SUCCESS,
} from '../../sagas/socialNetworkTraces/constants';
import {
  SHOW_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from '../../sagas/users/constants';
import { GET_WHITE_LABEL_SUCCESS } from '../../sagas/whiteLabels/constants';

export const INITIAL_STATE = null;

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_BRAND_SUCCESS:
    case SHOW_SNA_SUCCESS:
    case SHOW_SNT_SUCCESS:
    case RELOAD_SNT_SUCCESS:
    case GET_BRIEF_SUCCESS: {
      return { ...state, ...payload };
    }
    case SHOW_CAMPAIGN_SUCCESS:
    case SHOW_ACTIVE_PROPOSAL_SUCCESS:
    case SHOW_CAMPAIGN_PROPOSAL_PREVIEW_SUCCESS:
    case SHOW_CAMPAIGN_PROPOSAL_SUCCESS:
    case APPROVE_ACTIVE_PROPOSAL_SUCCESS:
    case UPDATE_PROPOSAL_STATUS_SUCCESS:
    case CREATE_COMMENT_ACTIVE_PROPOSAL_SUCCESS:
    case SHOW_CAMPAIGN_CONTENT_SUCCESS:
    case SHOW_CAMPAIGN_CONTENT_GRID_SUCCESS:
    case SHOW_USER_SUCCESS:
    case SHOW_EXCHANGE_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case SHOW_CAMPAIGN_REPORT_SUCCESS: {
      const { socialNetwork } = payload;
      if (
        socialNetwork === 'Instagram' &&
        typeof payload.insights.engagement !== 'undefined' &&
        payload.insights.engagement.video === 0 &&
        typeof state !== 'undefined' &&
        state !== null
      ) {
        const insights = {
          ...payload.insights,
          engagement: {
            ...payload.insights.engagement,
            video: state.insights.engagement.video,
          },
        };
        return { ...state, insights };
      }
      return { ...payload };
    }
    case SHOW_INFO_SNT_SUCCESS: {
      const {
        activities,
        avatar,
        bio,
        description,
        categories,
        contactInfo,
        countryCode,
        followers,
        name,
        socialNetwork,
        url,
        username,
        insights,
      } = payload;
      if (socialNetwork !== 'Instagram') {
        return {
          ...state,
          activities,
          avatar,
          bio,
          description,
          categories,
          contactInfo,
          countryCode,
          followers,
          insights,
          name,
          socialNetwork,
          url,
          username,
        };
      }
      if (
        (payload.insights.engagement.photo === 0 ||
          payload.insights.engagement.carousel === 0 ||
          payload.insights.engagement.video === 0) &&
        typeof state !== 'undefined' &&
        state !== null
      ) {
        const previousPhoto =
          state.insights.engagement && state.insights.engagement.photo
            ? state.insights.engagement.photo
            : 0;
        const previousCarousel =
          state.insights.engagement && state.insights.engagement.carousel
            ? state.insights.engagement.carousel
            : 0;
        const previousVideo =
          state.insights.engagement && state.insights.engagement.video
            ? state.insights.engagement.video
            : 0;

        const engagement = {
          ...payload.insights.engagement,
          photo:
            payload.insights.engagement.photo === 0
              ? previousPhoto
              : payload.insights.engagement.photo,
          carousel:
            payload.insights.engagement.carousel === 0
              ? previousCarousel
              : payload.insights.engagement.carousel,
          video:
            payload.insights.engagement.video === 0
              ? previousVideo
              : payload.insights.engagement.video,
        };
        return payload.typeApiNetwork !== 'manual'
          ? {
              ...state,
              categories,
              insights: { ...payload.insights, engagement },
              contactInfo,
            }
          : {
              ...state,
              categories,
              insights: { ...payload.insights, engagement },
              contactInfo,
              avatar,
              bio,
              description,
              name,
              followers,
              username,
              url,
              socialNetwork,
            };
      }
      return payload.typeApiNetwork !== 'manual'
        ? {
            ...state,
            categories,
            insights,
            contactInfo,
          }
        : {
            ...state,
            categories,
            insights,
            contactInfo,
            avatar,
            bio,
            description,
            name,
            followers,
            username,
            url,
            socialNetwork,
          };
    }
    case CREATE_BRAND_AGENCY_SUCCESS: {
      return { ...state, ...payload };
    }
    case GET_WHITE_LABEL_SUCCESS: {
      return { ...state, ...payload };
    }
    case SHOW_CAMPAIGNS_GLOBAL_INDICATORS_SUCCESS: {
      return { ...state, globalIndicators: { ...payload } };
    }
    case SHOW_CONTENTS_INDICATORS_SUCCESS: {
      return { ...state, contents: { ...payload } };
    }
    case SHOW_CAMPAIGNS_INDICATORS_SUCCESS:
      return { ...state, campaigns: { ...payload } };
    case SHOW_CUSTOMER_INDICATORS_SUCCESS: {
      return { ...state, customers: { ...payload } };
    }
    case SHOW_RANKING_INDICATORS_SUCCESS: {
      return { ...state, rankings: { ...payload } };
    }
    case GET_CURRENCY_SUCCESS: {
      return { ...state, ...payload };
    }
    case GET_EXCHANGE_SUCCESS: {
      return { ...state, ...payload };
    }
    case UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS_SUCCESS:
    case CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL_SUCCESS:
    case BIND_CAMPAIGN_CONTENT_SUCCESS:
      return state;
    case LOG_OUT_MOVEMENT:
    case '@@router/LOCATION_CHANGE':
      return INITIAL_STATE;
    default:
      return state;
  }
};
