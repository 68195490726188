import { takeLatest } from 'redux-saga/effects';
import { createBrandAction } from './actions/createBrand';
import { createBrandAgencyAction } from './actions/createBrandAgency';
import { deleteBrandAction } from './actions/deleteBrand';
import { deleteBrandAgencyAction } from './actions/deleteBrandAgency';
import { indexBrandsAction } from './actions/indexBrands';
import { showBrandAction } from './actions/showBrand';
import { updateBrandAction } from './actions/updateBrand';
import { updateBrandAgencyAction } from './actions/updateBrandAgency';
import { updateBrandUserAction } from './actions/updateBrandUser';
import {
  CREATE_BRAND,
  CREATE_BRAND_AGENCY,
  DELETE_BRAND_AGENCY,
  DELETE_BRAND,
  GET_BRAND,
  INDEX_BRANDS,
  SHOW_BRAND,
  UPDATE_BRAND,
  UPDATE_BRAND_AGENCY,
  UPDATE_BRAND_USER,
} from './constants';

export default [
  takeLatest(CREATE_BRAND, createBrandAction),
  takeLatest(INDEX_BRANDS, indexBrandsAction),
  takeLatest(GET_BRAND, showBrandAction),
  takeLatest(SHOW_BRAND, showBrandAction),
  takeLatest(UPDATE_BRAND, updateBrandAction),
  takeLatest(CREATE_BRAND_AGENCY, createBrandAgencyAction),
  takeLatest(UPDATE_BRAND_AGENCY, updateBrandAgencyAction),
  takeLatest(DELETE_BRAND_AGENCY, deleteBrandAgencyAction),
  takeLatest(DELETE_BRAND, deleteBrandAction),
  takeLatest(UPDATE_BRAND_USER, updateBrandUserAction),
];
