export const INDEX_EXCHANGES = 'INDEX_EXCHANGES';
export const INDEX_EXCHANGES_SUCCESS = `${INDEX_EXCHANGES}_SUCCESS`;
export const INDEX_EXCHANGES_FAILURE = `${INDEX_EXCHANGES}_FAILURE`;

export const GET_EXCHANGE = 'GET_EXCHANGE';
export const GET_EXCHANGE_SUCCESS = `${GET_EXCHANGE}_SUCCESS`;
export const GET_EXCHANGE_FAILURE = `${GET_EXCHANGE}_FAILURE`;

export const SHOW_EXCHANGE = 'SHOW_EXCHANGE';
export const SHOW_EXCHANGE_SUCCESS = `${SHOW_EXCHANGE}_SUCCESS`;
export const SHOW_EXCHANGE_FAILURE = `${SHOW_EXCHANGE}_FAILURE`;

export const UPDATE_EXCHANGE = 'UPDATE_EXCHANGE';
export const UPDATE_EXCHANGE_SUCCESS = `${UPDATE_EXCHANGE}_SUCCESS`;
export const UPDATE_EXCHANGE_FAILURE = `${UPDATE_EXCHANGE}_FAILURE`;

export const DELETE_EXCHANGE = 'DELETE_EXCHANGE';
export const DELETE_EXCHANGE_SUCCESS = `${DELETE_EXCHANGE}_SUCCESS`;
export const DELETE_EXCHANGE_FAILURE = `${DELETE_EXCHANGE}_FAILURE`;

export const CREATE_EXCHANGE = 'CREATE_EXCHANGE';
export const CREATE_EXCHANGE_SUCCESS = `${CREATE_EXCHANGE}_SUCCESS`;
export const CREATE_EXCHANGE_FAILURE = `${CREATE_EXCHANGE}_FAILURE`;
