import { takeLatest } from 'redux-saga/effects';
import { approveProposalAction } from './actions/approveProposal';
import { bindCampaignContentAction } from './actions/bindCampaignContent';
import { bindManualContentAction } from './actions/bindCampaignManualContent';
import { createManualBonustAction } from './actions/createCampaignManualBonus';
import { updateManualContentAction } from './actions/updateCampaignManualContent';
import { deleteManualContentAction } from './actions/deleteCampaignManualContent';
import { createCampaignAction } from './actions/createCampaign';
import { createContentProposalCommentAction } from './actions/createContentProposalComment';
import { createProposalCommentAction } from './actions/createProposalComment';
import { deleteCampaignProposalAction } from './actions/deleteCampaignProposal';
import { duplicateProposalAction } from './actions/duplicateProposal';
import { indexCampaignsAction } from './actions/indexCampaigns';
import { indexFinanceCampaignsAction } from './actions/indexCampaignsFinance';
import { sendProposalReviewAction } from './actions/sendProposalReview';
import { sendProposalToClientAction } from './actions/sendProposalToClient';
import { showCampaignAction } from './actions/showCampaign';
import { showCampaignContentAction } from './actions/showCampaignContent';
import { showCampaignContentGridAction } from './actions/showCampaignContentGrid';
import { showCampaignProposalAction } from './actions/showCampaignProposal';
import { showCampaignReportAction } from './actions/showCampaignReport';
import { showProposalAction } from './actions/showProposal';
import { showProposalPreviewAction } from './actions/showProposalPreview';
import { updateCampaignOpsUsersAction } from './actions/updateCampaignOpsUsers';
import { updateCampaignProposalAction } from './actions/updateCampaignProposal';
import { updateCampaignProposalPricesAction } from './actions/updateCampaignProposalPrices';
import { updateCampaignStatusAction } from './actions/updateCampaignStatus';
import { updateContentDescriptionAction } from './actions/updateContentDescription';
import { updateContentProposalStatusAction } from './actions/updateContentProposalStatus';
import { updateProposalNameAction } from './actions/updateProposalName';
import { updateProposalStatusAction } from './actions/updateProposalStatus';
import { updateCampaignCollaboratorsAction } from './actions/updateCampaignCollaborators';
import { updateBriefCollaboratorsAction } from './actions/updateBriefCollaborators';
import {
  APPROVE_ACTIVE_PROPOSAL,
  BIND_CAMPAIGN_CONTENT,
  BIND_MANUAL_CONTENT,
  CREATE_MANUAL_BONUS,
  UPDATE_MANUAL_CONTENT,
  DELETE_MANUAL_CONTENT,
  CREATE_CAMPAIGN,
  CREATE_COMMENT_ACTIVE_PROPOSAL,
  CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL,
  DELETE_CAMPAIGN_PROPOSAL,
  DUPLICATE_CAMPAIGN_PROPOSAL,
  INDEX_CAMPAIGNS,
  INDEX_FINANCE_CAMPAIGNS,
  SEND_PROPOSAL_REVIEW,
  SEND_PROPOSAL_TO_CLIENT,
  SHOW_ACTIVE_PROPOSAL,
  SHOW_CAMPAIGN,
  SHOW_CAMPAIGN_CONTENT,
  SHOW_CAMPAIGN_CONTENT_GRID,
  SHOW_CAMPAIGN_PROPOSAL,
  SHOW_CAMPAIGN_PROPOSAL_PREVIEW,
  SHOW_CAMPAIGN_PROPOSAL_MISC,
  UPDATE_CAMPAIGN_OPS_USERS,
  UPDATE_CAMPAIGN_PROPOSAL,
  UPDATE_CAMPAIGN_PROPOSAL_PRICES,
  UPDATE_CAMPAIGN_STATUS,
  UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS,
  UPDATE_CONTENT_DESCRIPTION,
  UPDATE_PROPOSAL_NAME,
  UPDATE_PROPOSAL_STATUS,
  SHOW_CAMPAIGN_REPORT,
  UPDATE_CAMPAIGN_COLLABORATORS,
  UPDATE_BRIEF_COLLABORATORS,
} from './constants';

export default [
  takeLatest(CREATE_CAMPAIGN, createCampaignAction),
  takeLatest(INDEX_CAMPAIGNS, indexCampaignsAction),
  takeLatest(INDEX_FINANCE_CAMPAIGNS, indexFinanceCampaignsAction),
  takeLatest(SHOW_CAMPAIGN, showCampaignAction),
  takeLatest(SHOW_CAMPAIGN_PROPOSAL, showCampaignProposalAction),
  takeLatest(SHOW_CAMPAIGN_PROPOSAL_MISC, showCampaignProposalAction),
  takeLatest(SHOW_ACTIVE_PROPOSAL, showProposalAction),
  takeLatest(APPROVE_ACTIVE_PROPOSAL, approveProposalAction),
  takeLatest(CREATE_COMMENT_ACTIVE_PROPOSAL, createProposalCommentAction),
  takeLatest(UPDATE_CAMPAIGN_PROPOSAL, updateCampaignProposalAction),
  takeLatest(
    UPDATE_CAMPAIGN_PROPOSAL_PRICES,
    updateCampaignProposalPricesAction
  ),
  takeLatest(UPDATE_PROPOSAL_NAME, updateProposalNameAction),
  takeLatest(UPDATE_CAMPAIGN_STATUS, updateCampaignStatusAction),
  takeLatest(UPDATE_PROPOSAL_STATUS, updateProposalStatusAction),
  takeLatest(DUPLICATE_CAMPAIGN_PROPOSAL, duplicateProposalAction),
  takeLatest(DELETE_CAMPAIGN_PROPOSAL, deleteCampaignProposalAction),
  takeLatest(SHOW_CAMPAIGN_CONTENT_GRID, showCampaignContentGridAction),
  takeLatest(SHOW_CAMPAIGN_CONTENT, showCampaignContentAction),
  takeLatest(UPDATE_CONTENT_DESCRIPTION, updateContentDescriptionAction),
  takeLatest(
    CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL,
    createContentProposalCommentAction
  ),
  takeLatest(
    UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS,
    updateContentProposalStatusAction
  ),
  takeLatest(BIND_CAMPAIGN_CONTENT, bindCampaignContentAction),
  takeLatest(BIND_MANUAL_CONTENT, bindManualContentAction),
  takeLatest(CREATE_MANUAL_BONUS, createManualBonustAction),
  takeLatest(UPDATE_MANUAL_CONTENT, updateManualContentAction),
  takeLatest(DELETE_MANUAL_CONTENT, deleteManualContentAction),
  takeLatest(SHOW_CAMPAIGN_PROPOSAL_PREVIEW, showProposalPreviewAction),
  takeLatest(SEND_PROPOSAL_REVIEW, sendProposalReviewAction),
  takeLatest(SEND_PROPOSAL_TO_CLIENT, sendProposalToClientAction),
  takeLatest(UPDATE_CAMPAIGN_OPS_USERS, updateCampaignOpsUsersAction),
  takeLatest(SHOW_CAMPAIGN_REPORT, showCampaignReportAction),
  takeLatest(UPDATE_CAMPAIGN_COLLABORATORS, updateCampaignCollaboratorsAction),
  takeLatest(UPDATE_BRIEF_COLLABORATORS, updateBriefCollaboratorsAction),
];
