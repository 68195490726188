import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { CREATE_COMMENT_ACTIVE_PROPOSAL } from '../constants';

const formatter = ({ campaignId, userId, comment }) => ({
  campaignId,
  formValues: {
    user: userId,
    comment,
  },
});

const createProposalCommentNotifier = (campaignId, userId, comment) => {
  return notify({
    type: CREATE_COMMENT_ACTIVE_PROPOSAL,
    campaignId,
    userId,
    comment,
    formatter,
  });
};

function* createProposalCommentAction({ campaignId, formValues }) {
  yield call(request, {
    type: CREATE_COMMENT_ACTIVE_PROPOSAL,
    method: 'post',
    endpoint: `campaigns/${campaignId}/activeProposal/comment`,
    params: formValues,
  });
}
export { createProposalCommentAction, createProposalCommentNotifier };
