import PropTypes, { string, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { authSelector } from '../../redux/reducers/authReducer/authSelectors';
import {
  BRAND_CLIENT,
  ADMIN_WHITE_LABELS,
  INFLUENCER_CONSULTANT,
} from '../../utils/roles';
// eslint-disable-next-line import/no-named-as-default
import RootPages from '../Auth/RootPages';

const rolePath = {
  [ADMIN_WHITE_LABELS]: 'white-label',
  [BRAND_CLIENT]: 'finance',
  [INFLUENCER_CONSULTANT]: 'social-network-accounts',
};

const Root = ({ auth: { token, role }, history }) => {
  if (token) {
    history.push(rolePath[role] || '/campaigns?tab=briefs');
    return <></>;
  }
  return <RootPages />;
};

Root.propTypes = {
  auth: PropTypes.shape({
    token: string,
    role: string,
  }).isRequired,
  history: object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: authSelector(state),
});

export default connect(mapStateToProps, null)(Root);
