export const INDEX_CURRENCIES = 'INDEX_CURRENCIES';
export const INDEX_CURRENCIES_SUCCESS = `${INDEX_CURRENCIES}_SUCCESS`;
export const INDEX_CURRENCIES_FAILURE = `${INDEX_CURRENCIES}_FAILURE`;

export const CREATE_CURRENCY = 'CREATE_CURRENCY';
export const CREATE_CURRENCY_SUCCESS = `${CREATE_CURRENCY}_SUCCESS`;
export const CREATE_CURRENCY_FAILURE = `${CREATE_CURRENCY}_FAILURE`;

export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_CURRENCY_SUCCESS = `${GET_CURRENCY}_SUCCESS`;
export const GET_CURRENCY_FAILURE = `${GET_CURRENCY}_FAILURE`;

export const SHOW_CURRENCY = 'SHOW_CURRENCY';
export const SHOW_CURRENCY_SUCCESS = `${SHOW_CURRENCY}_SUCCESS`;
export const SHOW_CURRENCY_FAILURE = `${SHOW_CURRENCY}_FAILURE`;

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_CURRENCY_SUCCESS = `${UPDATE_CURRENCY}_SUCCESS`;
export const UPDATE_CURRENCY_FAILURE = `${UPDATE_CURRENCY}_FAILURE`;

export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const DELETE_CURRENCY_SUCCESS = `${DELETE_CURRENCY}_SUCCESS`;
export const DELETE_CURRENCY_FAILURE = `${DELETE_CURRENCY}_FAILURE`;
