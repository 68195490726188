import { call } from 'redux-saga/effects';
import { likeRegex } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { INDEX_BRANDS } from '../constants';

// eslint-disable-next-line no-unused-vars
const formatter = ({
  formValues: {
    query: { name, industry, status, whiteBrand },
    ...rest
  },
  external,
}) => ({
  formValues: {
    query: {
      name: likeRegex(name),
      industry,
      status,
    },
    ...rest,
    whiteBrand,
  },
  external,
});

const indexBrandsNotifier = (formValues, external = false, loader = false) =>
  notify({
    type: INDEX_BRANDS,
    formValues,
    external,
    formatter,
    loader,
  });
function* indexBrandsAction({ formValues, external }) {
  yield call(request, {
    type: INDEX_BRANDS,
    method: 'get',
    endpoint: external ? '/brands/data' : '/brands',
    params: formValues,
  });
}

export { indexBrandsAction, indexBrandsNotifier };
