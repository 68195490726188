import { all } from 'redux-saga/effects';
import deviseWatchers from './devise';
import usersWatchers from './users';
import brandsWatchers from './brands';
import parametersWatchers from './parameters';
import helpersWatchers from './helpers';
import listeners from './listeners';
import postActions from './postActions';
import socialNetworkAccounts from './socialNetworkAccounts';
import socialNetworkTraces from './socialNetworkTraces';
import briefsWatchers from './briefs';
import campaignsWatchers from './campaigns';
import indicatorsWatchers from './indicators';
import whiteLabelsWatchers from './whiteLabels';
import currenciesWatchers from './currencies/index';
import exchangesWatchers from './exchanges';

export default function* rootSaga() {
  yield all([
    ...deviseWatchers,
    ...usersWatchers,
    ...briefsWatchers,
    ...campaignsWatchers,
    ...brandsWatchers,
    ...parametersWatchers,
    ...helpersWatchers,
    ...listeners,
    ...postActions,
    ...socialNetworkAccounts,
    ...socialNetworkTraces,
    ...indicatorsWatchers,
    ...whiteLabelsWatchers,
    ...currenciesWatchers,
    ...exchangesWatchers,
  ]);
}
