export const INDEX_WHITE_LABEL = 'INDEX_WHITE_LABEL';
export const INDEX_WHITE_LABEL_SUCCESS = `${INDEX_WHITE_LABEL}_SUCCESS`;
export const INDEX_WHITE_LABEL_FAILURE = `${INDEX_WHITE_LABEL}_FAILURE`;

export const GET_WHITE_LABEL = 'GET_WHITE_LABEL';
export const GET_WHITE_LABEL_SUCCESS = `${GET_WHITE_LABEL}_SUCCESS`;
export const GET_WHITE_LABEL_FAILURE = `${GET_WHITE_LABEL}_FAILURE`;

export const SHOW_WHITE_LABEL = 'SHOW_WHITE_LABEL';
export const SHOW_WHITE_LABEL_SUCCESS = `${SHOW_WHITE_LABEL}_SUCCESS`;
export const SHOW_WHITE_LABEL_FAILURE = `${SHOW_WHITE_LABEL}_FAILURE`;

export const CREATE_WHITE_LABEL = 'CREATE_WHITE_LABEL';
export const CREATE_WHITE_LABEL_SUCCESS = `${CREATE_WHITE_LABEL}_SUCCESS`;
export const CREATE_WHITE_LABEL_FAILURE = `${CREATE_WHITE_LABEL}_FAILURE`;

export const UPDATE_WHITE_LABEL = 'UPDATE_WHITE_LABEL';
export const UPDATE_WHITE_LABEL_SUCCESS = `${UPDATE_WHITE_LABEL}_SUCCESS`;
export const UPDATE_WHITE_LABEL_FAILURE = `${UPDATE_WHITE_LABEL}_FAILURE`;

export const DELETE_WHITE_LABEL = 'DELETE_WHITE_LABEL';
export const DELETE_WHITE_LABEL_SUCCESS = `${DELETE_WHITE_LABEL}_SUCCESS`;
export const DELETE_WHITE_LABEL_FAILURE = `${DELETE_WHITE_LABEL}_FAILURE`;
