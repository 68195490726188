import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { UPDATE_CAMPAIGN_STATUS } from '../constants';
import notify from '../../../helpers/notify';

const updateCampaignStatusNotifier = (campaignId, status, callback) =>
  notify({
    type: UPDATE_CAMPAIGN_STATUS,
    campaignId,
    status,
    callback,
    loader: true,
  });

function* updateCampaignStatusAction({ campaignId, status, callback }) {
  yield call(request, {
    type: UPDATE_CAMPAIGN_STATUS,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/status`,
    params: { status },
    callback,
  });
}

export { updateCampaignStatusAction, updateCampaignStatusNotifier };
