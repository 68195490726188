import React from 'react';
import facebookLogo from '../resources/images/logos/facebook.png';
import instagramLogo from '../resources/images/logos/instagram.png';
import linkedinLogo from '../resources/images/logos/linkedin.png';
import tiktokLogo from '../resources/images/logos/tiktok.png';
import twitterLogo from '../resources/images/logos/twitter.png';
import youtubeLogo from '../resources/images/logos/youtube.png';
import { titleCase } from '../helpers/input/format';
import { CONTENT_KIND, CONTENT_STATUS } from './constUtils';

const FANPAGE = 'FanPage';
const INSTAGRAM = 'Instagram';
const TWITTER = 'Twitter';
const YOUTUBE = 'YouTube';
const LINKEDIN = 'LinkedIn';
const TIKTOK = 'TikTok';

const MEDIA_TYPE_PHOTO = 1;
const MEDIA_TYPE_VIDEO_REEL = 2;
const MEDIA_TYPE_CAROUSEL = 8;

const PHOTO = 'photo';
const VIDEO = 'video';
const STORY = 'story';
const MULTIPLES = 'multiples';
const CARUOSEL = 'carousel';
const LIVE = 'live';
const HIGHLIGHT = 'highlight';
const REEL = 'reel';
const POST = 'post';
const TWEET = 'tweet';
const BONUS = 'Bonus';

const IMAGE_OBJ = 'photo';
const VIDEO_OBJ = 'video';
const CAROUSEL_OBJ = 'carousel';

const ACTIVITIES = {
  PHOTO,
  VIDEO,
  STORY,
  MULTIPLES,
  CARUOSEL,
  LIVE,
  HIGHLIGHT,
  REEL,
  POST,
  TWEET,
};

const INSIGHTS = {
  ENGAGEMENT: 'engagement',
  REACH: 'reach',
  VIEWS: 'views',
  IMPRESSIONS: 'impressions',
};

const MULTIPLE_ACTIVITIES_NAMES = {
  BURST_STORIES: 'burstStories',
};
const MULTIPLE_ACTIVITIES = {
  [INSTAGRAM]: {
    [MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES]: {
      name: MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES,
      mainActivity: ACTIVITIES.STORY,
      quantity: 3,
      estimationRates: [100, 90, 80],
    },
  },
  [TIKTOK]: {},
  [YOUTUBE]: {},
  [TWITTER]: {},
  [LINKEDIN]: {},
  [FANPAGE]: {},
};

const socialNetworkActivities = {
  [INSTAGRAM]: [
    { name: PHOTO, icon: 'photo' },
    { name: VIDEO, icon: 'video' },
    { name: STORY, icon: 'story' },
    {
      name:
        MULTIPLE_ACTIVITIES[INSTAGRAM][MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES]
          .name,
      icon:
        MULTIPLE_ACTIVITIES[INSTAGRAM][MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES]
          .mainActivity,
    },
    { name: CARUOSEL, icon: 'carousel' },
    { name: LIVE, icon: 'live' },
    { name: HIGHLIGHT, icon: 'highlight' },
  ],
  [FANPAGE]: [
    { name: PHOTO, icon: 'photo' },
    { name: VIDEO, icon: 'video' },
    { name: STORY, icon: 'story' },
    { name: POST, icon: 'post' },
    { name: LIVE, icon: 'live' },
  ],
  [TWITTER]: [
    { name: TWEET, icon: 'twitter' },
    { name: VIDEO, icon: 'video' },
    { name: PHOTO, icon: 'photo' },
    { name: LIVE, icon: 'live' },
  ],
  [YOUTUBE]: [{ name: VIDEO, icon: 'video' }],
  [LINKEDIN]: [
    { name: PHOTO, icon: 'photo' },
    { name: VIDEO, icon: 'video' },
    { name: POST, icon: 'post' },
  ],
  [TIKTOK]: [{ name: VIDEO, icon: 'video' }],
};

const socialNetworkActivitiesArr = {
  [INSTAGRAM]: [
    PHOTO,
    VIDEO,
    STORY,
    MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES,
    CARUOSEL,
    LIVE,
    REEL,
    BONUS,
  ],
  [FANPAGE]: [PHOTO, VIDEO, STORY, POST, LIVE],
  [TWITTER]: [TWEET, VIDEO, PHOTO, LIVE],
  [YOUTUBE]: [VIDEO, BONUS],
  [LINKEDIN]: [PHOTO, VIDEO, POST],
  [TIKTOK]: [VIDEO, BONUS],
};

const socialNetworkInsightObj = {
  [INSTAGRAM]: {
    [INSIGHTS.ENGAGEMENT]: [PHOTO, VIDEO, CARUOSEL, REEL],
    [INSIGHTS.REACH]: [
      PHOTO,
      VIDEO,
      CARUOSEL,
      STORY,
      REEL,
      MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES,
      LIVE,
    ],
    [INSIGHTS.VIEWS]: [
      STORY,
      MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES,
      REEL,
      VIDEO,
      LIVE,
    ],
  },
  [FANPAGE]: [IMAGE_OBJ, VIDEO_OBJ, CAROUSEL_OBJ],
  [TIKTOK]: {
    [INSIGHTS.ENGAGEMENT]: [VIDEO],
    [INSIGHTS.VIEWS]: [VIDEO],
    [INSIGHTS.REACH]: [VIDEO],
  },
  [YOUTUBE]: {
    [INSIGHTS.ENGAGEMENT]: [VIDEO],
    [INSIGHTS.VIEWS]: [VIDEO],
  },
};

const socialNetworkIcons = {
  [INSTAGRAM]: 'instagram',
  [FANPAGE]: 'facebook',
  [TWITTER]: 'twitter',
  [YOUTUBE]: 'youtube',
  [LINKEDIN]: 'linkedin',
  [TIKTOK]: 'tiktok',
};

const renderSocialNetworkIcons = (arr) =>
  arr.map((socialNetwork) => (
    <i
      key={socialNetwork}
      className={`icon-${socialNetworkIcons[socialNetwork]}`}
    />
  ));

const getsocialNetworkIcon = (socialNetwork) => (
  <i
    key={socialNetwork}
    className={`icon-${socialNetworkIcons[socialNetwork]}`}
  />
);

const socialNetworkLogos = {
  [INSTAGRAM]: instagramLogo,
  [FANPAGE]: facebookLogo,
  [YOUTUBE]: youtubeLogo,
  [TWITTER]: twitterLogo,
  [TIKTOK]: tiktokLogo,
  [LINKEDIN]: linkedinLogo,
};

const getsocialNetworkLogo = (socialNetwork) => {
  switch (socialNetwork.toUpperCase()) {
    case 'INSTAGRAM':
      return socialNetworkLogos.Instagram;
    case 'YOUTUBE':
      return socialNetworkLogos.YouTube;
    case 'TWITTER':
      return socialNetworkLogos.Twitter;
    case 'TIKTOK':
      return socialNetworkLogos.TikTok;
    case 'LINKEDIN':
      return socialNetworkLogos.LinkedIn;
    case 'FANPAGE':
    default:
      return socialNetworkLogos.FanPage;
  }
};

const socialNetworkNamesList = [
  'facebook',
  [INSTAGRAM],
  [TWITTER],
  [YOUTUBE],
  [LINKEDIN],
  [TIKTOK],
];

const SOCIAL_NETWORK_BASE_URL = {
  [TIKTOK]: 'https://www.tiktok.com',
  [YOUTUBE]: 'https://www.youtube.com',
  [INSTAGRAM]: 'https://www.instagram.com',
};

const getSocialNetworkUsernameURL = (socialNetwork, username) => {
  switch (socialNetwork) {
    case TIKTOK:
      return `${SOCIAL_NETWORK_BASE_URL[socialNetwork]}/@${username}`;
    default:
      return `${SOCIAL_NETWORK_BASE_URL[socialNetwork]}/${username}`;
  }
};
// const formatName = (name) => {
//   if (!name) {
//     return '';
//   }
//   const parts = name.split(' ');
//   return parts.length > 0
//     ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
//     : '';
// };

const getSNAName = (sna) => {
  const name = titleCase(
    sna.snaModifiers && sna.snaModifiers.name ? sna.snaModifiers.name : sna.name
  );
  return name.trim();
};

const splitMultipleActivities = (activities) => {
  const { [ACTIVITIES.MULTIPLES]: multiples, ...rest } = activities;
  return {
    ...rest,
    ...multiples,
  };
};

const socialNetworkOrder = {
  [INSTAGRAM]: 1,
  [TIKTOK]: 2,
  [YOUTUBE]: 3,
  [FANPAGE]: 4,
  [TWITTER]: 5,
  [LINKEDIN]: 6,
};

const socialNetworkActivitiesOrder = {
  [INSTAGRAM]: {
    [PHOTO]: 1,
    [VIDEO]: 2,
    [STORY]: 3,
    [MULTIPLE_ACTIVITIES_NAMES.BURST_STORIES]: 4,
    [CARUOSEL]: 5,
    [LIVE]: 6,
    [REEL]: 7,
    [BONUS]: 8,
  },
  [TIKTOK]: { [VIDEO]: 1, [BONUS]: 2 },
  [YOUTUBE]: { [VIDEO]: 1, [BONUS]: 2 },
  [FANPAGE]: {
    [PHOTO]: 1,
    [VIDEO]: 2,
    [STORY]: 3,
    [POST]: 4,
    [LIVE]: 5,
  },
  [TWITTER]: { [TWEET]: 1, [VIDEO]: 2, [PHOTO]: 3, [LIVE]: 4 },
  [LINKEDIN]: { [PHOTO]: 1, [VIDEO]: 2, [POST]: 3 },
};
const sortSNAS = (obj) => {
  return Object.entries(obj).sort(
    (a, b) => socialNetworkOrder[a[0]] - socialNetworkOrder[b[0]]
  );
};

const countSNAContent = (content) => {
  const types = {
    [CONTENT_STATUS.PUBLISHED]: 0,
    [CONTENT_KIND.BONUS]: 0,
  };
  content.forEach((val) => {
    if (val.kind === CONTENT_KIND.BONUS) {
      types[CONTENT_KIND.BONUS] += 1;
    } else if (val.status === CONTENT_STATUS.PUBLISHED) {
      types[CONTENT_STATUS.PUBLISHED] += 1;
    }
  });
  return types;
};

const isActivityViewsContent = (socialNetwork, activity) => {
  return (
    socialNetworkInsightObj[socialNetwork] &&
    socialNetworkInsightObj[socialNetwork][INSIGHTS.VIEWS].includes(activity)
  );
};

const isActivityEngagementContent = (socialNetwork, activity) => {
  return (
    socialNetworkInsightObj[socialNetwork] &&
    socialNetworkInsightObj[socialNetwork][INSIGHTS.ENGAGEMENT].includes(
      activity
    )
  );
};

const DEFAULT_COMISSION = 40;

export {
  FANPAGE,
  INSTAGRAM,
  TWITTER,
  YOUTUBE,
  LINKEDIN,
  TIKTOK,
  MEDIA_TYPE_PHOTO,
  MEDIA_TYPE_VIDEO_REEL,
  MEDIA_TYPE_CAROUSEL,
  ACTIVITIES,
  MULTIPLE_ACTIVITIES,
  MULTIPLE_ACTIVITIES_NAMES,
  INSIGHTS,
  SOCIAL_NETWORK_BASE_URL,
  DEFAULT_COMISSION,
  socialNetworkActivities,
  socialNetworkLogos,
  socialNetworkIcons,
  socialNetworkNamesList,
  socialNetworkActivitiesOrder,
  getsocialNetworkLogo,
  renderSocialNetworkIcons,
  getsocialNetworkIcon,
  socialNetworkActivitiesArr,
  socialNetworkInsightObj,
  getSNAName,
  sortSNAS,
  getSocialNetworkUsernameURL,
  splitMultipleActivities,
  countSNAContent,
  isActivityViewsContent,
  isActivityEngagementContent,
};
