import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_SNA } from '../constants';
import notify from '../../../helpers/notify';

const showSNANotifier = (SNAId) =>
  notify({
    type: SHOW_SNA,
    SNAId,
  });

function* showSNAAction({ SNAId }) {
  yield call(request, {
    type: SHOW_SNA,
    method: 'get',
    endpoint: `/social-network-accounts/${SNAId}`,
  });
}

export { showSNAAction, showSNANotifier };
