import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { CREATE_BRAND_AGENCY } from '../constants';

const formatter = ({ formValues: { brand, agencies } }) => ({
  formValues: {
    brand,
    ...agencies[0],
  },
});

const createBrandAgencyNotifier = (formValues, callback) =>
  notify({
    type: CREATE_BRAND_AGENCY,
    formValues,
    formatter,
    callback,
  });

function* createBrandAgencyAction({ formValues, callback }) {
  yield call(request, {
    type: CREATE_BRAND_AGENCY,
    method: 'post',
    endpoint: `/brands/${formValues.brand}/agencies`,
    params: formValues,
    callback,
  });
}
export { createBrandAgencyAction, createBrandAgencyNotifier };
