import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { SHOW_CAMPAIGN_PROPOSAL_PREVIEW } from '../constants';

const showProposalPreviewNotifier = (campaignId, proposalId) =>
  notify({
    type: SHOW_CAMPAIGN_PROPOSAL_PREVIEW,
    campaignId,
    proposalId,
  });

function* showProposalPreviewAction({ campaignId, proposalId }) {
  yield call(request, {
    type: SHOW_CAMPAIGN_PROPOSAL_PREVIEW,
    method: 'get',
    endpoint: `campaigns/${campaignId}/proposals/${proposalId}/preview`,
  });
}

export { showProposalPreviewAction, showProposalPreviewNotifier };
