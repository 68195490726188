import { call } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import { ADMIN_BRANDS } from '../../../../routing/paths';
import request from '../../../helpers/request';
import { CREATE_BRAND } from '../constants';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  if (typeof params.banner === 'string') delete params.banner;
  return {
    whiteBrand: localStorage.whiteBrand,
    formValues: objectToFormData(params),
  };
};

const createBrandNotifier = (formValues) =>
  notify({
    type: CREATE_BRAND,
    formValues,
    formatter,
  });

function* createBrandAction({ formValues }) {
  yield call(request, {
    type: CREATE_BRAND,
    method: 'post',
    endpoint: '/brands',
    params: formValues,
    path: ADMIN_BRANDS,
  });
}

export { createBrandAction, createBrandNotifier };
