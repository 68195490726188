import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { RELOAD_SNT, RELOAD_SNT_CB } from '../constants';

const reloadSNTNotifier = (
  formValues,
  socialNetwork,
  username,
  reportType,
  callback
) => {
  return notify({
    type: callback ? RELOAD_SNT_CB : RELOAD_SNT,
    formValues,
    socialNetwork,
    username,
    reportType,
    callback,
  });
};

function* reloadSNTAction({
  formValues,
  socialNetwork,
  username,
  reportType,
  callback,
}) {
  yield call(request, {
    type: callback ? RELOAD_SNT_CB : RELOAD_SNT,
    method: 'put',
    endpoint: `/social-network-traces/social-data/${socialNetwork}/${username}/${reportType}`,
    params: formValues,
    callback,
  });
}

export { reloadSNTAction, reloadSNTNotifier };
