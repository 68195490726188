import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS } from '../constants';

const updateContentProposalStatusNotifier = (
  campaignId,
  contentId,
  proposalId,
  callback
) => {
  return notify({
    type: UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS,
    campaignId,
    contentId,
    proposalId,
    callback,
  });
};

function* updateContentProposalStatusAction({
  campaignId,
  contentId,
  proposalId,
  callback,
}) {
  yield call(request, {
    type: UPDATE_CONTENT_ACTIVE_PROPOSAL_STATUS,
    method: 'put',
    endpoint: `campaigns/${campaignId}/contents/${contentId}/proposals/${proposalId}/status`,
    params: { status: 'Approved' },
    callback,
  });
}

export {
  updateContentProposalStatusAction,
  updateContentProposalStatusNotifier,
};
