import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { DELETE_MANUAL_CONTENT } from '../constants';
import notify from '../../../helpers/notify';

const formatter = ({ campaignId, contentId }) => ({
  campaignId,
  contentId,
});

const deleteManualContentNotifier = (campaignId, contentId, callback) =>
  notify({
    type: DELETE_MANUAL_CONTENT,
    formatter,
    campaignId,
    contentId,
    callback,
  });

function* deleteManualContentAction({ campaignId, contentId, callback }) {
  yield call(request, {
    type: DELETE_MANUAL_CONTENT,
    method: 'delete',
    endpoint: `/campaigns/${campaignId}/contents/${contentId}/published`,
    callback,
  });
}

export { deleteManualContentAction, deleteManualContentNotifier };
