import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_SNT_LIST } from '../constants';
import notify from '../../../helpers/notify';

const showSNTListNotifier = (SNTId, callback) =>
  notify({
    type: SHOW_SNT_LIST,
    SNTId,
    callback,
  });
function* showSNTListAction({ SNTId, callback }) {
  yield call(request, {
    type: SHOW_SNT_LIST,
    method: 'get',
    endpoint: `/social-network-traces/${SNTId}`,
    callback,
  });
}

export { showSNTListAction, showSNTListNotifier };
