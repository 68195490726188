export const MALE_GENDER = 'Male';
export const FEMALE_GENDER = 'Female';

export const genderLabels = [
  { label: MALE_GENDER, value: MALE_GENDER },
  { label: FEMALE_GENDER, value: FEMALE_GENDER },
];

export const genderInsightLabels = [
  { label: MALE_GENDER, value: 'm' },
  { label: FEMALE_GENDER, value: 'f' },
];

export const campaignDurations = [
  {
    label: 'one_week',
    value: 'one_week',
  },
  {
    label: 'two_weeks',
    value: 'two_weeks',
  },
  {
    label: 'one_month',
    value: 'one_month',
  },
  {
    label: 'three_months',
    value: 'three_months',
  },
  {
    label: 'other',
    value: 'other',
  },
];

export const rightsOfUse = [
  {
    label: 'digital_only',
    value: 'digital_only',
  },
  {
    label: 'mass_media_only',
    value: 'mass_media_only',
  },
  {
    label: 'all_of_the_above',
    value: 'all_of_the_above',
  },
];

export const CONTENT_STATUS = {
  PUBLISHED: 'Published',
  PENDING: 'Pending',
  APPROVED: 'Approved',
};

export const CONTENT_KIND = {
  BONUS: 'Bonus',
  PENDING: 'Pending',
};

export const PROPOSAL_STATUS = {
  INACTIVE: 'Inactive',
  PROPOSING: 'Proposing',
  APPROVED: 'Approved',
  ACTIVE: 'Active',
};

export const BRIEF_STATUS = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
};

export const CAMPAIGN_STATUS = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  FINISHED: 'Finished',
};

export const DIACRITIC_CHARS = {
  a: String.fromCharCode(
    65,
    97,
    192,
    224,
    193,
    225,
    194,
    226,
    195,
    227,
    196,
    228,
    229,
    258,
    259
  ),
  e: String.fromCharCode(69, 101, 200, 232, 201, 233, 202, 234, 203, 235),
  i: String.fromCharCode(73, 105, 204, 236, 205, 237, 206, 238, 207, 239),
  o: String.fromCharCode(
    79,
    111,
    210,
    242,
    211,
    243,
    212,
    244,
    213,
    245,
    214,
    246
  ),
  n: String.fromCharCode(78, 110, 209, 241),
  u: String.fromCharCode(85, 117, 217, 249, 218, 250, 219, 251, 220, 252),
  c: String.fromCharCode(67, 99, 199, 231),
  y: String.fromCharCode(89, 121, 221, 253, 159, 255),
};

export const quantityData = [
  { label: 10, value: 1 },
  { label: 20, value: 2 },
  { label: 30, value: 3 },
];

export const DECIMAL_PERCENTAGE = 2;

export const CAMPAIGN_TOGGLES = {
  TOTAL_PRICES: 'totalPrices',
  SUBTOTAL_PRICES: 'subtotalPrices',
  ACTIVITY_PRICES: 'activityPrices',
  CPE: 'cpe',
  ENGAGEMENT: 'engagement',
  CPV: 'cpv',
  VIEWS: 'views',
  CITIES: 'cities',
  CATEGORIES: 'categories',
  CALENDAR_CONTENTS: 'calendarContents',
  PRODUCTION_COST: 'productionCost',
  ENGAGEMENT_RATE_PROFILE: 'engagementRateProfile',
  ENGAGEMENT_RATE_CONTENT: 'engagementRateContent',
  ENGAGEMENT_RATE_REACH: 'engagementRateReach',
};
