import { takeLatest } from 'redux-saga/effects';
import { createBriefAction } from './actions/createBrief';
import { updateBriefAction } from './actions/updateBrief';
import { indexBriefsAction } from './actions/indexBriefs';
import { showBriefAction } from './actions/showBrief';
import { updateBriefOpsUsersAction } from './actions/updateBriefOpsUsers';
import { updateBriefStatusAction } from './actions/updateBriefStatus';
import {
  CREATE_BRIEF,
  GET_BRIEF,
  UPDATE_BRIEF,
  INDEX_BRIEFS,
  SHOW_BRIEF,
  UPDATE_BRIEF_OPS_USERS,
  UPDATE_BRIEF_STATUS,
} from './constants';

export default [
  takeLatest(CREATE_BRIEF, createBriefAction),
  takeLatest(UPDATE_BRIEF, updateBriefAction),
  takeLatest(INDEX_BRIEFS, indexBriefsAction),
  takeLatest(GET_BRIEF, showBriefAction),
  takeLatest(SHOW_BRIEF, showBriefAction),
  takeLatest(UPDATE_BRIEF_STATUS, updateBriefStatusAction),
  takeLatest(UPDATE_BRIEF_OPS_USERS, updateBriefOpsUsersAction),
];
