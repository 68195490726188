import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_CAMPAIGN_CONTENT } from '../constants';
import notify from '../../../helpers/notify';

const showCampaignContentNotifier = (campaignId, snaId) =>
  notify({
    type: SHOW_CAMPAIGN_CONTENT,
    campaignId,
    snaId,
  });

function* showCampaignContentAction({ campaignId, snaId }) {
  yield call(request, {
    type: SHOW_CAMPAIGN_CONTENT,
    method: 'get',
    endpoint: `/campaigns/${campaignId}/snas/${snaId}/contents`,
  });
}

export { showCampaignContentNotifier, showCampaignContentAction };
