import { UPDATE_PROPOSAL_TOGGLES } from '../../movements/constants';

export const INITIAL_STATE = [];

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_PROPOSAL_TOGGLES: {
      return [...payload];
    }
    default:
      return state;
  }
};
