import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { UPDATE_CURRENCY } from '../constants';
import {
  ADMIN_UPDATE_CURRENCY,
  ADMIN_CURRENCIES,
} from '../../../../routing/paths';
import { formatDate } from '../../../../utils/helperFunctions';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  params.updateAt = formatDate(params.updateAt, 'YYYY-MM-DD', 'MMM YYYY');
  params.status = formValues.status ? 1 : 0;

  return {
    formValues: params,
  };
};
const updateCurrencyNotifier = (formValues, loader = false) => {
  return notify({
    type: UPDATE_CURRENCY,
    formValues,
    loader,
    formatter,
  });
};

function* updateCurrencyAction({ formValues }) {
  yield call(request, {
    type: UPDATE_CURRENCY,
    method: 'put',
    endpoint: ADMIN_UPDATE_CURRENCY,
    params: formValues,
    path: ADMIN_CURRENCIES,
  });
}

export { updateCurrencyNotifier, updateCurrencyAction };
