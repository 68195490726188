import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import { UPDATE_BRIEF } from '../constants';
import { formatDate, t } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';

const formatter = ({ formValues, briefId }) => {
  const params = cloneDeep(formValues);
  params.startDate = formatDate(params.startDate, 'YYYY-MM-DD', 'MMM YYYY');
  params.budget = { min: 0, max: Number(params.budget) };

  if (params.advisor === t('brief.noAdvisor')) delete params.advisor;

  return {
    formValues: params,
    briefId,
  };
};

const updateBriefNotifier = (formValues, briefId, callback) =>
  notify({
    type: UPDATE_BRIEF,
    formValues,
    formatter,
    briefId,
    callback,
  });

function* updateBriefAction({ formValues, briefId, callback }) {
  yield call(request, {
    type: UPDATE_BRIEF,
    method: 'put',
    endpoint: `/briefs/${briefId}`,
    params: formValues,
    callback,
  });
}

export { updateBriefAction, updateBriefNotifier };
