import { cloneDeep } from 'lodash';
import moment from 'moment';
import { call } from 'redux-saga/effects';
import {
  ADMIN_CREATE_CURRENCY,
  ADMIN_CURRENCIES,
} from '../../../../routing/paths';
import { CREATE_CURRENCY } from '../constants';
import { formatDate } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  params.createdAt = formatDate(moment(), 'YYYY-MM-DD', 'MMM YYYY');
  params.updatedAt = formatDate(moment(), 'YYYY-MM-DD', 'MMM YYYY');
  return {
    formValues: params,
  };
};

const createCurrencyNotifier = (formValues, callback) =>
  notify({
    type: CREATE_CURRENCY,
    formValues,
    formatter,
    callback,
  });

function* createCurrencyAction({ formValues, callback }) {
  yield call(request, {
    type: CREATE_CURRENCY,
    method: 'post',
    endpoint: ADMIN_CREATE_CURRENCY,
    params: formValues,
    callback,
    path: ADMIN_CURRENCIES,
  });
}

export { createCurrencyNotifier, createCurrencyAction };
