export const LINK_FB_ACCOUNT = 'LINK_FB_ACCOUNT';
export const LINK_FB_ACCOUNT_SUCCESS = `${LINK_FB_ACCOUNT}_SUCCESS`;
export const LINK_FB_ACCOUNT_FAILURE = `${LINK_FB_ACCOUNT}_FAILURE`;

export const INDEX_SNA = 'INDEX_SNA';
export const INDEX_SNA_FULL = `${INDEX_SNA}_FULL`;
export const INDEX_SNA_SUCCESS = `${INDEX_SNA}_SUCCESS`;
export const INDEX_SNA_FAILURE = `${INDEX_SNA}_FAILURE`;

export const SHOW_SNA = 'SHOW_SNA';
export const SHOW_SNA_SUCCESS = `${SHOW_SNA}_SUCCESS`;
export const SHOW_SNA_FAILURE = `${SHOW_SNA}_FAILURE`;
