import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_SNT_FULL } from '../constants';
import objectToFormData from '../../../helpers/objectToFormData';
import { INSTAGRAM } from '../../../../utils/socialNetworkUtils';

const formatter = ({ formValues: { ...rest }, sntUsername, socialNetwork }) => {
  let values = { ...rest };
  if (typeof values.avatar === 'string') delete values.avatar;

  values = objectToFormData(values);

  return { formValues: values, sntUsername, socialNetwork };
};

const updateSNTFullNotifier = (
  formValues,
  sntUsername,
  socialNetwork,
  callback
) =>
  notify({
    type: UPDATE_SNT_FULL,
    formValues,
    sntUsername,
    socialNetwork,
    callback,
    formatter,
  });
function* updateSNTFullAction({
  formValues,
  sntUsername,
  socialNetwork,
  callback,
}) {
  yield call(request, {
    type: UPDATE_SNT_FULL,
    method: 'put',
    endpoint:
      socialNetwork === INSTAGRAM
        ? `/social-network-traces/social-data/Instagram/${sntUsername}/all`
        : `/social-network-traces/social-data/${socialNetwork}/${sntUsername}/allById`,
    params: formValues,
    callback,
  });
}

export { updateSNTFullAction, updateSNTFullNotifier };
