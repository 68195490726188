import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { LOG_IN } from '../constants';
import notify from '../../../helpers/notify';
import { getHostDomain } from '../../../../utils/helperFunctions';

const logInNotifier = (formValues) => {
  const domain = getHostDomain();
  const { whiteBrand = '001' } =
    JSON.parse(localStorage.getItem(`conf-${domain}`)) || {};
  return notify({
    type: LOG_IN,
    formValues: { ...formValues, whiteBrand },
  });
};

function* logInAction({ formValues, callback }) {
  yield call(request, {
    type: LOG_IN,
    method: 'post',
    endpoint: '/users/log-in',
    params: formValues,
    callback,
  });
}

export { logInAction, logInNotifier };
