import { t } from '../helperFunctions';

const parameterTableFilters = [
  {
    name: 'title',
    title: t('parameters.parametersInfo.title'),
    icon: 'bars',
  },
];

const parametersFilters = {
  query: {
    title: undefined,
  },
  pagination: {
    page: 1,
    limit: 10,
  },
};

export { parameterTableFilters, parametersFilters };
