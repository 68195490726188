import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_CAMPAIGN_OPS_USERS } from '../constants';

const updateCampaignOpsUsersNotifier = (formValues, campaignId, callback) =>
  notify({
    type: UPDATE_CAMPAIGN_OPS_USERS,
    callback,
    formValues,
    campaignId,
  });

function* updateCampaignOpsUsersAction({ formValues, campaignId, callback }) {
  yield call(request, {
    type: UPDATE_CAMPAIGN_OPS_USERS,
    callback,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/operationUsers`,
    params: formValues,
  });
}

export { updateCampaignOpsUsersAction, updateCampaignOpsUsersNotifier };
