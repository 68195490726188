import { call } from 'redux-saga/effects';
import { likeRegex, validFilter } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { INDEX_BRIEFS } from '../constants';

const formatter = ({
  formValues: {
    query: { name, brand, status, country, startDate, numBrief, whiteBrand },
    ...rest
  },
}) => {
  return {
    formValues: {
      query: {
        name: likeRegex(name),
        brand: likeRegex(brand),
        status,
        country,
        startDate,
        numBrief: validFilter(numBrief),
      },
      ...rest,
      whiteBrand,
    },
  };
};

const indexBriefsNotifier = (formValues, loader = false) =>
  notify({
    type: INDEX_BRIEFS,
    formValues,
    formatter,
    loader,
  });

function* indexBriefsAction({ formValues }) {
  yield call(request, {
    type: INDEX_BRIEFS,
    method: 'get',
    endpoint: '/briefs',
    params: formValues,
  });
}

export { indexBriefsAction, indexBriefsNotifier };
