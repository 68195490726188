import { Button, Col, Form, Row } from 'antd';
import i18n from 'i18next';
import { func, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { t } from '../../../utils/helperFunctions';
import { EmailField } from '../../../helpers/input/ContactInputs';
import { CreatePasswordField } from '../../../helpers/input/inputCreator';
import { required } from '../../../helpers/input/validations';
import { confirmEmailNotifier } from '../../../redux/sagas/devise/actions/confirmEmail';
import { logInNotifier } from '../../../redux/sagas/devise/actions/logIn';
import './logIn.scss';

export const LogIn = ({
  logIn,
  handleSubmit,
  search,
  confirmEmail,
  history,
}) => {
  useEffect(() => {
    if (search.includes('confirmationToken')) {
      confirmEmail(search);
    }
  }, [search]);

  const openRecoverPassword = () => {
    history.push('/forgot-password');
  };
  return (
    <div className="login">
      <h1 className="title-dss"> &nbsp;</h1>
      <Col xs={24} className="loginR">
        <Row type="flex" justify="center">
          <Col xs={16} md={16}>
            <div>
              <h1 className="title-login">{t('auth.logIn')}</h1>
              <div className="line-separator-container">
                <div className="line-section" />
                <div className="line-separator" />
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={12} md={8} id="col-form">
            <Form
              colon={false}
              onSubmit={handleSubmit(logIn)}
              className="auth-form"
            >
              <EmailField hasLabel={false} isRequired name="email" />
              <Field
                name="password"
                component={CreatePasswordField}
                placeholder={i18n.t('form.credentialsInfo.password')}
                validate={[required]}
              />
              <Row className="help-links">
                <Button
                  type="link text-left"
                  block
                  onClick={openRecoverPassword}
                  className="forgot-password-button"
                >
                  {i18n.t('auth.forgotPassword')}
                </Button>
              </Row>
              <Row
                type="flex"
                justify="center"
                className="mb-25 send-container"
              >
                <Col xs={24} md={20} lg={16} id="col-login">
                  <Button type="primary" block htmlType="submit" id="btn-login">
                    {i18n.t('auth.logIn')}
                  </Button>
                </Col>
              </Row>
            </Form>
            <h2 className="version">Versión 2.0.5</h2>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

LogIn.propTypes = {
  logIn: func.isRequired,
  confirmEmail: func.isRequired,
  search: string.isRequired,
  handleSubmit: func.isRequired,
  history: object.isRequired,
};

const mapDispatchToProps = {
  logIn: logInNotifier,
  confirmEmail: confirmEmailNotifier,
};

const mapStateToProps = (state) => ({
  search: state.router.location.search,
});

export const SimpleLogIn = LogIn;

const ShowTheLocationWithRouter = withRouter(LogIn);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'logInForm',
  })(ShowTheLocationWithRouter)
);
