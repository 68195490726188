import { call } from 'redux-saga/effects';
import { likeRegex } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { INDEX_WHITE_LABEL } from '../constants';

const formatter = ({
  formValues: {
    query: { name, theme, status },
    ...rest
  },
}) => ({
  formValues: {
    query: {
      name: likeRegex(name),
      theme,
      status,
    },
    ...rest,
  },
});

const indexWhiteLabelNotifier = (formValues, loader = false) => {
  return notify({
    type: INDEX_WHITE_LABEL,
    formValues,
    formatter,
    loader,
  });
};

function* indexWhiteLabelAction({ formValues }) {
  yield call(request, {
    type: INDEX_WHITE_LABEL,
    method: 'get',
    endpoint: '/whitelabel',
    params: formValues,
  });
}

export { indexWhiteLabelAction, indexWhiteLabelNotifier };
