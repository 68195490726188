import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { GET_BRIEF, SHOW_BRIEF } from '../constants';
import notify from '../../../helpers/notify';

const showBriefNotifier = (briefId, external = false) =>
  notify({
    type: external ? SHOW_BRIEF : GET_BRIEF,
    briefId,
    external,
  });

function* showBriefAction({ briefId, external }) {
  yield call(request, {
    type: external ? SHOW_BRIEF : GET_BRIEF,
    method: 'get',
    endpoint: `/briefs/${briefId}`,
  });
}

export { showBriefAction, showBriefNotifier };
