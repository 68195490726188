export const INDEX_SNT = 'INDEX_SNT';
export const INDEX_SNT_SUCCESS = `${INDEX_SNT}_SUCCESS`;
export const INDEX_SNT_FAILURE = `${INDEX_SNT}_FAILURE`;

export const SHOW_SNT = 'SHOW_SNT';
export const SHOW_SNT_LIST = 'SHOW_SNT_LIST';
export const SHOW_SNT_SUCCESS = `${SHOW_SNT}_SUCCESS`;
export const SHOW_SNT_FAILURE = `${SHOW_SNT}_FAILURE`;

export const SHOW_INFO_SNT = 'SHOW_INFO_SNT';
export const SHOW_INFO_SNT_SUCCESS = `${SHOW_INFO_SNT}_SUCCESS`;
export const SHOW_INFO_SNT_FAILURE = `${SHOW_INFO_SNT}_FAILURE`;

export const CREATE_SNT = 'CREATE_SNT';
export const CREATE_SNT_SUCCESS = `${CREATE_SNT}_SUCCESS`;
export const CREATE_SNT_FAILURE = `${CREATE_SNT}_FAILURE`;

export const UPDATE_SNT = 'UPDATE_SNT';
export const UPDATE_SNT_SUCCESS = `${UPDATE_SNT}_SUCCESS`;
export const UPDATE_SNT_FAILURE = `${UPDATE_SNT}_FAILURE`;

export const RELOAD_SNT = 'RELOAD_SNT';
export const RELOAD_SNT_SUCCESS = `${RELOAD_SNT}_SUCCESS`;
export const RELOAD_SNT_FAILURE = `${RELOAD_SNT}_FAILURE`;

export const RELOAD_SNT_CB = 'RELOAD_SNT_CB';
export const RELOAD_SNT_CB_SUCCESS = `${RELOAD_SNT_CB}_SUCCESS`;
export const RELOAD_SNT_CB_FAILURE = `${RELOAD_SNT_CB}_FAILURE`;

export const UPDATE_SNT_BASIC = 'UPDATE_SNT_BASIC';
export const UPDATE_SNT_BASIC_SUCCESS = `${UPDATE_SNT_BASIC}_SUCCESS`;
export const UPDATE_SNT_BASIC_FAILURE = `${UPDATE_SNT_BASIC}_FAILURE`;

export const UPDATE_SNT_FULL = 'UPDATE_SNT_FULL';
export const UPDATE_SNT_FULL_SUCCESS = `${UPDATE_SNT_FULL}_SUCCESS`;
export const UPDATE_SNT_FULL_FAILURE = `${UPDATE_SNT_FULL}_FAILURE`;

export const UPDATE_SNT_FIELD = 'UPDATE_SNT_FIELD';
export const UPDATE_SNT_FIELD_SUCCESS = `${UPDATE_SNT_FIELD}_SUCCESS`;
export const UPDATE_SNT_FIELD_FAILURE = `${UPDATE_SNT_FIELD}_FAILURE`;
