import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_CAMPAIGN_COLLABORATORS } from '../constants';

const updateCampaignCollaboratorsNotifier = (
  formValues,
  campaignId,
  callback
) =>
  notify({
    type: UPDATE_CAMPAIGN_COLLABORATORS,
    callback,
    formValues,
    campaignId,
  });

function* updateCampaignCollaboratorsAction({
  formValues,
  campaignId,
  callback,
}) {
  yield call(request, {
    type: UPDATE_CAMPAIGN_COLLABORATORS,
    callback,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/collaborators`,
    params: formValues,
  });
}

export {
  updateCampaignCollaboratorsAction,
  updateCampaignCollaboratorsNotifier,
};
