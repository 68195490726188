import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { SEND_PROPOSAL_REVIEW } from '../constants';

const sendProposalReviewNotifier = (campaignId, proposalId) =>
  notify({
    type: SEND_PROPOSAL_REVIEW,
    campaignId,
    proposalId,
  });

function* sendProposalReviewAction({ campaignId, proposalId }) {
  yield call(request, {
    type: SEND_PROPOSAL_REVIEW,
    method: 'post',
    endpoint: `campaigns/${campaignId}/proposals/${proposalId}/review`,
  });
}

export { sendProposalReviewAction, sendProposalReviewNotifier };
