import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { INDEX_SNA } from '../constants';
import { likeRegex } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';

const formatter = ({
  formValues: {
    query: {
      name,
      username,
      categories,
      followers: followerRanges,
      ...restQuery
    },
    ...rest
  },
  searchManual: manual,
}) => {
  return {
    formValues: {
      query: {
        name: likeRegex(name),
        username: likeRegex(username),
        followers: followerRanges,
        categories: {
          $in: categories,
        },
        manual,
        ...restQuery,
      },
      ...rest,
    },
  };
};

const indexSNAsNotifier = (formValues, searchManual) =>
  notify({
    type: INDEX_SNA,
    formValues,
    formatter,
    searchManual,
    loader: true,
  });

function* indexSNAsAction({ formValues }) {
  yield call(request, {
    type: INDEX_SNA,
    method: 'get',
    endpoint: '/social-network-accounts/',
    params: formValues,
  });
}

export { indexSNAsAction, indexSNAsNotifier };
