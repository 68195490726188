import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_BRAND_AGENCY } from '../constants';

const formatter = ({ formValues: { brand, agencies } }) => ({
  formValues: {
    brand,
    ...agencies[0],
  },
});

const updateBrandAgencyNotifier = (formValues, callback) =>
  notify({
    type: UPDATE_BRAND_AGENCY,
    formValues,
    formatter,
    callback,
  });

function* updateBrandAgencyAction({ formValues, callback }) {
  yield call(request, {
    type: UPDATE_BRAND_AGENCY,
    method: 'put',
    endpoint: `/brands/${formValues.brand}/agencies/${formValues['_id']}`,
    params: formValues,
    callback,
  });
}
export { updateBrandAgencyAction, updateBrandAgencyNotifier };
