import { call } from 'redux-saga/effects';
import { ADMIN_WHITE_LABEL } from '../../../../routing/paths';
import request from '../../../helpers/request';
import { CREATE_WHITE_LABEL } from '../constants';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';

const formatter = ({ formValues }) => ({
  formValues: objectToFormData({ ...formValues, birthDate: '1990-01-01' }),
});

const createWhiteLabelNotifier = (formValues) => {
  return notify({
    type: CREATE_WHITE_LABEL,
    formValues,
    formatter,
  });
};

function* createWhiteLabelAction({ formValues }) {
  yield call(request, {
    type: CREATE_WHITE_LABEL,
    method: 'post',
    endpoint: '/whitelabel',
    params: formValues,
    path: ADMIN_WHITE_LABEL,
  });
}

export { createWhiteLabelAction, createWhiteLabelNotifier };
