import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_CAMPAIGN_PROPOSAL } from '../constants';
import notify from '../../../helpers/notify';

const showCampaignProposalNotifier = (
  campaignId,
  proposalId,
  type = SHOW_CAMPAIGN_PROPOSAL
) =>
  notify({
    type,
    campaignId,
    proposalId,
  });

function* showCampaignProposalAction({ type, campaignId, proposalId }) {
  yield call(request, {
    type,
    method: 'get',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}`,
  });
}

export { showCampaignProposalAction, showCampaignProposalNotifier };
