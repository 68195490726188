import { call } from 'redux-saga/effects';
import { ADMIN_WHITE_LABEL } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';
import request from '../../../helpers/request';
import { UPDATE_WHITE_LABEL } from '../constants';

const formatter = ({ formValues: { ...rest } }) => {
  let values = { ...rest };
  delete values['_id'];
  values = objectToFormData(values);
  return { formValues: values, whiteLabelId: rest['_id'] };
};

const updateWhiteLabelNotifier = (formValues) =>
  notify({
    type: UPDATE_WHITE_LABEL,
    formValues,
    formatter,
  });

function* updateWhiteLabelAction({ formValues, whiteLabelId }) {
  yield call(request, {
    type: UPDATE_WHITE_LABEL,
    method: 'put',
    endpoint: `/whitelabel/${whiteLabelId}`,
    params: formValues,
    path: ADMIN_WHITE_LABEL,
  });
}

export { updateWhiteLabelAction, updateWhiteLabelNotifier };
