import { call } from 'redux-saga/effects';
import { parseQueryString } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { SHOW_CAMPAIGNS_GLOBAL_INDICATORS } from '../constants';

const getCampaignGlobalIndicatorNotifier = (params, loader = false) =>
  notify({
    type: SHOW_CAMPAIGNS_GLOBAL_INDICATORS,
    params,
    loader,
  });

function* getCampaignGlobalIndicatorAction({ params }) {
  const strQueryParams = parseQueryString(params);
  yield call(request, {
    type: SHOW_CAMPAIGNS_GLOBAL_INDICATORS,
    method: 'get',
    endpoint: `/indicators/getCampaignsGlobal${strQueryParams}`,
  });
}

export { getCampaignGlobalIndicatorAction, getCampaignGlobalIndicatorNotifier };
