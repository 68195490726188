import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { UPDATE_MANUAL_CONTENT } from '../constants';
import notify from '../../../helpers/notify';
import { formatDate } from '../../../../utils/helperFunctions';

const formatter = ({ formValues, campaignId, contentId }) => {
  const params = cloneDeep(formValues);
  if (params.publishedDate) {
    params.publishedDate = formatDate(
      params.publishedDate,
      'YYYY-MM-DD',
      'DD/MM/YYYY'
    );
  }
  return {
    formValues: params,
    campaignId,
    contentId,
  };
};

const updateManualContentNotifier = (
  formValues,
  campaignId,
  contentId,
  callback
) =>
  notify({
    type: UPDATE_MANUAL_CONTENT,
    formValues,
    formatter,
    campaignId,
    contentId,
    callback,
  });

function* updateManualContentAction({
  formValues,
  campaignId,
  contentId,
  callback,
}) {
  yield call(request, {
    type: UPDATE_MANUAL_CONTENT,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/contents/${contentId}/published`,
    params: formValues,
    callback,
  });
}

export { updateManualContentAction, updateManualContentNotifier };
