import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import {
  CREATE_BRAND_CONTACT_SUCCESS,
  CREATE_BRAND_CONTACT,
} from '../constants';
import notify from '../../../helpers/notify';

const formatter = ({ formValues: { brand, users } }) => ({
  formValues: {
    brand,
    whiteBrand: localStorage.whiteBrand,
    ...users[0],
  },
});

const createBrandContactNotifier = (formValues, callback) =>
  notify({
    type: CREATE_BRAND_CONTACT,
    formValues,
    callback,
    formatter,
  });

function* createBrandContactAction({ formValues, callback }) {
  yield call(request, {
    type: CREATE_BRAND_CONTACT_SUCCESS,
    method: 'post',
    endpoint: '/users/brand-users',
    params: formValues,
    callback,
  });
}

export { createBrandContactAction, createBrandContactNotifier };
