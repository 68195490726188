import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_INFO_SNT } from '../constants';
import notify from '../../../helpers/notify';
import { parseQueryString } from '../../../../utils/helperFunctions';

const showInfoSNTNotifier = (
  socialNetwork,
  username,
  countryCode,
  followers,
  loaderText
) =>
  notify({
    type: SHOW_INFO_SNT,
    socialNetwork,
    username,
    countryCode,
    followers,
    loaderText,
  });
function* showInfoSNTAction({
  socialNetwork,
  username,
  countryCode,
  followers,
}) {
  const params = { countryCode, followers };
  const strQueryParams = parseQueryString(params);
  yield call(request, {
    type: SHOW_INFO_SNT,
    method: 'get',
    endpoint: `/social-network-traces/social-data/${socialNetwork}/${username}${strQueryParams}`,
  });
}

export { showInfoSNTAction, showInfoSNTNotifier };
