import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { DELETE_USER } from '../constants';
import { ADMIN_USERS } from '../../../../routing/paths';

const deleteUserNotifier = (userId, callback) =>
  notify({
    type: DELETE_USER,
    userId,
    callback,
  });

function* deleteUserAction({ userId, callback }) {
  yield call(request, {
    type: DELETE_USER,
    method: 'delete',
    endpoint: `/users/${userId}`,
    path: ADMIN_USERS,
    callback,
  });
}

export { deleteUserAction, deleteUserNotifier };
