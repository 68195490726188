import { call } from 'redux-saga/effects';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { DELETE_WHITE_LABEL } from '../constants';

const deleteWhiteLabelNotifier = (whiteLabelId, callback) =>
  notify({
    type: DELETE_WHITE_LABEL,
    whiteLabelId,
    callback,
  });

function* deleteWhiteLabelAction({ whiteLabelId, callback }) {
  yield call(request, {
    type: DELETE_WHITE_LABEL,
    method: 'delete',
    endpoint: `/whitelabel/${whiteLabelId}`,
    callback,
  });
}
export { deleteWhiteLabelAction, deleteWhiteLabelNotifier };
