import { call, put } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_CAMPAIGN } from '../constants';
import notify from '../../../helpers/notify';
import triggerAction from '../../../helpers/triggerAction';

const showCampaignNotifier = (campaignId) =>
  notify({
    type: SHOW_CAMPAIGN,
    campaignId,
  });

function* onSuccess(type, data) {
  const processedData = { ...data };
  let hasPublishedContent = false;
  if (
    processedData.campaignContext &&
    processedData.campaignContext.socialNetworkAccounts
  ) {
    hasPublishedContent =
      processedData.campaignContext.socialNetworkAccounts.filter((sn) =>
        sn.content.some((content) => content.status === 'Published')
      ).length > 0;
  }
  yield put(
    triggerAction(`${type}_SUCCESS`, { ...processedData, hasPublishedContent })
  );
}
function* showCampaignAction({ campaignId }) {
  yield call(request, {
    type: SHOW_CAMPAIGN,
    method: 'get',
    endpoint: `/campaigns/${campaignId}`,
    onSuccess,
  });
}

export { showCampaignAction, showCampaignNotifier };
