import { DIACRITIC_CHARS } from '../../utils/constUtils';

// Format
const numOnly = (value) => value && `${value}`.replace(/[^0-9.]/g, '');

const numOnlyToDots = (value) => value && `${value}`.replace(/[^0-9]/g, '');

const numFormat = (value, maximumFractionDigits = 2) =>
  value
    ? parseFloat(numOnly(value)).toLocaleString('en', {
        maximumFractionDigits,
      })
    : 0;

const numIntegerFormat = (value) => {
  return value
    ? parseInt(value, 10).toLocaleString('en', {
        maximumFractionDigits: 0,
      })
    : 0;
};

const numRoundFormatLocale = (value) =>
  value
    ? Math.floor(value).toLocaleString('en', { maximumFractionDigits: 2 })
    : 0;

const numFormatLocale = (value, maximumFractionDigits = 3) => {
  return value ? value.toLocaleString('en', { maximumFractionDigits }) : 0;
};

const numFormatWithLanguageCode = (value, languageCode, decimal = 0) => {
  if (!value || !languageCode) return 0;
  if (typeof value === 'number') {
    return value.toLocaleString(languageCode, {
      maximumFractionDigits: decimal,
    });
  }
  return Number(value).toLocaleString(languageCode, {
    maximumFractionDigits: decimal,
  });
};

/**
 * Format country and cities
 * @param {*} value
 * @param {*} languageCode
 * @param {*} decimal
 * @returns
 */
const numFormatOriginal = (value, languageCode, decimal = 0) => {
  return value.toLocaleString(languageCode, {
    maximumFractionDigits: decimal,
  });
};
const numFormatWithoutDefault = (value) =>
  value && numOnly(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
const currencyFormat = (value, iso, fixed = 0) =>
  `$${
    !value ? 0 : numFormat(parseFloat(value).toFixed(value > 0 ? fixed : 0))
  }${iso ? ` ${iso}` : ''}`;

const currencyFormatCpeCpv = (value, iso) => {
  return `$${!value ? 0 : numFormat(parseFloat(value).toFixed(2), 2)}${
    iso ? ` ${iso}` : ''
  }`;
};

const percentWithoutDefaultPercent = (value) =>
  value ? (value * 100).toFixed(0) : 0;

const percentNormalizer = (value) => {
  return value ? Number(numFormat(value)) / 100 : undefined;
};

const percent = (value, isAlreadyPercent, decimal = 0) =>
  value
    ? `${(value * (isAlreadyPercent ? 1 : 100)).toFixed(decimal)} %`
    : `0 %`;

const plainPercent = (value) => (value ? `${value} %` : `0 %`);

const maskNumber = (value) => {
  if (value === null || value === '' || value === undefined) {
    return '';
  }
  let v = value.toString().replace(/[^\d.]/g, '');
  v = v.slice(0, v.indexOf('.') >= 0 ? v.indexOf('.') + 3 : undefined);
  return v;
};

// Parse
const parser = (value) => value.replace(/^[A-Z]+$/i, '');
const capitalize = (value) =>
  value ? value.charAt(0).toUpperCase() + value.slice(1) : value;

// Date
const dateFormat = (value) => {
  if (!value) {
    return value;
  }
  return value
    .substr(0, 10)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1/$2/$3')
    .substr(0, 10);
};

const budgetFormat = (input) => {
  if (!input) return input;

  let onlyNums = numOnly(input);
  const partsArray = onlyNums.split('.');
  if (partsArray.length > 1) {
    const parsed = parseFloat(onlyNums || 0).toFixed(2);
    onlyNums = parsed;
  }
  const amount = `${onlyNums}`
    .replace(/\$/g, '')
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `$${amount}`;
};

// Date
const amountFormat = (input) => {
  if (!input) return input;
  const amount = `${numOnly(input)}`
    .replace(/\$/g, '')
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${amount}`;
};

// Date
const amountDotsFormat = (input) => {
  if (!input) return input;
  const amount = `${numOnlyToDots(input)}`
    .replace(/\$/g, '')
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `$${amount}`;
};

const amountParser = (value) =>
  value ? `${value}`.replace(/\$\s?|(,*)/g, '') : value;

const dotsFormat = (input) => {
  if (!input) return input;
  const amount = `${numOnly(input)}`
    .replace(/\$/g, '')
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${amount}`;
};

const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

const camelToTitleCase = (str) => {
  if (str) {
    const result = str.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  return '';
};

const strTrim = (str) => {
  if (str) {
    return str.trim();
  }
  return '';
};

const isEmptyString = (value) => /^\s*$/.test(value);

const getDiacriticRegex = (word) => {
  return word
    .split('')
    .reduce(
      (prev, curr) =>
        prev + (DIACRITIC_CHARS[curr] ? `[${DIACRITIC_CHARS[curr]}]` : curr),
      ''
    );
};
const highlightText = (text, filteredText) => {
  if (!Array.isArray(filteredText) || !filteredText.length) {
    return text;
  }
  let regExp;
  let match;
  let newText = text;
  filteredText.forEach((currFilteredText) => {
    if (isEmptyString(currFilteredText)) {
      return;
    }
    regExp = new RegExp(getDiacriticRegex(currFilteredText), 'gi');
    match = String(text).match(regExp);
    newText =
      match && match.length > 0
        ? newText.replace(
            regExp,
            `<span class="ant-typography" direction="ltr"><mark><span class="highlight-text">${match[0]}</span></mark></span>`
          )
        : newText;
  });
  return newText;
};

export {
  numOnly,
  numFormat,
  numIntegerFormat,
  numFormatLocale,
  numRoundFormatLocale,
  currencyFormat,
  currencyFormatCpeCpv,
  percent,
  maskNumber,
  parser,
  capitalize,
  dateFormat,
  amountFormat,
  budgetFormat,
  amountParser,
  dotsFormat,
  percentNormalizer,
  titleCase,
  plainPercent,
  numFormatWithoutDefault,
  percentWithoutDefaultPercent,
  camelToTitleCase,
  strTrim,
  numFormatWithLanguageCode,
  numFormatOriginal,
  amountDotsFormat,
  highlightText,
  isEmptyString,
};
