import { call } from 'redux-saga/effects';
import { ADMIN_BRANDS } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import objectToFormData from '../../../helpers/objectToFormData';
import request from '../../../helpers/request';
import { UPDATE_BRAND } from '../constants';

const formatter = ({ formValues: { agencies, users, ...rest } }) => {
  let values = { ...rest };
  delete values['_id'];
  values = objectToFormData(values);
  return { formValues: values, brandId: rest['_id'] };
};

const updateBrandNotifier = (formValues) =>
  notify({
    type: UPDATE_BRAND,
    formValues,
    formatter,
  });

function* updateBrandAction({ formValues, brandId }) {
  yield call(request, {
    type: UPDATE_BRAND,
    method: 'put',
    endpoint: `/brands/${brandId}`,
    params: formValues,
    path: ADMIN_BRANDS,
  });
}

export { updateBrandAction, updateBrandNotifier };
