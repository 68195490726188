import { takeLatest } from 'redux-saga/effects';
import { indexExchangeAction } from './actions/indexExchanges';
import { updateExchangeAction } from './actions/updateExchange';
import { showExchangeAction } from './actions/showExchange';
import { createExchangeAction } from './actions/createExchange';
import { deleteExchangeAction } from './actions/deleteExchange';
import {
  INDEX_EXCHANGES,
  SHOW_EXCHANGE,
  UPDATE_EXCHANGE,
  CREATE_EXCHANGE,
  DELETE_EXCHANGE,
  // GET_CURRENCY,
  // DELETE_CURRENCY,
} from './constants';
import { SHOW_LOADER_MOVEMENT } from '../../movements/constants';
import { showLoaderMovement } from '../../movements/loaderMovements';

export default [
  takeLatest(INDEX_EXCHANGES, indexExchangeAction),
  takeLatest(SHOW_EXCHANGE, showExchangeAction),
  takeLatest(UPDATE_EXCHANGE, updateExchangeAction),
  takeLatest(CREATE_EXCHANGE, createExchangeAction),
  takeLatest(DELETE_EXCHANGE, deleteExchangeAction),

  //   takeLatest(DELETE_CURRENCY, deleteCurrencyAction),
  //   takeLatest(DELETE_CURRENCY, deleteCurrencyAction),
  takeLatest(SHOW_LOADER_MOVEMENT, showLoaderMovement),
];
