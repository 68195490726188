import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { DELETE_CURRENCY } from '../constants';
import { ADMIN_SHOW_CURRENCY } from '../../../../routing/paths';

const deleteCurrencyNotifier = (currency, callback) => {
  return notify({
    type: DELETE_CURRENCY,
    currency,
    callback,
  });
};

function* deleteCurrencyAction({ currency, callback }) {
  yield call(request, {
    type: DELETE_CURRENCY,
    method: 'delete',
    endpoint: ADMIN_SHOW_CURRENCY(currency),
    callback,
  });
}

export { deleteCurrencyNotifier, deleteCurrencyAction };
