import PropTypes from 'prop-types';
import React from 'react';

const Loader = ({ initial, loaderText, className }) => (
  <div className={`${className} loading-container ${initial && 'initial'}`}>
    {initial ? (
      <div className="image-loader">
        <div className="logo" />
        <div className="lds-grid">
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : (
      <div className="lds-grid">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    )}
    <div className="loader-text">{loaderText}</div>
  </div>
);

Loader.propTypes = {
  initial: PropTypes.bool,
  loaderText: PropTypes.string,
  className: PropTypes.string,
};

Loader.defaultProps = {
  initial: false,
  loaderText: '',
  className: '',
};

export default Loader;
