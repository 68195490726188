import { call } from 'redux-saga/effects';
import { CAMPAIGNS_PATH } from '../../../../routing/paths';
import request from '../../../helpers/request';
import { UPDATE_BRIEF_OPS_USERS } from '../constants';
import notify from '../../../helpers/notify';

const updateBriefOpsUsersNotifier = (formValues, briefId, callback) =>
  notify({
    type: UPDATE_BRIEF_OPS_USERS,
    callback,
    formValues,
    briefId,
  });

function* updateBriefOpsUsersAction({ formValues, briefId, callback }) {
  yield call(request, {
    type: UPDATE_BRIEF_OPS_USERS,
    callback,
    method: 'put',
    endpoint: `/briefs/${briefId}/operationUsers`,
    params: formValues,
    path: CAMPAIGNS_PATH,
  });
}

export { updateBriefOpsUsersAction, updateBriefOpsUsersNotifier };
