import { t } from './helperFunctions';

export const INFLUENCER_ROLE = 'Influencer';
export const MANAGER_ROLE = 'Manager';
export const ADVISOR_ROLE = 'Advisor';
export const ACCOUNT_ROLE = 'Account';
export const COUNTRY_MANAGER_ROLE = 'CountryManager';
export const ADMIN_ROLE = 'Admin';
export const FINANCE_ROLE = 'Finance';
export const INFLUENCER_CONSULTANT = 'InfluencerConsultant';
export const BRAND_CONTACT_ROLE = 'BrandContact';
export const BRAND_MANAGER_ROLE = 'BrandManager';
export const BRAND_CLIENT = 'BrandClient';
export const ADMIN_WHITE_LABELS = 'AdminWhiteLabel';
// List of all roles
// export const roleList = [
//   INFLUENCER_ROLE,
//   MANAGER_ROLE,
//   BRAND_CONTACT_ROLE,
//   BRAND_MANAGER_ROLE,
//   ADVISOR_ROLE,
//   ACCOUNT_ROLE,
//   COUNTRY_MANAGER_ROLE,
//   ADMIN_ROLE,
//   FINANCE_ROLE,
// ]; // List of all operative roles (Fluvip)
export const opsList = [
  ADVISOR_ROLE,
  ACCOUNT_ROLE,
  COUNTRY_MANAGER_ROLE,
  ADMIN_ROLE,
  FINANCE_ROLE,
  INFLUENCER_CONSULTANT,
  BRAND_CLIENT,
  ADMIN_WHITE_LABELS,
]; // List of brand roles
// export const brandList = [BRAND_CONTACT_ROLE, BRAND_MANAGER_ROLE];

/**
 * Gets the roles that a user can list (View)
 *
 * @param {String} adminRole Enum of a valid role.
 *
 * @return {Array} Returns array of roles that can be accesed (Viewed) by input role.
 */
// export const getAdminRoleList = (adminRole) => {
//   switch (adminRole) {
//     case COUNTRY_MANAGER_ROLE:
//       return [ADVISOR_ROLE, ACCOUNT_ROLE];
//     case ADMIN_ROLE:
//       return [
//         ADMIN_ROLE,
//         COUNTRY_MANAGER_ROLE,
//         ACCOUNT_ROLE,
//         MANAGER_ROLE,
//         FINANCE_ROLE,
//       ];
//     default:
//       return [];
//   }
// };

/**
 * Gets the roles that a user can create by its role
 *
 * @param {String} adminRole Enum of a valid role.
 *
 * @return {Array} Returns array of roles that can be created by input role.
 */
export const adminCreateRols = (adminRole) => {
  switch (adminRole) {
    case ACCOUNT_ROLE:
      return [];
    case ADVISOR_ROLE:
      return [ACCOUNT_ROLE];
    case COUNTRY_MANAGER_ROLE:
      return [ADVISOR_ROLE, ACCOUNT_ROLE];
    case ADMIN_ROLE:
      return [
        ADMIN_ROLE,
        COUNTRY_MANAGER_ROLE,
        ADVISOR_ROLE,
        ACCOUNT_ROLE,
        FINANCE_ROLE,
        INFLUENCER_CONSULTANT,
        BRAND_CLIENT,
        ADMIN_WHITE_LABELS,
      ];
    case FINANCE_ROLE:
      return [
        ADMIN_ROLE,
        COUNTRY_MANAGER_ROLE,
        ADVISOR_ROLE,
        ACCOUNT_ROLE,
        FINANCE_ROLE,
        INFLUENCER_CONSULTANT,
        BRAND_CLIENT,
      ];
    case BRAND_CLIENT:
      return [];
    case INFLUENCER_CONSULTANT:
      return [];
    // Influencer and Manager roles can be created without authentication
    default:
      return [INFLUENCER_ROLE, MANAGER_ROLE];
  }
};

/**
 * Gets the roles that a user can create by its role, with the labels format:
 *
 * @param {String} authRole Enum of a valid auth role.
 *
 * @return {Array} Returns array of roles that can be created by input role formatted.
 * [{
 *  'label': 'value',
 *  'value: 'value
 * }]
 *
 */
export const getRolesPermitted = (authRole) => {
  const rolesAccepted = [];
  adminCreateRols(authRole).forEach((role) =>
    rolesAccepted.push({ label: role, value: role })
  );
  return rolesAccepted;
};

const ROLES_NAMES = {
  [INFLUENCER_ROLE]: 'INFLUENCER_ROLE',
  [MANAGER_ROLE]: 'MANAGER_ROLE',
  [BRAND_CONTACT_ROLE]: 'BRAND_CONTACT_ROLE',
  [BRAND_MANAGER_ROLE]: 'BRAND_MANAGER_ROLE',
  [ADVISOR_ROLE]: 'ADVISOR_ROLE',
  [ACCOUNT_ROLE]: 'ACCOUNT_ROLE',
  [COUNTRY_MANAGER_ROLE]: 'COUNTRY_MANAGER_ROLE',
  [ADMIN_ROLE]: 'ADMIN_ROLE',
  [FINANCE_ROLE]: 'FINANCE_ROLE',
  [INFLUENCER_CONSULTANT]: 'INFLUENCER_CONSULTANT',
  [BRAND_CLIENT]: 'BRAND_CLIENT',
  [ADMIN_WHITE_LABELS]: 'ADMIN_WHITE_LABEL',
};

export const getRoleName = (key) =>
  ROLES_NAMES[key] ? t(`roles.${ROLES_NAMES[key]}`) : key;
