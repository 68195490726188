import { ConfigProvider, Layout } from 'antd';
import esEs from 'antd/es/locale/es_ES';
import PropTypes, { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Axios from 'axios';
import Tracker from '@openreplay/tracker';
import LoaderContainer from '../../helpers/containers/LoaderContainer';
import Loader from '../../helpers/containers/LoaderContainer/Loader';
import ModalContainer from '../../helpers/containers/ModalContainer';
import Header from '../../helpers/layouts/Header';
import PrivateMenu from '../../helpers/layouts/PrivateLayout/Menu';
import { authSelector } from '../../redux/reducers/authReducer/authSelectors';
import { verifyTokenNotifier } from '../../redux/sagas/devise/actions/verifyToken';
import { getHostDomain } from '../../utils/helperFunctions';
import { ThemeProvider, ThemeTester } from '../ThemeManager';
import RoutesContainer from './RoutesContainer';

const tracker = new Tracker({
  projectKey: process.env.REACT_APP_KEY_OPEN_REPLAY,
  ingestPoint: 'https://openreplay.influtech.ai/ingest',
  captureIFrames: true,
});

const AppContainer = ({
  verifyToken,
  auth: { token, role, verified, _id, name, email },
  clearSpaces,
  headerVisible,
  brandClient,
}) => {
  useEffect(() => {
    if (token && email) {
      tracker.start({
        userID: email,
        metadata: {
          userUUID: _id,
          name,
        },
      });
    }
  }, [email, tracker, _id]);

  const [domainConfigData, setDomainConfigData] = useState({});

  const domain = getHostDomain();

  const getConfTheme = (domainParam) => {
    Axios.get(
      `${process.env.REACT_APP_AWS_S3_URL}whitelabel/${domainParam}/conf-${domainParam}`
    )
      .then(({ data }) => {
        setDomainConfigData(data);
        localStorage.setItem(`conf-${domainParam}`, JSON.stringify(data));
      })
      .catch(() => {
        setDomainConfigData({ theme: { name: 'main' } });
        localStorage.setItem(
          `conf-${domainParam}`,
          JSON.stringify({ whiteBrand: '001', theme: { name: 'main' } })
        );
      });
  };

  useEffect(() => {
    verifyToken();
    const confLocalStorage = JSON.parse(localStorage.getItem(`conf-${domain}`));
    if (!confLocalStorage) {
      getConfTheme(domain);
    } else {
      setDomainConfigData(confLocalStorage);
    }
  }, []);

  const renderHeader = () =>
    token && headerVisible && <Header brandClient={brandClient} />;
  const renderMenu = () => token && <PrivateMenu role={role} />;

  const layoutClasses = () => {
    let classes = '';
    if (token) classes += 'logged ';
    if (clearSpaces) classes += 'clear-spaces ';
    if (!headerVisible) classes += 'header-no-visible ';
    if (role) classes += role;
    return classes;
  };

  return (
    <ThemeProvider domainConfigData={domainConfigData}>
      {!verified && localStorage.getItem('token') ? (
        <Loader initial />
      ) : (
        <ConfigProvider locale={esEs}>
          <Layout className={layoutClasses()}>
            <LoaderContainer />
            <ModalContainer />
            {renderHeader()}
            <Layout>
              {renderMenu()}
              <Layout>
                <Layout.Content>
                  <ThemeTester />
                  <RoutesContainer role={role} />
                </Layout.Content>
              </Layout>
            </Layout>
          </Layout>
        </ConfigProvider>
      )}
    </ThemeProvider>
  );
};

AppContainer.propTypes = {
  verifyToken: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    token: PropTypes.string,
    role: PropTypes.string,
    isGlobalManager: bool,
    verified: PropTypes.bool,
    _id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  clearSpaces: bool,
  headerVisible: bool,
  brandClient: PropTypes.string,
};

AppContainer.defaultProps = {
  clearSpaces: false,
  headerVisible: true,
  brandClient: '',
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
    clearSpaces: state.layout.clearSpaces,
    headerVisible: state.layout.header.visible,
    brandClient: state.auth.brandClient,
  };
};

const mapDispatchToProps = {
  verifyToken: verifyTokenNotifier,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AppContainer));
