import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { UPDATE_PROPOSAL_NAME, UPDATE_PROPOSAL_STATUS } from '../constants';
import notify from '../../../helpers/notify';

const updateProposalStatusNotifier = (
  campaignId,
  proposalId,
  status,
  callback
) =>
  notify({
    type: UPDATE_PROPOSAL_STATUS,
    campaignId,
    proposalId,
    status,
    callback,
    loader: true,
  });

function* updateProposalStatusAction({
  campaignId,
  proposalId,
  status,
  callback,
}) {
  yield call(request, {
    type: UPDATE_PROPOSAL_NAME,
    method: 'put',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}/status`,
    params: { status },
    callback,
  });
}

export { updateProposalStatusAction, updateProposalStatusNotifier };
