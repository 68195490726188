import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import {
  DUPLICATE_CAMPAIGN_PROPOSAL,
  DELETE_CAMPAIGN_PROPOSAL,
} from '../constants';

const deleteCampaignProposalNotifier = (campaignId, proposalId, callback) =>
  notify({
    type: DELETE_CAMPAIGN_PROPOSAL,
    campaignId,
    proposalId,
    callback,
  });

function* deleteCampaignProposalAction({ campaignId, proposalId, callback }) {
  yield call(request, {
    type: DUPLICATE_CAMPAIGN_PROPOSAL,
    method: 'delete',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}`,
    callback,
  });
}

export { deleteCampaignProposalAction, deleteCampaignProposalNotifier };
