import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { SHOW_USER } from '../constants';
import notify from '../../../helpers/notify';

const showUserNotifier = (userId) =>
  notify({
    type: SHOW_USER,
    userId,
  });

function* showUserAction({ userId }) {
  yield call(request, {
    type: SHOW_USER,
    method: 'get',
    endpoint: `/users/${userId}`,
  });
}

export { showUserAction, showUserNotifier };
