import { cloneDeep } from 'lodash';
import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { UPDATE_EXCHANGE } from '../constants';
import { ADMIN_EXCHANGES } from '../../../../routing/paths';
import { formatDate } from '../../../../utils/helperFunctions';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  params.status = formValues.status ? 1 : 0;
  params.updatedAt = new Date();
  params.dateRate = new Date(
    formatDate(params.dateRate, 'YYYY-MM-DD', 'DD-MM-YYYY')
  );
  return {
    formValues: params,
  };
};
const updateExchangeNotifier = (formValues, loader = false) => {
  return notify({
    type: UPDATE_EXCHANGE,
    formValues,
    formatter,
    loader,
  });
};

function* updateExchangeAction({ formValues }) {
  yield call(request, {
    type: UPDATE_EXCHANGE,
    method: 'put',
    endpoint: ADMIN_EXCHANGES,
    params: formValues,
    path: ADMIN_EXCHANGES,
  });
}

export { updateExchangeNotifier, updateExchangeAction };
