import React from 'react';
import { Icon, Popover, Select } from 'antd';
import { actions, useTheme } from '../Provider';
import './themeTester.scss';

export const ThemeTester = () => {
  const [themeData, dispatch] = useTheme();

  const handleChange = (theme) => {
    dispatch(actions.handleChange(theme));
  };
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }
  return (
    <div className="theme-tester">
      <Popover
        content={
          <Select
            className="theme-tester-select"
            defaultValue={themeData.selected}
            onChange={handleChange}
          >
            {Object.keys(themeData.themes).map((theme) => (
              <Select.Option key={theme} title={theme} value={theme}>
                {theme}
              </Select.Option>
            ))}
          </Select>
        }
        title="Change Theme"
        trigger="click"
      >
        <Icon type="bg-colors" />
      </Popover>
    </div>
  );
};
