import { call } from 'redux-saga/effects';
import { CAMPAIGNS_PATH } from '../../../../routing/paths';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { UPDATE_BRIEF_STATUS } from '../constants';

const updateBriefStatusNotifier = (formValues, briefId, callback) =>
  notify({
    type: UPDATE_BRIEF_STATUS,
    formValues,
    callback,
    briefId,
  });

function* updateBriefStatusAction({ formValues, briefId, callback }) {
  yield call(request, {
    type: UPDATE_BRIEF_STATUS,
    method: 'put',
    endpoint: `/briefs/${briefId}/status`,
    params: formValues,
    path: CAMPAIGNS_PATH,
    callback,
  });
}

export { updateBriefStatusAction, updateBriefStatusNotifier };
