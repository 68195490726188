export const HIDE_LOADER_MOVEMENT = 'HIDE_LOADER_MOVEMENT';
export const SHOW_LOADER_MOVEMENT = 'SHOW_LOADER_MOVEMENT';
export const SHOW_LOADER_MOVEMENT_TEXT = 'SHOW_LOADER_MOVEMENT_TEXT';
export const HIDE_SPINNER_MOVEMENT = 'HIDE_SPINNER_MOVEMENT';
export const SHOW_SPINNER_MOVEMENT = 'SHOW_SPINNER_MOVEMENT';
export const SHOW_MODAL_MOVEMENT = 'SHOW_MODAL_MOVEMENT';
export const HIDE_MODAL_MOVEMENT = 'HIDE_MODAL_MOVEMENT';
export const LOG_OUT_MOVEMENT = 'LOG_OUT_MOVEMENT';

export const TOGGLE_SIDER_MOVEMENT = 'TOGGLE_SIDER_MOVEMENT';
export const HIDE_SIDER_MOVEMENT = 'HIDE_SIDER_MOVEMENT';
export const SHOW_SIDER_MOVEMENT = 'SHOW_SIDER_MOVEMENT';
export const COLLAPSE_SIDER_MOVEMENT = 'COLLAPSE_SIDER_MOVEMENT';
export const EXPAND_SIDER_MOVEMENT = 'EXPAND_SIDER_MOVEMENT';

export const HIDE_LAYOUT_MOVEMENT = 'HIDE_LAYOUT_MOVEMENT';
export const SHOW_LAYOUT_MOVEMENT = 'SHOW_LAYOUT_MOVEMENT';

export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const RM_CART_ITEM = 'RM_CART_ITEM';
export const CLEAR_SPACES_LAYOUT_MOVEMENT = 'CLEAR_SPACES_LAYOUT_MOVEMENT';
export const RESET_SPACES_LAYOUT_MOVEMENT = 'RESET_SPACES_LAYOUT_MOVEMENT';

export const CHANGE_TAB_PREFIX = 'CHANGE_TAB_PREFIX';

export const UPDATE_PROPOSAL_TOGGLES = 'UPDATE_PROPOSAL_TOGGLES';
