import { takeLatest } from 'redux-saga/effects';
import { createWhiteLabelAction } from './actions/createWhiteLabel';
import { deleteWhiteLabelAction } from './actions/deleteWhiteLabel';
import { indexWhiteLabelAction } from './actions/indexWhiteLabel';
import { showWhiteLabelAction } from './actions/showWhiteLabel';
import { updateWhiteLabelAction } from './actions/updateWhiteLabel';
import {
  CREATE_WHITE_LABEL,
  DELETE_WHITE_LABEL,
  GET_WHITE_LABEL,
  INDEX_WHITE_LABEL,
  SHOW_WHITE_LABEL,
  UPDATE_WHITE_LABEL,
} from './constants';

export default [
  takeLatest(CREATE_WHITE_LABEL, createWhiteLabelAction),
  takeLatest(INDEX_WHITE_LABEL, indexWhiteLabelAction),
  takeLatest(GET_WHITE_LABEL, showWhiteLabelAction),
  takeLatest(SHOW_WHITE_LABEL, showWhiteLabelAction),
  takeLatest(UPDATE_WHITE_LABEL, updateWhiteLabelAction),
  takeLatest(DELETE_WHITE_LABEL, deleteWhiteLabelAction),
];
