import { call, put } from 'redux-saga/effects';
import { parametersFilters } from '../../../../utils/filters/parameterFilters';
import request from '../../../helpers/request';
import { GET_PARAMETERS, UPDATE_PARAMETER } from '../constants';
import notify from '../../../helpers/notify';

const updateParameterNotifier = (formValues) =>
  notify({
    type: UPDATE_PARAMETER,
    formValues,
  });

function* updateParameterAction({ formValues: { _id, ...rest } }) {
  yield call(request, {
    type: UPDATE_PARAMETER,
    method: 'put',
    endpoint: `/parameters/${_id}`,
    params: rest,
  });
  yield put({ type: GET_PARAMETERS, formValues: parametersFilters });
}

export { updateParameterAction, updateParameterNotifier };
