/* eslint-disable react/jsx-props-no-spreading */
import OTPInput from 'react-otp-input';
import React from 'react';
import './otpField.scss';

const OTPField = ({ ...props }) => (
  <OTPInput
    numInputs={6}
    inputStyle="otp-input"
    containerStyle="otp-container"
    {...props}
  />
);

OTPField.propTypes = {};

export default OTPField;
