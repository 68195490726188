import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from '../../../routing/paths';
import logoAlpina from '../../../resources/images/alpina.svg';
import logoRepco from '../../../resources/images/logo-repco.svg';
import './header.scss';

const Header = ({ brandClient }) => (
  <Layout.Header className="header">
    {brandClient === 'Alpina' && (
      <Link to={ROOT_PATH} style={{ marginTop: '-5px' }}>
        <img src={logoAlpina} alt="logo" className="img-alpina" />
      </Link>
    )}
    {(brandClient === 'RON VIEJO DE CALDAS' ||
      brandClient === 'Aguardiente Cristal') && (
      <Link to={ROOT_PATH} style={{ marginTop: '-5px' }}>
        <img src={logoRepco} alt="logo" className="img-alpina" />
      </Link>
    )}
    {brandClient === 'Gillette' && (
      <Link to={ROOT_PATH}>
        <div className="img-logo" />
      </Link>
    )}
    {!brandClient && (
      <Link to={ROOT_PATH}>
        <div className="img-logo" />
      </Link>
    )}
  </Layout.Header>
);

Header.propTypes = {
  brandClient: PropTypes.string,
};

Header.defaultProps = {
  brandClient: '',
};

export default Header;
