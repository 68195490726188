import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { GET_WHITE_LABEL, SHOW_WHITE_LABEL } from '../constants';
import notify from '../../../helpers/notify';

const showWhiteLabelNotifier = (whiteLabelId, external = false) =>
  notify({
    type: external ? SHOW_WHITE_LABEL : GET_WHITE_LABEL,
    whiteLabelId,
    external,
  });

function* showWhiteLabelAction({ whiteLabelId, external }) {
  yield call(request, {
    type: external ? SHOW_WHITE_LABEL : GET_WHITE_LABEL,
    method: 'get',
    endpoint: `/whitelabel/${whiteLabelId}`,
  });
}

export { showWhiteLabelAction, showWhiteLabelNotifier };
