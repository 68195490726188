import { call, put } from 'redux-saga/effects';
import moment from 'moment';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import triggerAction from '../../../helpers/triggerAction';
import { SHOW_CAMPAIGN_CONTENT_GRID } from '../constants';

const showCampaignContentGridNotifier = (campaignId) =>
  notify({
    type: SHOW_CAMPAIGN_CONTENT_GRID,
    campaignId,
  });

function* onSuccess(type, data) {
  const processedData = { ...data };
  processedData.campaignContext.socialNetworkAccounts.forEach((sna) => {
    // eslint-disable-next-line no-param-reassign
    sna.content = sna.content.reduce((newContent, current) => {
      const newDate = current.description.date;
      newContent.push({
        ...current,
        description: {
          ...current.description,
          date: moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        },
      });
      return newContent;
    }, []);
  });
  yield put(triggerAction(`${type}_SUCCESS`, processedData));
}

function* showCampaignContentGridAction({ campaignId }) {
  yield call(request, {
    type: SHOW_CAMPAIGN_CONTENT_GRID,
    method: 'get',
    endpoint: `/campaigns/${campaignId}/content-grid`,
    onSuccess,
  });
}

export { showCampaignContentGridAction, showCampaignContentGridNotifier };
