import { cloneDeep } from 'lodash';
import moment from 'moment';
import { call } from 'redux-saga/effects';
import { formatDate, t } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';
import request from '../../../helpers/request';
import { CREATE_BRIEF } from '../constants';

const formatter = ({ formValues }) => {
  const params = cloneDeep(formValues);
  // if (params.startDate && !params.startDate.isValid()) {
  //   newInput.value = moment();
  // }
  params.startDate = formatDate(params.startDate, 'YYYY-MM-DD', 'MMM YYYY');
  if (params.startDate === 'INVALID DATE') {
    params.startDate = moment()
      .format('YYYY-MM-DD')
      .toUpperCase()
      .replace('.', '');
  }
  params.budget = Number(params.budget);

  if (params.advisor === t('brief.noAdvisor')) delete params.advisor;

  return {
    formValues: params,
  };
};

const createBriefNotifier = (formValues, callback) =>
  notify({
    type: CREATE_BRIEF,
    formValues,
    formatter,
    callback,
  });

function* createBriefAction({ formValues, callback }) {
  yield call(request, {
    type: CREATE_BRIEF,
    method: 'post',
    endpoint: '/briefs',
    params: formValues,
    callback,
  });
}

export { createBriefAction, createBriefNotifier };
