import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { DUPLICATE_CAMPAIGN_PROPOSAL } from '../constants';

const duplicateProposalNotifier = (campaignId, proposalId, callback) =>
  notify({
    type: DUPLICATE_CAMPAIGN_PROPOSAL,
    campaignId,
    proposalId,
    callback,
  });

function* duplicateProposalAction({ campaignId, proposalId, callback }) {
  yield call(request, {
    type: DUPLICATE_CAMPAIGN_PROPOSAL,
    method: 'post',
    endpoint: `/campaigns/${campaignId}/proposals/${proposalId}`,
    callback,
  });
}

export { duplicateProposalAction, duplicateProposalNotifier };
