import { lazy } from 'react';
import Root from '../components/Root';
import {
  ACCOUNT_ROLE,
  ADMIN_ROLE,
  FINANCE_ROLE,
  ADVISOR_ROLE,
  COUNTRY_MANAGER_ROLE,
  BRAND_CLIENT,
  ADMIN_WHITE_LABELS,
  INFLUENCER_CONSULTANT,
} from '../utils/roles';
import * as Paths from './paths';

// TODO: Centralize authorization
const ForgotPassword = lazy(() => import('../components/Auth/ForgotPassword'));
const ChangePassword = lazy(() => import('../components/Auth/ChangePassword'));
const PublicBrief = lazy(() => import('../components/PublicBrief'));
const Brands = lazy(() => import('../components/Admin/Brands'));
const BrandForm = lazy(() => import('../components/Admin/Brands/BrandForm'));
const Parameters = lazy(() => import('../components/Admin/Parameters'));
const ParameterForm = lazy(() =>
  import('../components/Admin/Parameters/ParameterForm')
);
const Finance = lazy(() => import('../components/Campaigns/Finance'));
const Users = lazy(() => import('../components/Admin/Users'));
const UserForm = lazy(() => import('../components/Admin/Users/UserForm'));
const SNTForm = lazy(() => import('../components/Admin/SNTs/SNTForm'));
const Campaigns = lazy(() => import('../components/Campaigns'));
const ShowBrief = lazy(() => import('../components/Briefs/ShowBrief'));
const CampaignHub = lazy(() => import('../components/Campaigns/CampaignHub'));
const CampaignForm = lazy(() => import('../components/Campaigns/CampaignForm'));
const CampaignContentGrid = lazy(() =>
  import('../components/Campaigns/CampaignContentGrid')
);
const CampaignStatistics = lazy(() =>
  import('../components/Campaigns/CampaignStatistics')
);
const CampaignContent = lazy(() =>
  import('../components/Campaigns/CampaignContent')
);
const ProposalShow = lazy(() => import('../components/Campaigns/ProposalShow'));
const SocialNetworkAccounts = lazy(() =>
  import('../components/Admin/SocialNetworkAccounts')
);
const StyleGuide = lazy(() => import('../components/StyleGuide'));
const CampaignReport = lazy(() =>
  import('../components/Campaigns/CampaignReport')
);

const IndicatorManagement = lazy(() =>
  import('../components/IndicatorManagement')
);
const Currencies = lazy(() => import('../components/Admin/Currencies'));
const CurrencyForm = lazy(() =>
  import('../components/Admin/Currencies/CurrencyForm/CurrencyForm')
);
const Exchanges = lazy(() => import('../components/Admin/Exchanges'));
const ExchangesForm = lazy(() =>
  import('../components/Admin/Exchanges/ExchangeForm')
);

const WhiteLabels = lazy(() => import('../components/Admin/WhiteLabels'));
const WhiteLabelsForm = lazy(() =>
  import('../components/Admin/WhiteLabels/WhiteLabelForm')
);

export const DEFAULT_TITLE_PREFIX = '';
export default [
  {
    title: 'Forgot Password',
    defaultPrefix: true,
    path: Paths.AUTH_FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    title: 'Change Password',
    defaultPrefix: true,
    path: Paths.CHANGE_PASSWORD,
    component: ChangePassword,
  },
  {
    title: 'New Brief',
    defaultPrefix: true,
    path: Paths.PUBLIC_BRIEF,
    component: PublicBrief,
  },
  {
    title: 'Proposal',
    defaultPrefix: true,
    path: Paths.PUBLIC_PROPOSAL_REPORT(':campaignId'),
    component: ProposalShow,
  },
  {
    title: '',
    defaultPrefix: false,
    path: Paths.PROPOSAL_REPORT_PREVIEW(':campaignId', ':proposalId'),
    component: ProposalShow,
    // roles: [ADMIN_ROLE, ADVISOR_ROLE, COUNTRY_MANAGER_ROLE, ACCOUNT_ROLE],
  },
  {
    title: 'Home',
    defaultPrefix: true,
    path: Paths.ROOT_PATH,
    component: Root,
  },
  {
    title: 'Users',
    defaultPrefix: true,
    path: Paths.ADMIN_USERS,
    component: Users,
    roles: [ADMIN_ROLE, COUNTRY_MANAGER_ROLE, ADVISOR_ROLE, FINANCE_ROLE],
  },
  {
    title: 'Finance',
    defaultPrefix: true,
    path: Paths.ADMIN_FINANCE,
    component: Finance,
    roles: [ADMIN_ROLE, FINANCE_ROLE, BRAND_CLIENT, COUNTRY_MANAGER_ROLE],
  },
  {
    title: 'Create Users',
    prefix: false,
    path: Paths.ADMIN_CREATE_USER,
    component: UserForm,
    roles: [ADMIN_ROLE, COUNTRY_MANAGER_ROLE, ADVISOR_ROLE, FINANCE_ROLE],
  },
  {
    title: 'Edit User',
    prefix: false,
    path: Paths.ADMIN_SHOW_USER(':userId'),
    component: UserForm,
    roles: [ADMIN_ROLE, COUNTRY_MANAGER_ROLE, ADVISOR_ROLE, FINANCE_ROLE],
  },
  {
    title: '',
    defaultPrefix: true,
    path: Paths.ADMIN_PARAMETERS,
    component: Parameters,
    roles: [ADMIN_ROLE, FINANCE_ROLE],
  },
  {
    title: '',
    defaultPrefix: true,
    path: Paths.ADMIN_UPDATE_PARAMETER,
    component: ParameterForm,
    roles: [ADMIN_ROLE, FINANCE_ROLE],
  },
  {
    title: 'Brands',
    prefix: false,
    path: Paths.ADMIN_BRANDS,
    component: Brands,
    roles: [
      ADMIN_ROLE,
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Create Brand',
    prefix: false,
    path: Paths.ADMIN_CREATE_BRAND,
    component: BrandForm,
    roles: [
      ADMIN_ROLE,
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Edit Brand',
    prefix: false,
    path: Paths.ADMIN_SHOW_BRAND(':brandId'),
    component: BrandForm,
    roles: [
      ADMIN_ROLE,
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Currencies',
    prefix: false,
    path: Paths.ADMIN_CURRENCIES,
    component: Currencies,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Create Currency',
    prefix: false,
    path: Paths.ADMIN_CREATE_CURRENCY,
    component: CurrencyForm,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Edit Currency',
    prefix: false,
    path: Paths.ADMIN_SHOW_CURRENCY(':currencyId'),
    component: CurrencyForm,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Exchanges',
    prefix: false,
    path: Paths.ADMIN_EXCHANGES,
    component: Exchanges,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Edit Exchange',
    prefix: false,
    path: Paths.ADMIN_SHOW_EXCHANGE(':exchangeId'),
    component: ExchangesForm,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Create Exchange',
    prefix: false,
    path: Paths.ADMIN_CREATE_EXCHANGE,
    component: ExchangesForm,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Create Influencer',
    prefix: false,
    path: Paths.ADMIN_CREATE_SNT,
    component: SNTForm,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      ADMIN_ROLE,
      COUNTRY_MANAGER_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Edit Influencer',
    prefix: false,
    path: Paths.ADMIN_SHOW_SNT(':sntId'),
    component: SNTForm,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      ADMIN_ROLE,
      COUNTRY_MANAGER_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: '',
    prefix: false,
    path: Paths.CAMPAIGNS_PATH,
    component: Campaigns,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Brief',
    defaultPrefix: false,
    path: Paths.SHOW_BRIEF_PATH(':briefId'),
    component: ShowBrief,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Edit Brief',
    prefix: false,
    path: Paths.EDIT_BRIEF_PATH(':briefId'),
    component: PublicBrief,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Create',
    defaultPrefix: false,
    path: Paths.CREATE_BRIEF_CAMPAIGN_PATH(':briefId'),
    component: CampaignForm,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Campaign Detail',
    defaultPrefix: false,
    path: Paths.SHOW_CAMPAIGN_PATH(':campaignId'),
    component: CampaignHub,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Create Proposal',
    defaultPrefix: false,
    path: Paths.CAMPAIGN_PROPOSAL_PATH(':campaignId', ':proposalId'),
    component: CampaignForm,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Content grid',
    defaultPrefix: false,
    path: Paths.CAMPAIGN_CONTENT_GRID_PATH(':campaignId'),
    component: CampaignContentGrid,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Report Campaign',
    defaultPrefix: false,
    path: Paths.CAMPAIGN_STATISTICS_PATH(':campaignId'),
    component: CampaignStatistics,
  },
  {
    title: 'Add Content',
    defaultPrefix: false,
    path: Paths.CAMPAIGN_CONTENT_PATH(':campaignId', ':snaId'),
    component: CampaignContent,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
    ],
  },
  {
    title: 'Find Influencers',
    prefix: false,
    path: Paths.ADMIN_SNAS,
    component: SocialNetworkAccounts,
    roles: [
      ACCOUNT_ROLE,
      ADVISOR_ROLE,
      COUNTRY_MANAGER_ROLE,
      ADMIN_ROLE,
      FINANCE_ROLE,
      INFLUENCER_CONSULTANT,
    ],
  },
  {
    title: 'Marca Report',
    defaultPrefix: true,
    path: Paths.PUBLIC_CAMPAIGN_REPORT(':campaignId'),
    component: CampaignReport,
  },
  {
    title: 'Style Guide',
    prefix: false,
    path: Paths.STYLE_GUIDE,
    component: StyleGuide,
  },
  {
    title: 'Indicator Management',
    defaultPrefix: false,
    path: Paths.INDICATOR_MANAGEMENT,
    component: IndicatorManagement,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'White Label',
    prefix: false,
    path: Paths.ADMIN_WHITE_LABEL,
    component: WhiteLabels,
    roles: [ADMIN_ROLE, ADMIN_WHITE_LABELS],
  },
  {
    title: 'Create White Label',
    prefix: false,
    path: Paths.ADMIN_CREATE_WHITE_LABEL,
    component: WhiteLabelsForm,
    roles: [ADMIN_ROLE],
  },
  {
    title: 'Edit White Label',
    prefix: false,
    path: Paths.ADMIN_SHOW_WHITE_LABEL(':whiteLabelId'),
    component: WhiteLabelsForm,
    roles: [ADMIN_ROLE],
  },
];
