import { takeLatest } from 'redux-saga/effects';
import { showSNAAction } from './actions/showSNA';
import { indexSNAsAction } from './actions/indexSNAs';
import { updateSNAsAction } from './actions/updateSNAs';
import {
  INDEX_SNA,
  INDEX_SNA_FULL,
  LINK_FB_ACCOUNT,
  SHOW_SNA,
} from './constants';
import { linkFBAccountAction } from './actions/linkFBAccount';

export default [
  takeLatest(LINK_FB_ACCOUNT, linkFBAccountAction),
  takeLatest(INDEX_SNA, indexSNAsAction),
  takeLatest(INDEX_SNA_FULL, updateSNAsAction),
  takeLatest(SHOW_SNA, showSNAAction),
];
