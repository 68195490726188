import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { INDEX_USERS } from '../constants';
import { likeRegex } from '../../../../utils/helperFunctions';
import notify from '../../../helpers/notify';

const formatter = ({
  formValues: {
    query: { _id, name, role, country, isGlobalManager, whiteBrand, status },
    ...rest
  },
  external,
}) => ({
  formValues: {
    query: {
      status,
      $or: [
        { name: likeRegex(name), role, country, _id },
        {
          name: likeRegex(name),
          role,
          'opsContext.isGlobalManager': isGlobalManager,
        },
      ],
    },
    ...rest,
    whiteBrand,
  },
  external,
});

const indexUsersNotifier = (formValues, external, callback) =>
  notify({
    type: INDEX_USERS,
    formValues,
    callback,
    external,
    loader: false,
    formatter,
  });

function* indexUsersAction({ formValues, callback, external }) {
  yield call(request, {
    type: external ? 'INDEX_USERS_EXTERNAL' : INDEX_USERS,
    method: 'get',
    endpoint: '/users',
    callback,
    params: formValues,
  });
}

export { indexUsersAction, indexUsersNotifier };
