import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { INDEX_ADVISOR_USERS } from '../constants';
import notify from '../../../helpers/notify';

const indexAdvisorUsersNotifier = (formValues, external = false, callback) =>
  notify({
    type: INDEX_ADVISOR_USERS,
    formValues,
    external,
    callback,
  });

function* indexAdvisorUsersAction({ formValues, external, callback }) {
  yield call(request, {
    type: INDEX_ADVISOR_USERS,
    method: 'get',
    endpoint: external ? '/users/advisors/data' : '/users/advisors',
    params: formValues,
    callback,
  });
}

export { indexAdvisorUsersAction, indexAdvisorUsersNotifier };
