import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { useTheme } from '../ThemeManager/Provider';
import { THEME_VARS } from '../ThemeManager/themes/constants';
// eslint-disable-next-line import/no-named-as-default
import LogIn from './LogIn';
// eslint-disable-next-line import/no-named-as-default
import LandIng from './LandIng';

export const RootPages = () => {
  const [theme] = useTheme();
  const [showLogin, setShowLogin] = useState(false);
  const checkRootPage = () => {
    const { selected } = theme;
    if (theme.themes[selected]) {
      const isLanding =
        theme.themes[selected][THEME_VARS.THEME_VALID_LANDING_IMAGE];
      setShowLogin(!isLanding);
    }
  };
  useEffect(() => {
    checkRootPage();
  }, [theme.selected]);
  return showLogin ? <LogIn /> : <LandIng setShowLogin={setShowLogin} />;
};

export const SimpleRoot = RootPages;

const ShowTheLocationWithRouter = withRouter(RootPages);

export default connect()(
  reduxForm({
    form: 'logInForm',
  })(ShowTheLocationWithRouter)
);
