import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL } from '../constants';

const createContentProposalCommentNotifier = (
  campaignId,
  contentId,
  proposalId,
  feedback
) => {
  return notify({
    type: CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL,
    campaignId,
    contentId,
    proposalId,
    feedback,
  });
};

function* createContentProposalCommentAction({
  campaignId,
  contentId,
  proposalId,
  feedback,
}) {
  yield call(request, {
    type: CREATE_COMMENT_CONTENT_ACTIVE_PROPOSAL,
    method: 'post',
    endpoint: `campaigns/${campaignId}/contents/${contentId}/proposals/${proposalId}/feedback`,
    params: { feedback },
  });
}
export {
  createContentProposalCommentAction,
  createContentProposalCommentNotifier,
};
