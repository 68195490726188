import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { GET_PARAMETERS } from '../constants';
import notify from '../../../helpers/notify';
import { likeRegex } from '../../../../utils/helperFunctions';

const formatter = ({
  formValues: {
    query: { title },
    ...rest
  },
}) => ({
  formValues: {
    query: {
      title: likeRegex(title),
    },
    ...rest,
  },
});

const indexParametersNotifier = (formValues) =>
  notify({
    type: GET_PARAMETERS,
    formValues,
    formatter,
  });

function* indexParametersAction({ formValues }) {
  yield call(request, {
    type: GET_PARAMETERS,
    method: 'get',
    endpoint: '/parameters',
    params: formValues,
  });
}

export { indexParametersAction, indexParametersNotifier };
