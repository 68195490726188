import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import { GET_BRAND, SHOW_BRAND } from '../constants';
import notify from '../../../helpers/notify';

const showBrandNotifier = (brandId, external = false) =>
  notify({
    type: external ? SHOW_BRAND : GET_BRAND,
    brandId,
    external,
  });

function* showBrandAction({ brandId, external }) {
  yield call(request, {
    type: external ? SHOW_BRAND : GET_BRAND,
    method: 'get',
    endpoint: `/brands/${brandId}`,
  });
}

export { showBrandAction, showBrandNotifier };
