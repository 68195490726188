import { THEME_VARS } from './constants';

const PRIMARY_COLOR = '#1442f9';
const PINK_COLOR = '#df249d';
const WHITE_COLOR = 'white';
const PURPLE_COLOR = '#6670e8';

export const main = {
  [THEME_VARS.PRIMARY_COLOR]: PRIMARY_COLOR,
  [THEME_VARS.LAYOUT_HEADER_BACKGROUND_COLOR]: PRIMARY_COLOR,
  [THEME_VARS.MENU_ITEM_COLOR]: PRIMARY_COLOR,
  [THEME_VARS.TABLE_HEADER_COLOR]: PRIMARY_COLOR,
  [THEME_VARS.PRIMARY_BACKGROUND_COLOR_BUTTON]: PINK_COLOR,
  [THEME_VARS.PRIMARY_TEXT_COLOR_BUTTON]: WHITE_COLOR,
  [THEME_VARS.SECONDARY_COLOR_CONTRAST_PRIMARY]: WHITE_COLOR,
  [THEME_VARS.PRIMARY_BACKGROUND_COLOR_COLLAPSE_HEADER]: PINK_COLOR,
  [THEME_VARS.SECONDARY_BACKGROUND_COLOR]: PINK_COLOR,
  [THEME_VARS.SECONDARY_TEXT_COLOR]: PURPLE_COLOR,
  [THEME_VARS.LOGIN_IMAGE]: `''`,
  [THEME_VARS.LOGO_IMAGE]: `''`,
  [THEME_VARS.PREVIEW_IMAGE]: `''`,
  [THEME_VARS.CART_IMAGE]: `''`,
  [THEME_VARS.BLUE_BOX_IMAGE]: `''`,
  [THEME_VARS.PINK_BOX_IMAGEs]: `''`,
  [THEME_VARS.THEME]: 'false',
  [THEME_VARS.THEME_VALID_BLACK]: 'false',
  [THEME_VARS.THEME_VALID_GREENSOCIAL]: 'false',
  [THEME_VARS.THEME_VALID_BLUERCN]: 'false',
  [THEME_VARS.THEME_VALID_VICTORIAFRIENDS]: 'false',
  [THEME_VARS.THEME_VALID_THEME2]: 'false',
  [THEME_VARS.THEME_VALID_MAIN]: 'false',
  [THEME_VARS.THEME_VALID_ATV]: 'false',
  [THEME_VARS.THEME_VALID_REBOLD]: 'false',
  [THEME_VARS.THEME_VALID_MBCS]: 'false',
};
