import { call } from 'redux-saga/effects';
import request from '../../../helpers/request';
import notify from '../../../helpers/notify';
import { BIND_CAMPAIGN_CONTENT } from '../constants';

const bindCampaignContentNotifier = (campaignId, contentId, url, callback) =>
  notify({
    type: BIND_CAMPAIGN_CONTENT,
    campaignId,
    contentId,
    url,
    callback,
  });

function* bindCampaignContentAction({ campaignId, contentId, url, callback }) {
  yield call(request, {
    type: BIND_CAMPAIGN_CONTENT,
    method: 'post',
    endpoint: `/campaigns/${campaignId}/contents/${contentId}/url`,
    params: { url },
    callback,
  });
}

export { bindCampaignContentAction, bindCampaignContentNotifier };
