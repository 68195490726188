export const THEME_VARS = {
  PRIMARY_COLOR: '@primary-color',
  LAYOUT_HEADER_BACKGROUND_COLOR: '@layout-header-background',
  MENU_ITEM_COLOR: '@menu-item-color',
  TABLE_HEADER_COLOR: '@table-header-color',
  SECONDARY_BACKGROUND_COLOR: '@secondary-background-color',
  SECONDARY_TEXT_COLOR: '@secondary-text-color',
  PRIMARY_BACKGROUND_COLOR_BUTTON: '@primary-background-color-button',
  PRIMARY_TEXT_COLOR_BUTTON: '@primary-text-color-button',
  SECONDARY_COLOR_CONTRAST_PRIMARY: '@secondary-color-contrast-primary',
  PRIMARY_BACKGROUND_COLOR_COLLAPSE_HEADER:
    '@primary-background-color-collapse-header',
  LOGIN_IMAGE: '@login-image',
  LOGO_IMAGE: '@logo-image',
  LANDING_IMAGE: '@landing-image',
  PREVIEW_IMAGE: '@preview-image',
  CART_IMAGE: '@cart-image',
  BLUE_BOX_IMAGE: '@blue-box-image',
  PINK_BOX_IMAGE: '@pink-box-image',
  THEME: '@theme',
  THEME_VALID_BLACK: '@theme-valid-black',
  THEME_VALID_GREENSOCIAL: '@theme-valid-greensocial',
  THEME_VALID_BLUERCN: '@theme-valid-bluercn',
  THEME_VALID_ORANGEEXISTAYA: '@theme-valid-orangeexistaya',
  THEME_VALID_VICTORIAFRIENDS: '@theme-valid-victoriafriends',
  THEME_VALID_THEME10: '@theme-valid-theme10',
  THEME_VALID_THEME2: '@theme-valid-theme2',
  THEME_VALID_IKI: '@theme-valid-iki',
  THEME_VALID_ATV: '@theme-valid-atv',
  THEME_VALID_REBOLD: '@theme-valid-rebold',
  THEME_VALID_MBCS: '@theme-valid-mbcs',
  THEME_VALID_MAIN: '@theme-valid-main',
  THEME_VALID_LOGIN_IMAGE: '@theme-valid-login-img',
  THEME_VALID_LANDING_IMAGE: '@theme-valid-landing-img',
};
export const DEFAULT_VARS = {
  BACKGROUND3:
    'https://s3-us-west-2.amazonaws.com/fluvip2.0-prod-documents/whitelabel/logo',
};
