import { takeLatest } from 'redux-saga/effects';
import { createSNTAction } from './actions/createSNT';
import { indexSNTsAction } from './actions/indexSNTs';
import { showSNTAction } from './actions/showSNT';
import { showSNTListAction } from './actions/showSNTList';
import { showInfoSNTAction } from './actions/showInfoSNT';
import { updateSNTAction } from './actions/updateSNT';
import { reloadSNTAction } from './actions/reloadSNT';
import { updateSNTBasicAction } from './actions/updateSNTBasic';
import { updateSNTFullAction } from './actions/updateSNTFull';
import { updateSNTFieldAction } from './actions/updateSNTField';
import {
  CREATE_SNT,
  INDEX_SNT,
  SHOW_SNT,
  SHOW_SNT_LIST,
  SHOW_INFO_SNT,
  UPDATE_SNT,
  UPDATE_SNT_BASIC,
  UPDATE_SNT_FULL,
  UPDATE_SNT_FIELD,
  RELOAD_SNT,
  RELOAD_SNT_CB,
} from './constants';

export default [
  takeLatest(INDEX_SNT, indexSNTsAction),
  takeLatest(CREATE_SNT, createSNTAction),
  takeLatest(SHOW_SNT, showSNTAction),
  takeLatest(SHOW_SNT_LIST, showSNTListAction),
  takeLatest(SHOW_INFO_SNT, showInfoSNTAction),
  takeLatest(UPDATE_SNT, updateSNTAction),
  takeLatest(RELOAD_SNT, reloadSNTAction),
  takeLatest(RELOAD_SNT_CB, reloadSNTAction),
  takeLatest(UPDATE_SNT_BASIC, updateSNTBasicAction),
  takeLatest(UPDATE_SNT_FULL, updateSNTFullAction),
  takeLatest(UPDATE_SNT_FIELD, updateSNTFieldAction),
];
