export const capitalize = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1);
export const numOnly = (value) => {
  const newVal = `${value}`;
  if (!newVal) {
    return newVal;
  }
  return newVal.replace(/\D+/g, '');
};
export const letterOnly = (str) => {
  if (!str) {
    return str;
  }
  return str.replace(/[^a-zA-Z ]/g, '');
};
export const capitalLetters = (str) =>
  str
    ? str
        .replace(/[^a-zA-Z ]/g, '')
        .charAt(0)
        .toUpperCase() + str.slice(1)
    : str;

export const quantity = (value) =>
  `${Math.round(value * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
export const currency = (value) => `$${quantity(value)}`;
